import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MovistarLandpageComponent} from './movistar-landpage/movistar-landpage.component';

const routes: Routes = [
  {
    path: 'movistar-dispositivos',
    component: MovistarLandpageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MovistarRoutingModule { }
