import { ProductModel } from './../../models/product/product.model';
import { Injectable } from '@angular/core';
import {BaseService} from '../../core/services/base.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {GeneralResponse} from '../../models/general-response.model';
import {catchError, map} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsOttService extends BaseService {

  public productsOttChanged = new Subject<ProductModel | null>();

  constructor(public router: Router, public http: HttpClient) {
    super(router);
  }

  getProducts(): Observable<{} | GeneralResponse> {
    let url = `${this._api}client/ott/products?filter[saleable]=1`;
    return this.http.get<GeneralResponse>(url)
      .pipe(
        map(res => {
          if (res.data) {
            const data = ProductModel.createArray(res.data, new ProductModel());
            return new GeneralResponse('', false, false, data);
          } else {
            return new GeneralResponse(res.message, res.error, false, null);
          }
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }


  buyProduct(procedure): Observable<{} | GeneralResponse> {
    let url = `${this._api}client/me/ott/buy-product`;
    return this.http.post<GeneralResponse>(url, procedure)
      .pipe(
        map(res => {
          if (!res.error) {
            const product = ProductModel.createOne(res.data, new ProductModel());
            this.productsOttChanged.next(product);
            return new GeneralResponse('', false, false, product);
          } else {
            return new GeneralResponse(res.message, res.error, false, null);
          }
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }
}
