import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, NgControl, ReactiveFormsModule} from '@angular/forms';

import { ModalCartConfirmComponent } from './modal-cart-confirm/modal-cart-confirm.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalContactDateComponent } from './components/modal-contact-date/modal-contact-date.component';
import { ModalProcedureResultComponent } from './modal-procedure-result/modal-procedure-result.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalAdquireProductConfirmComponent } from './modal-adquire-product-confirm/modal-adquire-product-confirm.component';
import { ModalPersonalDataComponent } from './modal-personal-data/modal-personal-data.component';
import { ModalElectronicInvoiceComponent } from './modal-electronic-invoice/modal-electronic-invoice.component';
import { ModalDownProductsConfirmComponent } from './modal-down-products-confirm/modal-down-products-confirm.component';
import { ModalWifiChangesComponent } from './modal-wifi-changes/modal-wifi-changes.component';
import { ModalViewDetailsComponent } from './modal-view-details/modal-view-details.component';
import { ModalPaymentConfirmComponent } from './modal-payment-confirm/modal-payment-confirm.component';
import { SharedModule } from '../shared/shared.module';
import { ModalEmitCouponComponent } from './modal-emit-coupon/modal-emit-coupon.component';
import {ModalAutomaticDebitComponent} from './modal-automatic-debit/modal-automatic-debit.component';
import {RecaptchaComponent} from './components/recaptcha/recaptcha.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import { ModalTechnicalClaimComponent } from './modal-technical-claim/modal-technical-claim.component';
import { ModalChangeAddressComponent } from './modal-change-address/modal-change-address.component';
import { ModalContactComponent } from './modal-contact/modal-contact.component';
import { ModalPayOnlineComponent } from './modal-pay-online/modal-pay-online.component';
import { ModalAdditionalConnectionsComponent } from './modal-additional-connections/modal-additional-connections.component';
import { ModalChangeOwnershipComponent } from './modal-change-ownership/modal-change-ownership.component';
import { ModalGenericProcedureComponent } from './modal-generic-procedure/modal-generic-procedure.component';
import { ModalContactMovilComponent } from './modal-contact-movil/modal-contact-movil.component';
import { ModalHomeGenericRequestComponent } from './modal-home-generic-request/modal-home-generic-request.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { ModalAdquireProductPerDayComponent } from './modal-adquire-product-per-day/modal-adquire-product-per-day.component';
import { ModalAdquireProductOttComponent } from './modal-adquire-product-ott/modal-adquire-product-ott.component';

@NgModule({
    declarations: [
        ModalCartConfirmComponent,
        ModalHeaderComponent,
        ModalContactDateComponent,
        ModalProcedureResultComponent,
        ModalAdquireProductConfirmComponent,
        ModalPersonalDataComponent,
        ModalElectronicInvoiceComponent,
        ModalDownProductsConfirmComponent,
        ModalWifiChangesComponent,
        ModalViewDetailsComponent,
        ModalPaymentConfirmComponent,
        ModalEmitCouponComponent,
        ModalAutomaticDebitComponent,
        RecaptchaComponent,
        ModalTechnicalClaimComponent,
        ModalChangeAddressComponent,
        ModalContactComponent,
        ModalPayOnlineComponent,
        ModalAdditionalConnectionsComponent,
        ModalChangeOwnershipComponent,
        ModalGenericProcedureComponent,
        ModalContactMovilComponent,
        ModalHomeGenericRequestComponent,
        ModalAdquireProductPerDayComponent,
        ModalAdquireProductOttComponent,
    ],
    imports: [
        CommonModule,

        FormsModule,
        ReactiveFormsModule,

        SharedModule,

        NgxMaskModule.forRoot(),
        AlertModule.forRoot(),
        NgxCaptchaModule,

        BsDropdownModule,
    ],
    providers: [
        // NgControl
    ],
    exports: [
        ModalHeaderComponent,
        RecaptchaComponent,
        ModalCartConfirmComponent
    ]
})
export class ProceduresModule { }
