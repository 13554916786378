import { Component, OnInit } from '@angular/core';
import {ProductModel} from '../../../models/product/product.model';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ClientService} from '../../../providers/auth/client.service';
import {ProductsPerDayService} from '../../../providers/procedures/products-per-day.service';
import {ProcedureProductDailyModel} from '../../../models/procedure/procedure-product-daily.model';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../../core/components/base-component';
import {GeneralResponse} from '../../../models/general-response.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-modal-adquire-product-per-day',
  templateUrl: 'modal-adquire-product-per-day.component.html',
  styleUrls: ['modal-adquire-product-per-day.component.scss']
})
export class ModalAdquireProductPerDayComponent extends BaseComponent implements OnInit {

  public onCloseModal = new Subject<GeneralResponse>();
  plan: ProductModel;
  product: ProductModel;
  title: string;

  daysToSelect: {id: number, value: Date, selected: boolean, available: boolean}[];

  constructor(
    private bsModalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    private clientService: ClientService,
    private prodsPerDayService: ProductsPerDayService
  ) {
    super();
  }


  ngOnInit() {
    super.ngOnInit();
    this.initDaysToSelect();
  }

  initDaysToSelect(): void {
    this.daysToSelect = [];
    const dateToday = new Date();
    this.daysToSelect.push({id: 0, value: dateToday, selected: false, available: true});
    for (let i = 1; i < 7; i++) {
      let date = new Date();
      date.setDate(dateToday.getDate() + i);
      this.daysToSelect.push({id: i, value: date, selected: false, available: true});
    }
  }


  // abstract clases
  get isValidForm(): boolean {
    return this.selectedDays.length > 0;
  }

  makeProcedure(): void {
    this.spinner.show();
    const procedure = new ProcedureProductDailyModel();
    procedure.client = this.clientService.currentClient;
    procedure.connection = this.clientService.currentConection;
    procedure.product = this.product;
    procedure.dateFrom = this.selectedDays[0].value.toISOString();
    procedure.dateUntil = this.selectedDays.length > 1 ?
      this.selectedDays[this.selectedDays.length - 1].value.toISOString()
      :
      procedure.dateFrom;

    this.prodsPerDayService.buyProductsPerDay(procedure)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((response: GeneralResponse) => {
        this.onClose(response);
        }, (err) => {
          this.onClose(err);
        }
      );
  }

  checkAvailability(): void {
    if (this.selectedDays.length == 0) {
      this.daysToSelect.forEach(d => d.available = true);
      return;
    }
    this.daysToSelect.forEach(d => {
      const canSetAvailable = !(d.selected && this.daysToSelect[d.id - 1]?.selected && this.daysToSelect[d.id + 1]?.selected);

      if (this.selectedDays.length < 4 && (d.selected || this.daysToSelect[d.id - 1]?.selected || this.daysToSelect[d.id + 1]?.selected)) {
        if (canSetAvailable) {
          d.available = true;
        } else {
          d.available = false;
        }
      } else {
        if (!d.selected || !canSetAvailable) {
          d.available = false;
        }
      }
    })
  }

  onClose(response: GeneralResponse | null = null) {
    this.spinner.hide();
    this.onCloseModal.next(response);
    this.bsModalRef.hide();
  }

  get selectedDays(): any[] {
    return this.daysToSelect.filter(x => x.selected);
  }

}
