import { Serializable } from 'src/app/core/models/serializable.model';

export class AccountModel extends Serializable {

  id: number;
    date: Date|string|any;
    type: string;
    number: string;
    period: string;
    periodDate: Date|string|any;
    total: number;
    rest: number;
    expiration: Date|string|any;

    newModel(data: any): AccountModel {
        const dataParsed = new AccountModel(data);

        dataParsed.total = +dataParsed.total;
        dataParsed.rest = +dataParsed.rest;

        return dataParsed;
    }

    get isPaid(): boolean {
      return (this.rest === 0);
    }

    get realTotal(): number {
      return this.rest;
    }
}
