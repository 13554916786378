import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base-component';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { CartService } from 'src/app/providers/cart/cart.service';
import { FamiliesService } from 'src/app/providers/products/families.service';
import { ProductFamilyModel } from 'src/app/models/product/product-family.model';
import { ProductModel } from 'src/app/models/product/product.model';
import { takeUntil } from 'rxjs/operators';
import { BomsService } from 'src/app/providers/products/boms.service';

@Component({
  selector: 'app-cart-plan-details',
  templateUrl: './cart-plan-details.component.html',
  styleUrls: ['./cart-plan-details.component.scss']
})
export class CartPlanDetailsComponent extends BaseComponent implements OnInit {
  product: ProductModel;
  optionalsSelected: ProductModel[];
  optionalsOriginal: ProductModel[] = [];
  idPlan: number;
  total = 0;
  family: ProductFamilyModel;
  loading: boolean;
  totalPrice = 0;

  constructor(
    private _activeRoute: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private bomService: BomsService,
    private familyService: FamiliesService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.loading = true;
    this._activeRoute.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params: Params) => {
        if (params['familyId'] && params['planId']) {
          this.idPlan = +params['planId'];
          this.family = this.familyService.getFamily(+params['familyId']);
          this.getProduct();
          this.loading = false;
        } else {
          this.goBack();
        }
      });

    // subscribo a esto por si presiona f5 y tengo q recargar los planes.
    this.familyService.familiesChange
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((families: ProductFamilyModel[]) => {
        this.family = families.find(family => family.id === this.family.id);
        this.getProduct();
        this.loading = false;
      });
  }

  getProduct(): void {
    this.product = this.family.plans.find(plan => plan.id === this.idPlan);
    if (this.product) {
      this.optionalsOriginal = this.product.optionals;
      // busca en el carro si existe trae los opcionales
      const productInCart = this.cartService.findProductInCart(this.product.id);
      if (productInCart) {
        this.optionalsSelected = productInCart.optionals;
      } else {
        this.optionalsSelected = [];
      }
      this.recalculatePrice();
    }
  }

  goBack(): void {
    this.router.navigate(['..'], { relativeTo: this._activeRoute });
  }

  recalculatePrice() {
    this.totalPrice = this.product.price;
    for (const optional of this.optionalsSelected) {
      this.totalPrice += +optional.price;
    }
  }

  onChangeSelectedOptional($event): void {
    if ($event.checked) {
      this.optionalsSelected.push($event.optional);
    } else {
      this.optionalsSelected.splice(
        this.optionalsSelected.indexOf(
          this.optionalsSelected.find(os => os.id === $event.optional.id)
          ),
        1
      );
    }
    this.recalculatePrice();
  }

  /**
   * agrega al carro un producto seleccionado
   */
  addToCart() {
    const prodToAdd = new ProductModel({
      ...this.product,
    });
    prodToAdd.optionals = this.optionalsSelected;
    this.cartService.addProduct(prodToAdd);
    this.router.navigate(['/carro-compras/productos']);

    this.bomService.findBestBoms(this.cartService.cart.products);
  }
}
