import { Serializable } from 'src/app/core/models/serializable.model';

export class ConsumedInternetModel extends Serializable {

    public hasConsumes: boolean;
    public codigo: string;
    public upstream: string;
    public downstream: string;
    public consumo: string;
    public producto: string;
    public limite_transferencia: string;
    public periodos_facturados: string;
    public porcentaje_consumo: number;
    public excedente: string;
    public estimado_consumo: string;
    public estimado_excedente: string;
    public mac: string;

    get percentage(): string {
        return this.porcentaje_consumo + '%';
    }

    newModel(data: any): ConsumedInternetModel {
        const dataParsed = new ConsumedInternetModel(data);
        dataParsed.hasConsumes = data.hasConsumes;
        if (dataParsed.hasConsumes) {
            dataParsed.codigo = data.codigo;
            dataParsed.upstream = data.upstream;
            dataParsed.downstream = data.downstream;
            dataParsed.consumo = data.consumo;
            dataParsed.producto = data.producto;
            dataParsed.limite_transferencia = data.limite_transferencia;
            dataParsed.periodos_facturados = data.periodos_facturados;
            dataParsed.porcentaje_consumo = +data.porcentaje_consumo;
            dataParsed.excedente = data.excedente;
            dataParsed.estimado_consumo = data.estimado_consumo;
            dataParsed.estimado_excedente = data.estimado_excedente;
            dataParsed.mac = data.mac;
        }
        return dataParsed;
    }
}
