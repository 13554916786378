import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LayoutService {

  changeShowFooter = new Subject<boolean>();

  private _showFooter = true;

  constructor() {
    this._showFooter = true;
  }

  get showFooter(): boolean {
    return this._showFooter;
  }

  set showFooter(showFooter: boolean) {
    this.changeShowFooter.next(showFooter);
    this._showFooter = showFooter;
  }

}
