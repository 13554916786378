import { Serializable } from '../../core/models/serializable.model';

export class DataModel extends Serializable {

    public id: number;
    public name: string;

    newModel(data: any): DataModel {
        return new DataModel(data);
    }
}
