
export class SubsidiaryAddressModel {
    public id: number;
    public name: string;
    public address: string;
    public hours: string;
    public lat: string;
    public long: string;
    public tag: string;

    constructor(id: number, name: string, address: string, hours: string, lat: string, long: string, tag: string) {
      this.id = id;
      this.name = name;
      this.address = address;
      this.hours = hours;
      this.lat = lat;
      this.long = long;
      this.tag = tag;
    }
}
