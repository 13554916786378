import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ProductModel } from 'src/app/models/product/product.model';
import { ProductFamilyModel } from 'src/app/models/product/product-family.model';

@Component({
    selector: 'app-plan-details',
    templateUrl: './plan-details.component.html',
    styleUrls: ['./plan-details.component.scss']
})
export class PlanDetailsComponent implements OnInit {

    @Input() product: ProductModel;
    @Input() productOriginal: ProductModel = null;

    @Input() isBom: boolean;
    @Input() family: ProductFamilyModel;
    @Input() optionalsSelected: ProductModel[];
    @Input() optionalsOriginal: ProductModel[];

    @Output() goBack: EventEmitter<void> = new EventEmitter<void>();
    @Output() changeSelectedOptional: EventEmitter<{checked: boolean, optional: ProductModel}> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.product.channels = this.product.channels.sort((c1, c2) => {
            if (c1.number > c2.number) {
                return 1;
            }
            if (c1.number < c2.number) {
                return -1;
            }
            return 0;
        });
    }

    onChangeSelectedOptional($event): void {
        this.changeSelectedOptional.emit($event);
    }

    onGoBack() {
        this.goBack.emit();
    }

    currentProduct(): ProductModel {
        if (this.isBom) {
            return this.productOriginal;
        }
        return this.product;
    }


}
