import { Injectable } from '@angular/core';
import { ProductModel } from 'src/app/models/product/product.model';
import { CartBomModel } from 'src/app/models/cart/cart-bom.model';
import { BomModel } from 'src/app/models/product/bom.model';

@Injectable({
  providedIn: 'root'
})
export class CartBomService {

  private _cart: CartBomModel;

  constructor() {
    this._cart = new CartBomModel();
  }

  /**
   * Devuelve el carro de compras
   */
  get cart(): CartBomModel {
    return this._cart;
  }

  /**
   * Devuelve si el cart tiene al menos 1 producto
   */
  get hasProducts(): boolean {
    return (this._cart.products.length > 0);
  }

  /**
   * Devuelve true si el bom ya fue seetado
   */
  get hasBom(): boolean {
    // pregunto por el id porque si hay uno seleccionado y cambia de sucursal no lo encuentra y dirige a /undefined
    return (this._cart.bom && this._cart.bom.id) ? true : false;
  }

  /** busca el producto en los planes del carro */
  findProductInCart(productId: number): ProductModel | null {
    return this._cart.products.find(p => p.id === productId);
  }

  /** busca el producto en el bom original */
  findProductInBom(productId: number): ProductModel | null {
    return this._cart.bom.products.find(p => p.id === productId);
  }

  /**
   * remove bom from cart
   */
  cleanBom() {
    this._cart = new CartBomModel();
  }

  /**
   * Agrega un bom como seleccionado copiando los productos con opcionales con precio 0
   * @param bom BomModel
   */
  setBom(bom: BomModel) {

    // si el bom que quiero setear es el mismo que esta en el servicio y el precio es el mismo no hago nada
    if (this._cart.bom &&
      this._cart.bom.id === bom.id &&
      this._cart.bom.price === bom.price) {
      } else {

      // recreo el bom sin productos
      this._cart.bom = new BomModel({
        ...bom
      });

      this._cart.products = bom.products.map(function(prod) {
          prod.price = 0;
          prod.optionals.map( function (opt) {
              opt.price = 0;
              return opt;
          });
        return prod;
      });
    }

    this.calculateTotal();
  }

  /**
   * Reemplaza un producto por otro (es el mismo pero con adicionales distintos)
   * @param product producto nuevo y a reemplazar
   */
  replaceProduct(product: ProductModel) {
    const indexToReplace = this._cart.products.indexOf(this._cart.products.find(p => p.id === product.id));
    this._cart.products[indexToReplace] = product;
    this.calculateTotal();
  }

  /**
   * Recalcula el total del carro. Precio del bom + opcionales
   */
  calculateTotal() {
    if (this.hasBom) {
      this.cart.price = this.cart.bom.price;
      for (const product of this._cart.products) {
        for (const optional of product.optionals) {
          this.cart.price += +optional.price;
        }
      }
    } else {
      this.cart.price = 0;
    }
  }
}
