<div dropdown class="dropdown show">
    <a
      id="basic-link"
      dropdownToggle
      (click)="(false)"
      aria-controls="basic-link-dropdown"
      class="btn dropdown-toggle">
      <i class="fa fa-map-marker-alt"></i>
      {{ currentSubsidiary.name | uppercase }}
    </a>
    <div
      id="basic-link-dropdown"
      *dropdownMenu
      class="dropdown-menu"
      role="menu"
      aria-labelledby="basic-link">
      <a
        *ngFor="let sub of subsidiaries()"
        (click)="onChangeSubsidiary(sub)"
        class="dropdown-item fix-a-blue-color cursor-pointer"
      >
        {{ sub.name | uppercase }}
      </a>
    </div>
  </div>
