import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../../../providers/layout.service';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';
import {PaymentCenterModel, SubsidiaryModel} from '../../../models/data/subsidiary.model';

@Component({
  selector: 'app-payment-places',
  templateUrl: './payment-places.component.html',
  styleUrls: ['./payment-places.component.scss']
})
export class PaymentPlacesComponent implements OnInit {


  subsidiary: SubsidiaryModel;
  constructor(private layoutService: LayoutService,
              private subsidiaryService: SubsidiaryService) { }

  ngOnInit() {
    this.subsidiary = this.subsidiaryService.currentSubsidiary;
    this.layoutService.showFooter = true;
  }

  goToExternalLink(cp: PaymentCenterModel) {
    window.open(cp.href, '_blank');
  }
}
