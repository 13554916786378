<app-plan-details
  [family]="family"
  [product]="product"
  [isBom]="false"
  [optionalsSelected]="optionalsSelected"
  [optionalsOriginal]="optionalsOriginal"
  (goBack)="goBack()"
  (changeSelectedOptional)="onChangeSelectedOptional($event)">
  </app-plan-details>

<app-cart-select-price-bar
  [buttonText]="'AGREGAR'"
  [price]="totalPrice"
  [promoSubscribe]="false"
  (apply)="addToCart()">
</app-cart-select-price-bar>
