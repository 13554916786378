import { Serializable } from 'src/app/core/models/serializable.model';
import {environment} from '../../../environments/environment';
import { ProductFamilyModel } from './product-family.model';
import { TermCondition } from './term-condition.model';
import { ProductChannelModel } from './product-channel.model';

export class ProductModel extends Serializable {

  public id: number;
  public name: string;
  public code: number;
  public description: string;
  public termsConditions: string;
  public price: number;
  public weight: number;
  public daysToDown: number;
  public allowDirectDown: boolean;
  public allowDirectUp: boolean;
  public saleable: boolean;
  public isPrincipal: boolean;
  public useNow: boolean;
  public icon: string;
  public points: number;
  public deviceType: string | null;
  public optionals: ProductModel[] = [];
  public channels: ProductChannelModel[] = [];

  public family: ProductFamilyModel;

  public selected = false;
  public base64Icon: string;

  public dailies: ProductModel[] = [];


  newModel(data: any): ProductModel {
    const dataParsed = new ProductModel(data);
    dataParsed.price = +dataParsed.price;
    if (data.family) {
      dataParsed.family = new ProductFamilyModel(data.family.id, '', data.family.tag, '', '');
    }

    if (data.optionals) {
      dataParsed.optionals = ProductModel.createArray(data.optionals, new ProductModel());
    }

    if (data.channels) {
      dataParsed.channels = ProductChannelModel.createArray(data.channels, new ProductChannelModel());
    }

    if (data.dailies) {
      dataParsed.dailies = ProductModel.createArray(data.dailies, new ProductModel());
    }

    return dataParsed;
  }

  get iconUrl(): string {
    return environment.iconsUrl + this.icon;
  }

  get needDevice(): boolean {
    return this.deviceType != null;
  }

  get hasOptionals(): boolean {
    return (this.optionals.length > 0);
  }

  get optionalsWithChannels(): ProductModel[] {
    return this.optionals.filter(opt => opt.channels.length > 0);
  }

  /**
   * devuelve el termino y condicion para el producto
   */
  get productTermAndCondition(): TermCondition | null {
    if (this.termsConditions != null) {
      return new TermCondition(this.name, this.termsConditions);
    }
    return null;
  }

  /**
   * devuelve terminos y condiciones del plan y de los opcionales q lo componen
   */
  get planTermsAndConditions(): TermCondition[] | null {
    const terms: TermCondition[] = [];
    if (this.termsConditions != null) {
      terms.push(this.productTermAndCondition);
    }
    for (const optional of this.optionals) {
      if (optional.productTermAndCondition != null) {
        terms.push(optional.productTermAndCondition);
      }
    }
    return (terms.length > 0) ? terms : null;
  }

  get isBasicoOTT(): boolean {
    return this.code === environment.basicoOttCode
  }
}
