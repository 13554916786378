import { Injectable } from '@angular/core';
import { BaseService } from '../core/services/base.service';
import { Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { GeneralResponse } from '../models/general-response.model';
import { HttpClient } from '@angular/common/http';
import { GraphModel } from '../models/data/graph.model';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class GraphsService extends  BaseService {

  private _seccionMediaIzq: GraphModel = new GraphModel();
  private _seccionMediaDer: GraphModel = new GraphModel();
  private _movistar: GraphModel = new GraphModel();
  private _carousel: GraphModel[];

  constructor(public router: Router,
              public http: HttpClient) {
      super(router);
  }

  get seccionMediaIzq(): GraphModel {
    return this._seccionMediaIzq;
  }
  get seccionMediaDer(): GraphModel {
    return this._seccionMediaDer;
  }
  get movistar(): GraphModel {
    return this._movistar;
  }
  get carousel(): GraphModel[] {
    return this._carousel;
  }


  @Cacheable({
    cacheBusterObserver: cacheBuster$
  })
  getGraphs(subsidiary: number, corporative: number): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/graphs?filter[subsidiary]=${subsidiary}&filter[corporative]=${corporative}`;
    return this.http.get<GeneralResponse>(url)
      .pipe(
          map(res => {
              const graphs = GraphModel.createArray(res.data, new GraphModel());
              this._seccionMediaDer = graphs.find(y => y.title.includes('Derecha'));
              this._seccionMediaIzq = graphs.find(y => y.title.includes('Izquierda'));
              this._movistar = graphs.find(y => y.title.includes('Movistar'));
              // todo => aca hay q ponerle las imagenes de carrousel
              const banner = graphs.filter(z => z.title.includes('Banner'));
              this._carousel = (banner);

              return new GeneralResponse('', false, false, graphs);
          }),
          catchError(err => {
              return this.handleError(err);
          })
      );
  }
}

