import { Component, OnInit, Input } from "@angular/core";
import { ProductFamilyModel } from "src/app/models/product/product-family.model";
import { Router, ActivatedRoute } from "@angular/router";
import { CartService } from "src/app/providers/cart/cart.service";
import { BaseComponent } from "../../../../../core/components/base-component";
import { SubsidiaryService } from "../../../../../providers/data/subsidiary.service";

@Component({
  selector: "app-cart-family-buttons",
  templateUrl: "./family-buttons.component.html",
  styleUrls: ["./family-buttons.component.scss"],
})
export class FamilyButtonsComponent extends BaseComponent implements OnInit {
  @Input() families: ProductFamilyModel[];
  @Input() showTitle: boolean;
  constructor(
    private router: Router,
    private _activeRoute: ActivatedRoute,
    private cartService: CartService,
    private subsidiaryService: SubsidiaryService
  ) {
    super();
  }

  ngOnInit() {}

  /**
   * devuelve las familias q todavia no se asigno producto
   */
  familiesLeft(): ProductFamilyModel[] {
    const families: ProductFamilyModel[] = [];
    for (const f of this.families) {
      if (!this.cartService.checkIfFamilyIsSelected(f.id) && f.tag != "now") {
        families.push(f);
      }
    }
    return families;
  }

  get leftFamilies(): boolean {
    return this.familiesLeft().length > 0;
  }

  goToFamilyProducts(familyId: number): void {
    this.router.navigate(["../detalle/" + `${familyId}`], {
      relativeTo: this._activeRoute,
    });
  }

  get canShowMovistar(): boolean {
    return (
      this.isMobileScreen() &&
      this.subsidiaryService.currentSubsidiary.tag !== "sl"
    );
  }

  goToMovistar(): void {
    this.router.navigate(["movistar"]);
  }
  goToDevices(): void {
    this.router.navigate(["movistar-dispositivos"]);
  }
}
