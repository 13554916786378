import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../../../providers/layout.service';

@Component({
  selector: 'app-now-express',
  templateUrl: './now-express.component.html',
  styleUrls: ['./now-express.component.scss']
})
export class NowExpressComponent implements OnInit {

    constructor(private layoutService: LayoutService) { }

    ngOnInit() {
        this.layoutService.showFooter = true;
    }
}
