import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';
import { DirectPaymentOkComponent } from './direct-payment-ok/direct-payment-ok.component';
import { DirectPaymentErrorComponent } from './direct-payment-error/direct-payment-error.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                redirectTo: 'pagar',
                pathMatch: 'prefix',
            },
            {
                path: 'pagar',
                component: DirectPaymentComponent,
            },
            {
                path: 'pago-resultado-ok',
                component: DirectPaymentOkComponent
            },
            {
                path: 'pago-resultado-error',
                component: DirectPaymentErrorComponent
            }
            ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AppMobileRoutingModule { }
