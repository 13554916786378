import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { ProceduresBaseComponent } from '../procedures-base-component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';

@Component({
    selector: 'app-modal-procedure-result',
    templateUrl: './modal-procedure-result.component.html',
    styleUrls: ['./modal-procedure-result.component.scss']
})
export class ModalProcedureResultComponent extends ProceduresBaseComponent implements OnInit {

    error: boolean;
    title: string;
    message: string;
    title_message: string;
    // foot_message = environment.texts.procedure_footer;
    foot_message = '';
    constructor(
        bsModalRef: BsModalRef,
        spinner: NgxSpinnerService,
        proceduresService: ProceduresService
    ) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.error) {
            this.title_message = 'Ocurrió un problema.';
        } else {
            this.title_message = 'Gracias!';
        }
    }

    get alertType(): string {
        if (this.error) {
            return 'warning';
        } else {
            return 'success';
        }
    }

    makeProcedure() {
        return null;
    }

    get isValidForm()  {
        return true;
    }

}
