<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container py-4 mb-5">
    <div class="row">
      <div class="col-lg-6">
        <div class="titulo color-primary">Producto a adquirir</div>

        <div class="row align-self-center">
          <span class="col-sm-3 color-primary  font-weight-bold"
            >Producto:</span
          >
          <div class="col-sm-9 ">
            <span>{{ product.name }}</span>
          </div>
        </div>

        <div class="row align-self-center" *ngIf="product.hasOptionals">
            <span class="col-sm-3 color-primary  font-weight-bold"
              >Adicionales:</span
            >
            <div class="col-sm-9 ">
              <span>{{ optionalsText }}</span>
            </div>
          </div>

        <div class="row align-self-center">
          <span class="col-sm-3 color-primary  font-weight-bold">Precio:</span>
          <div class="col-sm-9 ">
            <span>{{ product.price | currency: 'es-AR':'$' }}</span>
          </div>
        </div>

        <div class="row align-self-center" *ngIf="plan">
          <span class="col-sm-3 color-primary  font-weight-bold">Plan:</span>
          <div class="col-sm-9 ">
            <span>{{ plan.name }}</span>
          </div>
        </div>

      <div class=" carrito mt-3" *ngIf="product.needDevice">
        <div class="lista-productos" >
          <div class="row no-gutters mt-1">
            <div class="adicionales vo-adicionales" *ngIf="product.deviceType !== 'TODOS'">
              <a
                class="btn text-left border-0 text-white btn-adicionales bg-color-secondary d-block font-weight-bold"
                aria-expanded="false"
                aria-controls="collapseAdicionales"
              >
                <i class="fa fa-list"></i> Seleccioná el dispositivo a asociar
              </a>
              <div id="collapseAdicionales">
                <ul class="px-0">
                  <li class="border-top-0" *ngFor="let device of devices">
                    <div class="row font-size-1rem">
                      <div class="col-auto col-check text-right">
                        <input
                          type="checkbox"
                          [id]="device.id"
                          (change)="changeSelection($event, device)"
                        />
                        <!--  -->
                      </div>
                      <div class="col font-size-1rem">
                        <label [for]="device.id"
                          >{{ device.product.name }}
                          <span class="precio color-primary"
                            ><strong>Serie:</strong> {{ device.number }}</span
                          >
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <alert type="danger">
                  <small class="form-text">
                  <i class="fa fa-exclamation-triangle"></i> Debés marcar al menos un dispositivo
                  </small>
                </alert>
            </div>
            <div class="adicionales vo-adicionales" *ngIf="product.deviceType === 'TODOS'">
              <alert type="info">
                <small class="form-text">
                  <i class="fa fa-info-circle"></i>El producto se asociará a todos sus dispositivos.
                </small>
              </alert>
            </div>
          </div>
        </div>
      </div>
    </div>
        <div class="col-lg-6 margin-top-mobile-1">
          <app-modal-contact-date
            [(comment)]="comment"
            [(timeFrom)]="timeFrom"
            [(timeUntil)]="timeUntil"
            [(phone)]="phone"
          ></app-modal-contact-date>

        </div>
    </div>


  </div>
</div>

<div class="modal-footer">

          <button
            [disabled]="!isValidForm"
            href="javascript:void(0)"
            class="btn btn-block text-white bg-color-primary d-block"
            (click)="onConfirm()"
          >
            CONFIRMAR COMPRA
          </button>

</div>

