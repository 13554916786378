import {Component, OnInit} from '@angular/core';
import {LayoutService} from '../../../providers/layout.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.layoutService.showFooter = true;
  }

}
