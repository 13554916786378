<div class="adicionales">
    <a
    (click)="toggleCollapse()"
    class="btn text-left border-0 text-white btn-adicionales bg-color-secondary d-block font-weight-bold collapsed"
    aria-expanded="false"
    aria-controls="collapseAdicionales">
      <i class="fa" [ngClass]="{'fa-caret-right': isCollapsed, 'fa-caret-up':!isCollapsed}"></i>
        VER OPCIONES ADICIONALES
    </a>
    <div id="collapseAdicionales" [collapse]="isCollapsed">
      <ul class="px-0">
        <li class="border-top-0"  *ngFor="let optional of optionals" >
          <div class="row font-size-1rem">
            <div class="col-auto col-check text-right">
              <input
              [disabled]="noEditableOptional(optional)"
              type="checkbox"
              [id]="optional.id"
              (change)="changeSelection($event, optional)"
              [checked]="isSelected(optional.id)">
            </div>
            <div class="col text-left">
              <label [for]="optional.id">{{optional.name}}</label>
              <small *ngIf="noEditableOptional(optional)">&nbsp;&nbsp;&nbsp;(Incluido en promo)</small>
            </div>
            <div class="col-auto text-right">
              <span class="precio color-primary" [ngClass]="{'precio-tachado': noEditableOptional(optional)}">{{optional.price | currency:'es-AR':'$'}}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
