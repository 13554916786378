import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

import {BaseService} from '../../core/services/base.service';
import {catchError, map} from 'rxjs/internal/operators';
import {Cacheable} from 'ngx-cacheable';
import {Observable, Subject} from 'rxjs';
import {GeneralResponse} from '../../models/general-response.model';
import {DataModel} from '../../models/data/data.model';
import { ProcedureKindModel } from 'src/app/models/data/procedure-kind.model';


@Injectable({providedIn: 'root'})
export class DataService extends BaseService {

    /**
     * ante un refresh de los datos emito q hubo cambios
     */
    dataChanged = new Subject<boolean>();

    private _documents: DataModel[] = [];
    private _procedureState: DataModel[] = [];
    private _procedureKind: ProcedureKindModel[] = [];

    constructor(public router: Router, public http: HttpClient) {
        super(router);
    }

    get documents(): DataModel[] {
      return this._documents;
    }
    get procedureState(): DataModel[] {
      return this._procedureState;
    }
    get procedureKind(): ProcedureKindModel[] {
      return this._procedureKind;
    }

    // open routes
    public getData(): Observable<{} | GeneralResponse> {
        const url = `${this._api}client/common-data`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {
                    this._documents = DataModel.createArray(res.data.documents, new DataModel());
                    this._procedureState = DataModel.createArray(res.data.procedureStates, new DataModel());
                    this._procedureKind = DataModel.createArray(res.data.procedureKinds, new ProcedureKindModel());
                    this.dataChanged.next(true);
                    return new GeneralResponse('', false, false, null);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }
}
