<!-- Footer -->
<footer class="pt-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-3" style="text-align: center;">
        <img [src]="isCorporative ? 'assets/img/ic_footer_logo_corp.png' : 'assets/img/ic_footer_logo@2x.png'" class="logo" />
        <div class="tel">
          <!--<img src="assets/img/ic_footer_phone.png" class="mr-2"/>-->
          <a [href]="'tel:' + tel">{{tel}}</a>
        </div>
      </div>
      <div class="col-lg-3">
        <ul>
          <li *ngIf="!isCorporative"><a target="_blank"  href="https://now.express.com.ar/">NOW Express</a></li>
          <li *ngIf="!isCorporative"><a (click)="goToCart()"  href="javascript:void(0)">Productos</a></li>
          <li *ngIf="!isCorporative"><a (click)="goToBoms()" href="javascript:void(0)">Promociones</a></li>
          <!-- <li><a href="#">Atención Cliente</a></li> -->
          <li><a (click)="virtualOffice()" href="javascript:void(0)">Oficina virtual</a></li>
        </ul>
      </div>
      <div class="col-lg-3">
         <ul>
          <li *ngIf="!isCorporative"><a (click)="finder()" href="javascript:void(0)">Buscador de programación</a></li>
          <li *ngIf="!isCorporative"><a (click)="recomendador()" href="javascript:void(0)" >Recomendador</a></li>
          <li><a (click)="centrosAtencion()" href="javascript:void(0)">Atención al cliente</a></li>
          <li><a (click)="lugaresPago()" href="javascript:void(0)">Lugares y medios de pago</a></li>
        </ul>
      </div>
      <div class="col-lg-3">
        <ul>
          <li *ngIf="!isCorporative"><a (click)="consejosUtiles()" href="javascript:void(0)">Consejos útiles</a></li>
          <li *ngIf="!isCorporative"><a (click)="faq()" href="javascript:void(0)">Preguntas frecuentes</a></li>
          <li><a (click)="onDownServices()" href="javascript:void(0)">Baja de servicios</a></li>
          <li *ngIf="!isCorporative && isLogged"><a (click)="phone_consumes()" href="javascript:void(0)">Consumos telefonía</a></li>
          <!--<li *ngIf="!isCorporative && isLogged"><a (click)="internet_consumes()" href="javascript:void(0)">Consumo de internet</a></li>-->
        </ul>
        <div class="redes">
          <ul>
            <li><a target="_blank" href="https://www.facebook.com/express.com.ar/?fref=ts" class="lnk-social social-fb">Facebook</a></li>
            <li><a target="_blank" href="https://www.instagram.com/express.arg/" class="lnk-social social-ig">Instagram</a></li>
            <li><a target="_blank" href="https://www.youtube.com/channel/UCntIfmA8JR7uQZBCBSpoZ6w" class="lnk-social social-yt">YouTube</a></li>
            <li><a target="_blank" href="https://www.linkedin.com/company/expressarg" class="lnk-social social-li">Linkedin</a></li>
            <li><a target="_blank" href="https://twitter.com/express_arg" class="lnk-social social-tw">Twitter</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row justify-content-center m-0 pt-4 pt-lg-0"><p><small>Desarrollado por <a href="https://mapplics.com/web/" target="_blank">Mapplics Mobile Solutions</a></small></p></div>
  </div>
  <!-- /.container -->
</footer>
