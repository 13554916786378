import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BomModel } from 'src/app/models/product/bom.model';

@Component({
  selector: 'app-bom-home-details',
  templateUrl: './bom-home-details.component.html',
  styleUrls: ['./bom-home-details.component.scss']
})
export class BomHomeDetailsComponent implements OnInit {

  @Input() bom: BomModel;
  @Input() isPromo: boolean;
  @Output() viewBom: EventEmitter<BomModel> = new EventEmitter<BomModel>();

  constructor() { }

  ngOnInit() {
  }

  onViewBom() {
    this.viewBom.emit(this.bom);
  }

}
