<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="modal-body">
    <alert type="info">
        <h4 class="alert-heading">
          <i class="fa fa-question-circle"></i>&nbsp;
            <span *ngIf="isPaymentLast">Confirma el pago de la factura '{{account.number}}', correspondiente al período '{{account.period | periodFormat}}' por un total de '{{payment_total | currency:'es-AR':'$'}}'</span>
            <span *ngIf="isPaymentMin">Confirma el pago mínimo, por un total de '{{payment_total | currency:'es-AR':'$'}}'</span>
            <span *ngIf="isPaymentTotal">Confirma el pago Total de la , por un total de '{{payment_total | currency:'es-AR':'$'}}'</span>
            <span *ngIf="isPaymentOTT">Vamos a pedirte un pago de '{{payment_total | currency:'es-AR':'$'}}' para validar tu tarjeta, y te lo devolvemos al instante</span>
        </h4>
      </alert>

      <form #wondersoftForm name="wondersoft" id="wondersoft" method="post" action="{{wsUri}}">

        <input *ngFor="let data of keyValues"
        type="hidden"
        name="{{data.key}}"
        value="{{data.value}}" />

        <button class="btn btn-block btn-blue" type="submit" name="enviar" (click)="onSubmitRccpagosForm()" >PAGAR</button>

        </form>
</div>
