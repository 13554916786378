
<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand" (click)="goHome()" href="javascript:void(0);">
      <img src="assets/img/ic_logo_navbar@2x.png" />
    </a>

    <!-- open menu mobile button-->
    <button
            class="navbar-toggler"
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"

            (click)="menuCollapse = !menuCollapse"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- // -->

    <div class="navbar-collapse" id="navbarResponsive" [ngClass]="{collapse: menuCollapse}" >
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <app-header-subsidiaries></app-header-subsidiaries>
        </li>
        <li class="nav-item cursor-pointer" *ngIf="isMobileScreen()" (click)="toggleCorporative()">
          <a class="nav-link nav-link-corp"  href="javascript:void(0)">
            <img *ngIf="!isCorporative"
                 src="assets/img/ic_logo_corp@2x.png"
                 class="img-corp float-left"
            />
            <img *ngIf="isCorporative"
                 src="assets/img/ic_logo_express_chico@2x.png"
                 class="img-corp float-left"
            />
            <div class="float-left text-corp" *ngIf="isCorporative">
              HOGAR
            </div>
            <div class="float-left text-corp" *ngIf="!isCorporative">
              EMPRESAS
            </div>
          </a>
        </li>

        <li *ngIf="!isMobileScreen()" class="nav-item cursor-pointer" (click)="showHogar()">
          <a class="nav-link nav-corp cursor-pointer" [ngClass]="{
                  'active': !isCorporative,
                  'inactive': isCorporative}">HOGAR</a>
        </li>

        <li *ngIf="!isMobileScreen()" class="nav-item cursor-pointer" (click)="showCorporative()">
          <a class="nav-link nav-corp cursor-pointer" [ngClass]="{
                  'active': isCorporative,
                  'inactive': !isCorporative}">
            EMPRESAS
          </a>
        </li>

        <li class="nav-item d-none d-xs-block d-xl-block tel-container">
          <a class="nav-link nav-link-tel" href="javascript:void(0)">
            <img
                    src="assets/img/ic_call_phone@2x.png"
                    class="float-left"
                    width="24"
            />
            <div class="float-left text-tel">
              <div class="tel"><a [href]="'tel:' + tel">{{tel}}</a></div>
            </div>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
<!--        <li class="nav-item">-->
<!--          <a class="nav-link nav-combo-fucsia cursor-pointer" (click)="openRequestModal()">REALIZÁ TU CONSULTA</a>-->
<!--        </li>-->
        <li class="nav-item" *ngIf="!isSantiago">
          <a class="nav-link nav-combo-gray cursor-pointer" (click)="goToTotem()">PAGÁ TUS FACTURAS</a>
        </li>
        <li class="nav-item" *ngIf="!isCorporative">
          <a class="nav-link nav-combo cursor-pointer" (click)="goToCart()">ARMÁ TU COMBO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link nav-oficina cursor-pointer" (click)="goToVirtualOffice()" >OFICINA VIRTUAL</a>
        </li>
        <!-- Mobile -->
        <li class="nav-item subnav" *ngIf="showTurnos">
          <a class="nav-link" href="https://appointments.clients.debmedia.com/#/company/tvsa/schedules" target="_blank"> TURNOS ONLINE </a>
        </li>
        <li class="nav-item subnav" *ngIf="!isCorporative">
          <a class="nav-link nav-now" href="javascript:void(0);" (click)="goToVirtualOffice()">NOW EXPRESS</a>
        </li>
        <li class="nav-item subnav" *ngIf="!isCorporative">
          <a class="nav-link" href="javascript:void(0);" (click)="goToCart()"> PRODUCTOS </a>
        </li>
       <li class="nav-item subnav" *ngIf="!isCorporative">
         <a class="nav-link" href="javascript:void(0);" (click)="goToBoms()"> PROMOCIONES </a>
       </li>
<!--        <li class="nav-item subnav" *ngIf="!isCorporative">-->
<!--          <a class="nav-link" href="javascript:void(0);" (click)="goToRecommend()"> RECOMENDADOR </a>-->
<!--        </li>-->
        <li class="nav-item subnav" *ngIf="!isCorporative">
          <a class="nav-link" href="javascript:void(0);" (click)="goToStaticSection('centros-de-atencion')"> CENTROS DE ATENCIÓN </a>
        </li>
        <li class="nav-item subnav" *ngIf="!isCorporative">
          <a class="nav-link" href="javascript:void(0);" (click)="goToStaticSection('lugares-de-pago')"> LUGARES DE PAGO </a>
        </li>
        <li  class="nav-item subnav" *ngIf="!isCorporative">
          <a class="nav-link" href="javascript:void(0);" (click)="goToStaticSection('preguntas-frecuentes')">PREGUNTAS FRECUENTES</a>
        </li>
        <li class="nav-item subnav" *ngIf="!isCorporative">
          <a class="nav-link" href="javascript:void(0);" (click)="goToStaticSection('consejos-utiles')">CONSEJOS ÚTILES</a>
        </li>
        <li class="nav-item subnav" *ngIf="!isCorporative && !isSanLorenzo">
          <a class="nav-link" href="javascript:void(0);" (click)="goToStaticSection('movistar-dispositivos')">MOVISTAR</a>
        </li>
        <li class="nav-item subnav" *ngIf="isCorporative">
          <br><br>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Desktop -->
<nav
        class="navbar navbar-expand-lg fixed-top navbar-2 d-none d-sm-none d-md-none d-lg-block"
>
  <div class="container">
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" dropdown *ngIf="!isCorporative">
          <a
                  href
                  id="products-link"
                  dropdownToggle
                  (click)="(false)"
                  aria-controls="products-link-dropdown"
                  class="nav-link dropdown-toggle"
          >
            PRODUCTOS
          </a>
          <div
                  id="products-link-dropdown"
                  *dropdownMenu
                  role="menu"
                  aria-labelledby="products-link"
                  class="dropdown-menu mega-menu v-2 z-depth-1 special-color py-4 px-3"
          >
            <app-header-menu-products
                    (goToFamilyProducts)="navigateTo($event)"
            ></app-header-menu-products>
          </div>
        </li>
       <li class="nav-item" *ngIf="!isCorporative">
         <a class="nav-link cursor-pointer"
            (click)="goToBoms()"
            href="javascript:void(0);"> PROMOCIONES </a>
       </li>
<!--        <li class="nav-item" *ngIf="!isCorporative">-->
<!--          <a class="nav-link cursor-pointer"-->
<!--             href="javascript:void(0);" (click)="goToRecommend()"> RECOMENDADOR </a>-->
<!--        </li>-->
        <li class="nav-item" dropdown *ngIf="!isCorporative">
          <a
                  href
                  id="clientAtention-link"
                  dropdownToggle
                  (click)="(false)"
                  aria-controls="clientAtention-link-dropdown"
                  class="nav-link dropdown-toggle"
          >
            ATENCIÓN AL CLIENTE
          </a>
          <div
                  id="clientAtention-link-dropdown"
                  *dropdownMenu
                  role="menu"
                  aria-labelledby="clientAtention-link"
                  class="dropdown-menu mega-menu v-2 z-depth-1 special-color py-4 px-3"
          >
            <app-header-menu-client
                    (goToVirtualOffice)="goToVirtualOffice()"
                    (goToStaticSection)="goToStaticSection($event)"
            ></app-header-menu-client>
          </div>
        </li>
        <li class="nav-item" *ngIf="showTurnos">
          <a class="nav-link cursor-pointer" 
             href="https://appointments.clients.debmedia.com/#/company/tvsa/schedules" target="_blank"> TURNOS ONLINE</a>
        </li>
        <li *ngIf="isCorporative">
          <br><br>
        </li>
      </ul>
    </div>
  </div>
</nav>
