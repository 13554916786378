<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>
<div class="modal-body">
  <div class="container py-4 mb-5 oficina-virtual formulario">
    <!--<div class="row">-->
      <!--<div class="col-12">-->
        <!--<div class="titulo color-primary">{{title}}</div>-->
      <!--</div>-->
    <!--</div>-->
    <div *ngIf="showAmounts()">
      <div>
        <h4>Marcá tu saldo</h4>
      </div>
      <form>
        <div class="payment-opt" *ngIf="hasPaymentLast">
          <input type="radio" id="lastPayment" name="lastPayment" [value]="currentClient.paymentLast" [(ngModel)]="selectedValue" >
          <label for="lastPayment" class="font-size-1rem">
            &nbsp;&nbsp;&nbsp; Última factura
            <strong class="color-primary">
              ${{currentClient.paymentLast}}
            </strong>
          </label>
        </div>

        <div class="payment-opt" *ngIf="hasPaymentMin">
          <input type="radio" name="minPayment" id="minPayment" [value]="currentClient.paymentMin" [(ngModel)]="selectedValue" >
          <label for="minPayment" class="font-size-1rem">
              &nbsp;&nbsp;&nbsp; Monto mínimo
          <strong class="color-primary">
            ${{currentClient.paymentMin}}
          </strong>
        </label>
        </div>

        <div class="payment-opt" *ngIf="hasPaymentTotal">
          <input type="radio" name="totalPayment" id="totalPayment" [value]="currentClient.paymentTotal" [(ngModel)]="selectedValue">
          <label for="totalPayment" class="font-size-1rem">
              &nbsp;&nbsp;&nbsp; Total
            <strong class="color-primary">
              ${{currentClient.paymentTotal}}
            </strong>
          </label>
        </div>

      </form>
      <button *ngIf="action === 'pay'" class="btn btn-block btn-blue mt-4" type="submit" name="pay" (click)="OnPayOnline()" [disabled]="!selectedValue">PAGAR</button>

      <button *ngIf="action === 'print'" class="btn btn-block btn-blue mt-4" type="submit" name="emit" (click)="onEmitCoupon()" [disabled]="!selectedValue">EMITIR</button>
    </div>

    <div *ngIf="!showAmounts()">
      <h5>Estás al día.</h5>
    </div>
  </div>
</div>
