<section  class="py-5 container">
  <alert type="warning p-4">
    <h6 class="alert-heading text-center" >
      <i class="fa fa-exclamation-triangle"></i>&nbsp;
      <span>El pago no ha sido procesado.</span>
      <br/><br/>
      
      <p><b>Detalle del problema: </b> {{error_message}}</p>
      <p>Por favor vuelva a ingresar a la aplicación y reintentelo</p>
      <!-- <p>Si el problema persiste, por favor comuniquese con Express.</p> -->
    </h6>
  </alert>  
</section>