<body class="movistar-land" id="content-movistar">

<app-movistar-header [image]="bannerHeader"></app-movistar-header>

<section>
  <div class="row w-100 m-0" *ngIf="devices">
    <div class="col-sm-12 offset-sm-0 col-10 offset-1 pt-3 pb-3 ">
      <carousel [itemsPerSlide]="itemsPerSlide"
                [singleSlideOffset]="true"
                [showIndicators]="true"
                interval="false"
      >
        <slide *ngFor="let device of devices; let index=index" class="carousel-slide mx-auto text-center">
          <img [src]="device.image" alt="image slide" class="phone-img">
          <div class="carousel-caption">
            <p class="brand">{{device.brand}}</p>
            <p class="model">{{device.model}}</p>
<!--            <small class="small">Desde</small>-->
<!--            <h4 class="price">$ {{device.price}}</h4>-->
          </div>
        </slide>
      </carousel>
    </div>

    <div class="col-12 text-center">
      <p class="cond">*Sujeto a disponibiildad técnica y geográfica. </p>
    </div>
  </div>

  <app-movistar-form></app-movistar-form>

  <app-movistar-footer></app-movistar-footer>
</section>

</body>
