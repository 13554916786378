import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { AppMobileRoutingModule } from './app-mobile-routing.module';
import { DirectPaymentErrorComponent } from './direct-payment-error/direct-payment-error.component';
import { DirectPaymentOkComponent } from './direct-payment-ok/direct-payment-ok.component';
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
    declarations: [
        DirectPaymentComponent,
        DirectPaymentOkComponent,
        DirectPaymentErrorComponent
    ],
    imports: [
        CommonModule,
        AppMobileRoutingModule,
        SharedModule,
        AlertModule.forRoot(),
    ]
})
export class AppMobileModule { }
