<body class="home">
<header>
  <carousel [showIndicators]="true">
    <slide *ngFor="let im of carousel">
      <div class="carousel-slide" [style.background-image]="'url(' + im.linkBackground + ')'">
        <div class="row">
          <div class="col-lg-6 text-center">
            <img [src]="im.linkImageLeft" class="img-slide-left"/>
            <div>
              <a *ngIf="!isCorporative" [href]="im.link"
                 class="btn btn-default" [style.background-color]="im.buttonColor">
                {{im.buttonText}}
              </a>
              <a *ngIf="isCorporative" href="javascript:void(0);"
                 (click)="openModal()" class="btn btn-default"
                 [style.background-color]="im.buttonColor">
                {{im.buttonText}}
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <img [src]="im.linkImageRight" class="img-right"/>
          </div>
        </div>
      </div>
    </slide>
  </carousel>

  <div class="productos" *ngIf="!isCorporative">
    <div class="row">
      <div class="col-lg-4 px-0 col-producto text-center boms" style="padding: 0 !important;" *ngFor="let bom of boms"
           [ngStyle]="{'background-image': 'url(' + bom.backgroundImageUrl+ ')'}">
        <img [src]="bom.principalImageUrl" class="img-fluid cursor-pointer text-center" (click)="showBom(bom)"/>
      </div>
      <ng-container *ngIf="fixedBannersToShow > 0">
        <div class="col-lg-4 px-0 col-producto text-center boms" style="padding: 0 !important;" *ngIf="fixedBannersToShow >= 1"
              [ngStyle]="{'background-image': 'url(assets/img/telefonia.png)'}">
          <img src="assets/img/television-4.png" class="img-fluid cursor-pointer text-center" (click)="seePlans('prods-by-day')"/>
        </div>
        <!--<div class="col-lg-4 px-0 col-producto text-center boms" style="padding: 0 !important;" *ngIf="fixedBannersToShow >= 1" >
          <img src="assets/img/television-1.png" class="img-fluid cursor-pointer" (click)="seePlans('now')"/>
          <div class="row middle-section-text">
            <div class="col-sm-6 col-lg-10">
              <div class="nombre">Televisión</div>
            </div>
          </div>
          <a (click)="seePlans('now')"
             href="javascript:void(0);"
             class="btn btn-default">
            &nbsp;</a> --
        </div>-->
        <div class="col-lg-4 px-0 col-producto text-center boms" style="padding: 0 !important;" *ngIf="fixedBannersToShow >= 2"
          [ngStyle]="{'background-image': 'url(assets/img/telefonia.png)'}">
          <img src="assets/img/internet-4.png" class="img-fluid cursor-pointer text-center" (click)="seePlans('futbol')"/>
        </div>
        <!--<div class="col-lg-4 px-0 col-producto text-center boms" style="padding: 0 !important;" *ngIf="fixedBannersToShow >= 2">
          <img src="assets/img/internet-1.png" class="img-fluid cursor-pointer" (click)="seePlans('cable')"/>
          <div class="row middle-section-text">
            <div class="col-sm-6 col-lg-12">
              <div class="nombre">Internet</div>
            </div>
          </div>
          <a
             href="javascript:void(0);"
             class="btn btn-default">VER PLANES</a> --
        </div>-->
        <div class="col-lg-4 px-0 col-producto text-center boms" style="padding: 0 !important;" *ngIf="fixedBannersToShow >= 3"
          [ngStyle]="{'background-image': 'url(assets/telefonia.png)'}">
          <!-- <img src="assets/img/internet.png" class="img-fluid cursor-pointer text-center" (click)="seePlans('cable')"/> -->
        </div>
        <!-- <div class="col-lg-4 px-0 col-producto prod-telefonia" *ngIf="fixedBannersToShow >= 3">
          <div class="row middle-section-text">
            <div class="col-sm-6 col-lg-12">
              <div class="nombre">Telefonía fija</div>
            </div>
          </div>
          <a (click)="seePlans('telefono')"
             href="javascript:void(0);"
             class="btn btn-default" >VER PLANES</a>
        </div> -->
      </ng-container>
    </div>
  </div>


  <div class="productos" *ngIf="isCorporative">
    <div class="row">
      <div class="col-lg-4 px-0 col-producto leftBanner text-center">
        <img src="assets/img/corp/Boton1-01.png" class="banner-corp">
      </div>
      <div class="col-lg-4 px-0 col-producto middleBanner text-center">
        <img src="assets/img/corp/Boton2-01.png" class="banner-corp">
      </div>
      <div class="col-lg-4 px-0 col-producto rightBanner text-center">
        <img src="assets/img/corp/Boton3-01.png" class="banner-corp">
      </div>
    </div>
  </div>

  <div class="productos-2">
    <div class="row">
      <div class="col-sm-6 px-0 col-left" [style.background-image]="'url(' + seccionMediaIzq.linkBackground + ')'">
        <!--seccion medio izquierda-->
        <img [src]="seccionMediaIzq.linkImageLeft" class="content"/>
        <div>
          <a  *ngIf="!isCorporative" [href]="seccionMediaIzq.link" class="btn btn-default" [style.background-color]="seccionMediaIzq.buttonColor">
            {{seccionMediaIzq.buttonText}}
          </a>
          <a  *ngIf="isCorporative"  href="javascript:void(0);"
              (click)="openModal()" class="btn btn-default" [style.background-color]="seccionMediaIzq.buttonColor">
            {{seccionMediaIzq.buttonText}}
          </a>
        </div>
      </div>
      <div class="col-sm-6 px-0 col-right" [style.background-image]="'url(' + seccionMediaDer.linkBackground + ')'">
        <!--seccion medio derecha-->
        <img [src]="seccionMediaDer.linkImageLeft" class="content"/>
        <div>
          <a *ngIf="!isCorporative" [href]="seccionMediaDer.link" class="btn btn-default" [style.background-color]="seccionMediaDer.buttonColor">
            {{seccionMediaDer.buttonText}}
          </a>
          <a *ngIf="isCorporative"  href="javascript:void(0);"
             (click)="openModal()"
             class="btn btn-default" [style.background-color]="seccionMediaDer.buttonColor">
            {{seccionMediaDer.buttonText}}
          </a>

        </div>
      </div>
    </div>
  </div>
</header>

<section id="items" *ngIf="!isCorporative">
  <div class="container">
    <div class="content">
      <div class="row justify-content-around">
        <div class="col-md-6 col-lg-3">
          <a (click)="goToVirtualOffice()"
             href="javascript:void(0);">
            <img src="assets/img/ic_back_of_virtual@2x.png">
            <h3>OFICINA VIRTUAL</h3>
            <div class="info">Ingresá a tu cuenta para realizar tus consultas y trámites online.</div>
          </a>
        </div>
        <div class="col-md-6 col-lg-3">
          <a (click)="goToElectronicBill()"
             href="javascript:void(0);">
            <img src="assets/img/ic_back_factura@2x.png">
            <h3>FACTURA ELECTRÓNICA</h3>
            <div class="info">Descargá e imprimí tu factura. Registrate y recibila todos los meses en tu correo electrónico.</div>
          </a>
        </div>
        <div class="col-md-6 col-lg-3">
          <a (click)="goToFinder()"
             href="javascript:void(0);">
            <img src="assets/img/ic_back_buscador@2x.png">
            <h3>BUSCADOR DE PROGRAMACIÓN</h3>
            <div class="info">Encontrá la grilla de contenidos de todos los canales.</div>
          </a>
        </div>
        <!--        <div class="col-md-6 col-lg-2">-->
        <!--          <a (click)="goToRecomendador()"-->
        <!--             href="javascript:void(0);">-->
        <!--            <img src="assets/img/ic_back_recomendador@2x.png">-->
        <!--            <h3>RECOMENDADOR</h3>-->
        <!--            <div class="info">Elegí el plan de internet ideal para tu hogar.</div>-->
        <!--          </a>-->
        <!--        </div>-->
        <div class="col-md-6 col-lg-3">
          <a (click)="showDnu()"
             href="javascript:void(0);">
            <img src="assets/img/dnu.png">
            <h3>DNU</h3>
            <div class="info">Consultá la resolución del gobierno nacional.</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
