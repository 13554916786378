import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../../../providers/layout.service';

@Component({
  selector: 'app-helpful-tips',
  templateUrl: './helpful-tips.component.html',
  styleUrls: ['./helpful-tips.component.scss']
})
export class HelpfulTipsComponent implements OnInit {


  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
      this.layoutService.showFooter = true;
  }



}
