<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container py-4 mb-5" name="form" [formGroup]="form">
    <div class="row">
      <div class="col-lg-12 margin-top-mobile-1">
        <div class="titulo color-primary">Reclamo para la conexión
          {{ currentConnection.code }} - Domicilio:
          {{ currentConnection.fullAddress }}</div>

        <div class="form-group row">
          <label  class="col-sm-3 col-form-label col-form-label-sm color-primary align-self-center">Horario
            para Contactar<span class="required">*</span></label>
          <div class="col-sm-4 align-self-center">
            <input type="tel" placeholder="hh:mm" class="form-control" mask="Hh:m0"
                   formControlName="timeFrom"
                   [ngClass]="{ 'is-invalid': validInput('timeFrom') }"/>
          </div>
          <div class="col-sm-1 align-self-center"></div>
          <div class="col-sm-4 input-in-mobile align-self-center">
            <input  type="tel" formControlName="timeUntil" placeholder="hh:mm" class="form-control"
                    mask="Hh:m0"
                    [ngClass]="{ 'is-invalid': validInput('timeUntil') }"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm color-primary">
            Reclamo<span class="required">*</span></label>
          <div class="col-sm-9">
          <textarea type="text" rows="4" class="form-control form-control-sm"
                    placeholder="Escriba su mensaje aquí"
                    formControlName="comment"
                    [ngClass]="{ 'is-invalid': validInput('comment') }"></textarea>
          </div>
        </div>

      </div>
    </div>

    <app-modal-recaptcha
            (confirmCaptcha)="toggleCaptcha($event)"
    ></app-modal-recaptcha>
  </div>
</div>

<div class="modal-footer">

  <button
          [disabled]="!isValidForm"
          href="javascript:void(0)"
          class="btn btn-block text-white bg-color-primary d-block"
          (click)="onConfirm()"
  >
    ENVIAR
  </button>

</div>

