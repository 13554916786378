import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductModel } from 'src/app/models/product/product.model';

@Component({
  selector: 'app-cart-selected-products',
  templateUrl: './selected-products.component.html',
  styleUrls: ['./selected-products.component.scss']
})
export class SelectedProductsComponent implements OnInit {

  @Input() products: ProductModel[];
  @Input() isBom: boolean;

  @Output() quitProduct: EventEmitter<ProductModel> = new EventEmitter<ProductModel>();
  @Output() editProduct: EventEmitter<ProductModel> = new EventEmitter<ProductModel>();
  constructor() { }

  ngOnInit() {
  }

  onQuitProduct(product: ProductModel) {
    this.quitProduct.emit(product);
  }

  onEditProduct(product: ProductModel) {
    this.editProduct.emit(product);
  }

}
