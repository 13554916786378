import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyProductComponentComponent } from './family-product-component/family-product-component.component';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { OptionalsComponent } from './plan-details/optionals/optionals.component';
import { CollapseModule } from 'src/app/shared/directives/collapse';
import { SelectPriceBarComponent } from './select-price-bar/select-price-bar.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PeriodFormatPipe } from './pipes/period-format.pipe';
import { ChannelsComponent } from './plan-details/channels/channels.component';
import {CapitalizePipe} from './pipes/capitalize.pipe';

@NgModule({
  declarations: [
    FamilyProductComponentComponent,
    PlanDetailsComponent,
    OptionalsComponent,
    SelectPriceBarComponent,
    TermsConditionsComponent,
    PeriodFormatPipe,
    ChannelsComponent,
    CapitalizePipe
  ],
  imports: [
    CommonModule,
    CollapseModule,
  ],
  exports: [
    FamilyProductComponentComponent,
    PlanDetailsComponent,
    OptionalsComponent,
    SelectPriceBarComponent,
    TermsConditionsComponent,

    PeriodFormatPipe,
    CapitalizePipe
  ]
})
export class SharedModule { }
