<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="oficina-virtual formulario">
  <div name="form" [formGroup]="form">
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="titulo color-primary">Confirmar solicitud de contacto</div>

          <!--nombre de la compania-->
          <div class="form-group row ">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Companía
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                      type="text"
                      class="form-control form-control-sm "
                      formControlName="companyName"
                      [ngClass]="{ 'is-invalid': validInput('name') }"
                      placeholder="Ingresá razón social..."
              />
            </div>
          </div>


          <!-- nombre y apellido -->
          <div class="form-group row ">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Nombre y apellido
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                      type="text"
                      class="form-control form-control-sm "
                      formControlName="name"
                      [ngClass]="{ 'is-invalid': validInput('name') }"
                      placeholder="Ingresá nombre o razón social..."
              />
            </div>
          </div>

          <div class="form-group row">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Email
              <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                      type="email"
                      class="form-control form-control-sm"
                      placeholder="Ingresá email..."
                      formControlName="email"
                      [ngClass]="{ 'is-invalid': validInput('email') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Teléfono
              <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                      type="tel"
                      class="form-control form-control-sm"
                      placeholder="(000) 000 0000"
                      mask="(000) 000 0000"
                      formControlName="phone"
                      [ngClass]="{ 'is-invalid': validInput('phone') }"
              />
            </div>
          </div>




          <div class="form-group row">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Calle<span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Calle..."
                      formControlName="street"
                      [ngClass]="{ 'is-invalid': validInput('street') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Altura <span class="required">*</span> | Piso | Depto
            </label>
            <div class="col-sm-3 input-in-mobile">
              <input
                      type="tel"
                      class="form-control form-control-sm"
                      placeholder="Altura..."
                      formControlName="streetNumber"
                      [ngClass]="{ 'is-invalid': validInput('streetNumber') }"
              />
            </div>
            <div class="col-sm-3 input-in-mobile">
              <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Piso..."
                      formControlName="streetFloor"
                      [ngClass]="{ 'is-invalid': validInput('streetFloor') }"
              />
            </div>
            <div class="col-sm-3 input-in-mobile">
              <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Depto..."
                      formControlName="streetDept"
                      [ngClass]="{ 'is-invalid': validInput('streetDept') }"
              />
            </div>
          </div>


          <!--consulta-->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label col-form-label-sm color-primary">
              Consulta</label>
            <div class="col-sm-9">
                    <textarea type="text" rows="4" class="form-control form-control-sm"
                              placeholder="Escriba su consulta aquí"
                              formControlName="comment"></textarea>
            </div>
          </div>


          <div class="row">
            <div class="col-sm-12">
              <div class="alert alert-danger" role="alert">
                <i class="fa fa-exclamation-triangle"></i> Los campos marcados con (*) son obligatorios.
              </div>
            </div>
            <div class="col-sm-12 text-right">
              <app-modal-recaptcha
                      (confirmCaptcha)="toggleCaptcha($event)"
              ></app-modal-recaptcha>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
              [disabled]="!isValidForm"
              href="javascript:void(0)"
              class="btn btn-block text-white bg-color-primary d-block"
              (click)="onConfirm()"
      >
        CONTACTAR
      </button>
    </div>

  </div>
</div>