import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base-component';
import { BomsService } from 'src/app/providers/products/boms.service';
import { takeUntil } from 'rxjs/operators';
import { BomModel } from 'src/app/models/product/bom.model';

@Component({
  selector: 'app-cart-select-price-bar',
  templateUrl: './select-price-bar.component.html',
  styleUrls: ['./select-price-bar.component.scss']
})
export class SelectPriceBarComponent extends BaseComponent implements OnInit {

  @Input() buttonText: string;
  @Input() price: number;
  @Input() promoSubscribe: boolean;
  @Output() apply: EventEmitter<void> = new EventEmitter<void>();

  showPromo = false;
  promoPrice = 0;

  constructor(private bomsService: BomsService) {
    super();
   }

  ngOnInit() {
    super.ngOnInit();
    // si no es bom m subscribo al buscador de promos
    if (this.promoSubscribe) {

      this.setPromo(this.bomsService.bomBestPromo);

      this.bomsService.emitHasPromo
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(
          (bom: BomModel | null) => {
            this.setPromo(bom);
          }
        );
    }
  }

  setPromo(bom: BomModel | null) {
    if (bom) {
      this.showPromo = true;
      this.promoPrice = bom.price;
    } else {
      this.showPromo = false;
    }
  }

  onApply(): void {
     this.apply.emit();
  }
}
