import {Component, Input, OnInit} from '@angular/core';
import {GraphModel} from '../../../../models/data/graph.model';

@Component({
  selector: 'app-movistar-header',
  templateUrl: './movistar-header.component.html',
  styleUrls: ['./movistar-header.component.scss']
})
export class MovistarHeaderComponent implements OnInit {

  @Input() image: GraphModel;
  constructor() { }

  ngOnInit() {
  }

}
