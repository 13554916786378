import { Serializable } from 'src/app/core/models/serializable.model';

export class ProductChannelModel extends Serializable {

  public id: number;
  public name: string;
  public icon: string;
  public order: number;
  public number: number;

  newModel(data: any): ProductChannelModel {
      const dataParsed = new ProductChannelModel(data);

      return dataParsed;
  }
}
