import { Injectable } from '@angular/core';
import {BaseService} from '../../core/services/base.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Cacheable} from 'ngx-cacheable';
import {GeneralResponse} from '../../models/general-response.model';
import {DeviceModel} from '../../models/movistar/device.model';
import {catchError, map} from 'rxjs/operators';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class DevicesService extends BaseService {

  constructor(public router: Router,
              public http: HttpClient
  ) {
    super(router);
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$
  })
  getAll(subsId: number): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/devices?filter[subsidiary]=${subsId}`;
    return this.http.get<GeneralResponse>(url)
      .pipe(
        map(res => {
          const devices = DeviceModel.createArray(res.data, new DeviceModel());
          return new GeneralResponse('', false, false, devices);
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }
}
