<div class="container">
    <div class="row">
        <div class="col-sm-6" *ngFor="let bom of bomsPromo">
            <app-bom-home-details
              [bom]="bom"
              [isPromo]="true"
              (viewBom)="goToBomProductPage($event)"
            ></app-bom-home-details>
        </div>
        <div class="col-sm-6" *ngFor="let bom of boms">
            <app-bom-home-details
              [bom]="bom"
              [isPromo]="false"
              (viewBom)="goToBomProductPage($event)"
            ></app-bom-home-details>
        </div>
    </div>
