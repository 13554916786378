import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart.component';
import { CartComponent as CartCartComponent} from './cart/cart.component';
import { BomComponent } from './bom/bom.component';
import { CartHomeComponent } from './cart/cart-home/cart-home.component';
import { BomHomeComponent } from './bom/bom-home/bom-home.component';
import { CartFamilyProductsComponent } from './cart/cart-family-products/cart-family-products.component';
import { BomDetailComponent } from './bom/bom-detail/bom-detail.component';

import { BomPlanDetailsComponent } from './bom/bom-plan-details/bom-plan-details.component';
import { CartPlanDetailsComponent } from './cart/cart-plan-details/cart-plan-details.component';



const routes: Routes = [
  {
    // path: 'cart',
    // children: [
    //     {
            path: 'carro-compras',
            component: CartComponent,
            children: [
              {
                path: 'productos',
                component: CartCartComponent,
                children: [
                  {
                    path: '',
                    redirectTo: 'inicio',
                    pathMatch: 'prefix',
                  },
                  {
                    path: 'inicio',
                    component: CartHomeComponent,
                  },
                  {
                    path: 'detalle/:familyId',
                    component: CartFamilyProductsComponent,
                  },
                  {
                    path: 'detalle/:familyId/:planId',
                    component: CartPlanDetailsComponent
                  }
                ]
              },
              {
                path: 'promos',
                component: BomComponent,
                children: [
                  {
                    path: '',
                    redirectTo: 'inicio',
                    pathMatch: 'prefix',
                  },
                  {
                    path: 'inicio',
                    component: BomHomeComponent,
                  },
                  {
                    path: 'detalle/:bomId',
                    component: BomDetailComponent
                  },
                  {
                    path: 'detalle/:bomId/:planId',
                    component: BomPlanDetailsComponent
                  },
                  {
                    path: 'detalle-qr/:bomId',
                    component: BomDetailComponent
                  }
                ]
              }
            ]
    //     },
    // ],
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartRoutingModule { }
