import { Component, OnInit } from '@angular/core';
import {ConnectionModel} from '../../../models/client/connection.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {DataService} from '../../../providers/data/data.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ClientService} from '../../../providers/auth/client.service';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {HelperFunctions} from '../../../helpers/functions';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';

@Component({
    selector: 'app-modal-additional-connections',
    templateUrl: './modal-additional-connections.component.html',
    styleUrls: ['./modal-additional-connections.component.scss']
})
export class ModalAdditionalConnectionsComponent  extends ProceduresBaseComponent implements OnInit  {

    form: FormGroup;
    connections: ConnectionModel[];
    reasons = [
        {name: 'Alta'},
        {name: 'Modificación'},
        {name: 'Baja'},
    ];

    procedureTypes = [
        {tag: ProcedureKind.CHANGE_CONNECTION_PATH, text: 'Cambio de recorrido'},
        {tag: ProcedureKind.ADDITIONAL_CONNECTIONS, text: 'Bocas adicionales'},
    ];

    constructor(
        bsModalRef: BsModalRef,
        spinner: NgxSpinnerService,
        proceduresService: ProceduresService,
        private clientsService: ClientService,
        private dataService: DataService,
        private _formBuilder: FormBuilder
    ) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initForm();
    }

    initForm() {
        this.connections = this.clientsService.currentClient.connections;
        this.form = this._formBuilder.group({
            connection: [this.clientsService.currentConection, [Validators.required]],
            selectedProcedure: ['', [Validators.required]],
            reason: ['', [Validators.required]],
            comment: [''],
            timeFrom: ['0900', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            timeUntil: ['1800', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
        });
    }

    get isValidForm(): boolean {
        return this.form.valid && this.captchaValid;
    }

    validInput(input: string): boolean {
        const control = this.form.get(input);
        if (control.dirty) {
            return !this.form.get(input).valid;
        } else {
            return false;
        }
    }
    makeProcedure(): ProcedureModel {
        const procedure = new ProcedureModel();

        procedure.initForNewProcedure();
        procedure.client = this.clientsService.currentClient;
        procedure.connection = this.form.value.connection;
        procedure.subsidiary = this.clientsService.currentClient.subsidiary;
        procedure.contactPhone = null;
        procedure.contactTime = HelperFunctions.formatContactTime(this.form.value.timeFrom, this.form.value.timeUntil);

        procedure.procedureKind = this.dataService.procedureKind.find(
            pk => pk.tag === this.form.value.selectedProcedure
        );
        procedure.data = [
            new ProcedureDataModel('reason', this.form.value.reason)
        ];

        procedure.clientDetails = this.form.value.comment;
        return procedure;
    }

}
