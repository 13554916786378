<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="oficina-virtual formulario">
  <div name="form" [formGroup]="form">
    <div class="container py-4 mb-5">
      <div class="row">
        <div class="col-12">
          <div class="titulo color-primary">Datos personales</div>

          <alert type="info" class="">
              <small class="form-text text-muted">
                <i class="fa fa-info-circle"></i>&nbsp;Completá la info que querés modificar
              </small>
          </alert>

          <!-- nombre y apellido -->
<!--          <div class="form-group row ">-->
<!--            <label-->
<!--              for="colFormLabelSm"-->
<!--              class="col-sm-3 col-form-label col-form-label-sm color-primary "-->
<!--              >Nombre y Apellido-->
<!--              <span class="required">*</span></label-->
<!--            >-->
<!--            <div class="col-sm-9 align-self-center">-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control form-control-sm "-->
<!--                formControlName="name"-->
<!--                [ngClass]="{ 'is-invalid': validInput('name') }"-->
<!--                placeholder="Nombre o razón social"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
              >Email <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="email"
                class="form-control form-control-sm"
                placeholder="Email"
                formControlName="email"
                [ngClass]="{ 'is-invalid': validInput('email') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
              >Teléfono <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="tel"
                class="form-control form-control-sm"
                placeholder="(000) 000 0000"
                mask="(000) 000 0000"
                formControlName="phone"
                [ngClass]="{ 'is-invalid': validInput('phone') }"
              />
            </div>
          </div>
        </div>
      </div>
      <app-modal-recaptcha
              (confirmCaptcha)="toggleCaptcha($event)"
      ></app-modal-recaptcha>
    </div>
  </div>
</div>

<div class="modal-footer">

    <button
      [disabled]="!isValidForm"
      href="javascript:void(0)"
      class="btn btn-block text-white bg-color-primary d-block"
      (click)="onConfirm()"
    >
      CONFIRMAR
    </button>

</div>
