import { Serializable } from 'src/app/core/models/serializable.model';

export class AccountPaidModel extends Serializable {

    id: number;
    clientId: number;
    type: string;
    number: number;
    period: string;
    total: number;
    result: string;
    verification: string;
    verificationResult: string;
    date: Date;

    newModel(data: any): AccountPaidModel {
        const dataParsed = new AccountPaidModel(data);
        dataParsed.total = +dataParsed.total;

        return dataParsed;
    }

    get isOk(): boolean {
        return (this.result === 'ok');
    }

}
