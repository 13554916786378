import { Component, OnInit } from '@angular/core';
import {DataModel} from '../../../models/data/data.model';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {DataService} from '../../../providers/data/data.service';
import {AuthService} from '../../../providers/auth/auth.service';
import {ClientService} from '../../../providers/auth/client.service';
import {CartService} from '../../../providers/cart/cart.service';
import {CartBomService} from '../../../providers/cart/cart-bom.service';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';
import {HelperFunctions} from '../../../helpers/functions';

@Component({
  selector: 'app-modal-contact-movil',
  templateUrl: './modal-contact-movil.component.html',
  styleUrls: ['./modal-contact-movil.component.scss']
})
export class ModalContactMovilComponent extends ProceduresBaseComponent implements OnInit {

  form: FormGroup;

  title: string;
  planName: string;

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private dataService: DataService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private clientsService: ClientService,
    private cartService: CartService,
    private cartBomService: CartBomService,
    private subsidiaryService: SubsidiaryService) {
    super(bsModalRef, spinner, proceduresService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initForm();
  }

  initForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      documentType: ['', Validators.required],
      documentNumber: ['', Validators.required],
      street: ['', [Validators.required]],
      streetNumber: ['', [Validators.required]],
      streetFloor: [''],
      streetDept: [''],
      comment: [''],
      timeFrom: ['0900', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      timeUntil: ['1800', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });
  }


  validInput(input: string): boolean {
    const control = this.form.get(input);
    if (control.dirty) {
      return !this.form.get(input).valid;
    } else {
      return false;
    }
  }

  get isValidForm(): boolean {
    return this.form.valid && this.captchaValid;
  }

  makeProcedure(): ProcedureModel {
    const procedure = new ProcedureModel();

    procedure.initForNewProcedure();
    procedure.subsidiary = this.subsidiaryService.currentSubsidiary;
    procedure.client = this.clientsService.currentClient ? this.clientsService.currentClient : null;
    procedure.clientDetails = this.form.value.comment;
    procedure.contactPhone = this.form.value.phone;
    procedure.contactTime = HelperFunctions.formatContactTime(this.form.value.timeFrom, this.form.value.timeUntil);


    procedure.procedureKind = this.dataService.procedureKind
      .find(pk => pk.tag === ProcedureKind.MOBILE_PHONE_REQUEST);

    procedure.data = [];
    procedure.data.push(new ProcedureDataModel('nombre', this.form.value.name));
    procedure.data.push(new ProcedureDataModel('email', this.form.value.email));
    procedure.data.push(new ProcedureDataModel('documento', this.form.value.documentType + ' ' + this.form.value.documentNumber));
    procedure.data.push(new ProcedureDataModel('telefono', this.form.value.phone));
    procedure.data.push(new ProcedureDataModel('calle', this.form.value.street));
    procedure.data.push(new ProcedureDataModel('número', this.form.value.streetNumber));
    procedure.data.push(new ProcedureDataModel('plan movistar', this.planName));
    if (this.form.value.streetFloor !== '') {
      procedure.data.push(new ProcedureDataModel('piso', this.form.value.streetFloor));
    }
    if (this.form.value.streetDept !== '') {
      procedure.data.push(new ProcedureDataModel('depto', this.form.value.streetDept));
    }
    return procedure;
  }

  get documentTypesArray(): DataModel[] {
    return this.dataService.documents;
  }

}
