import { OnInit, OnDestroy } from '@angular/core';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { BaseComponent } from 'src/app/core/components/base-component';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';
import { GeneralResponse } from 'src/app/models/general-response.model';

export abstract class ProceduresBaseComponent extends BaseComponent implements OnInit, OnDestroy {

  /** emito al cerrar el modal para q el componente se encargue del envio */
  public onCloseModal = new Subject<GeneralResponse>();

  title: string;

  captchaValid = false;

  constructor(
      public bsModalRef: BsModalRef,
      private spinner: NgxSpinnerService,
      private proceduresService: ProceduresService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onClose(response: GeneralResponse | null = null) {
    this.onCloseModal.next(response);
    this.bsModalRef.hide();
  }

  onConfirm() {
    this.sendProcedureRequest(this.makeProcedure());
  }

  toggleCaptcha(value: boolean) {
    this.captchaValid = value;
  }

  sendProcedureRequest(procedure: ProcedureModel) {
    this.spinner.show();
    this.proceduresService
        .saveProcedure(procedure, !procedure.procedureKind.loginRequired)
        .pipe(take(1))
        .subscribe((response: GeneralResponse) => {
              this.sendProcedureResponse(response);
            },
            (err) => {
              let mge = 'No se pudo procesar la solicitud, ocurrió un problema en el servidor. Por favor vuelva a intentarlo.';
              if (err.message) {
                mge = err.message;

              }
              this.sendProcedureResponse(new GeneralResponse(
                  mge,
                  true, false, null)
              );
            });
  }

  sendProcedureResponse(response: GeneralResponse) {
    this.spinner.hide();
    this.onClose(response);
  }

  // abstract clases
  abstract makeProcedure(): ProcedureModel;

  abstract get isValidForm(): boolean;
}
