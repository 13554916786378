import { Component, OnInit } from '@angular/core';
import { ProceduresBaseComponent } from '../procedures-base-component';
import { ProductModel } from 'src/app/models/product/product.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';
import { ClientService } from 'src/app/providers/auth/client.service';
import { DataService } from 'src/app/providers/data/data.service';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { HelperFunctions } from 'src/app/helpers/functions';
import { ProcedureKind } from 'src/app/models/data/procedure-kind.enum';

@Component({
  selector: 'app-modal-down-products-confirm',
  templateUrl: './modal-down-products-confirm.component.html',
  styleUrls: ['./modal-down-products-confirm.component.scss']
})
export class ModalDownProductsConfirmComponent  extends ProceduresBaseComponent implements OnInit {

  plans: ProductModel[];
  optionals: {optional: ProductModel, plan: ProductModel}[];

  timeFrom = '0900';
  timeUntil = '1800';
  comment = '';
  phone = '';

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private clientService: ClientService,
    private dataService: DataService,
  ) {
    super(bsModalRef, spinner, proceduresService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  optionalsText(plan: ProductModel): string {
    const optionalsSelected = plan.optionals.filter(o =>  o.selected === true);
    if (optionalsSelected.length > 0) {
      return Array.prototype.map.call(optionalsSelected, o => o.name).join(' | ');
    } else {
      return '';
    }
  }
  // abstract clases
  get isValidForm(): boolean {
   return this.captchaValid;
  }

  get hasPlans(): boolean {
    return (this.plans.length > 0);
  }
  get hasOptionals(): boolean {
    return (this.optionals.length > 0);
  }

  makeProcedure(): ProcedureModel {
    const procedure = new ProcedureModel();

    procedure.initForNewProcedure();

    procedure.client = this.clientService.currentClient;
    procedure.connection = this.clientService.currentConection;
    procedure.subsidiary = this.clientService.currentClient.subsidiary;

    procedure.clientDetails = this.comment;
    procedure.contactTime = HelperFunctions.formatContactTime(this.timeFrom, this.timeUntil);
    procedure.contactPhone = this.phone;

    procedure.procedureKind = this.dataService.procedureKind
        .find(pk => pk.tag === ProcedureKind.DOWN_SERVICE);

    // recorro planes
    for (const plan of this.plans) {
      procedure.products.push(plan);
    }

    for (const opt of this.optionals) {
      procedure.products.push(opt.optional);
    }

    return procedure;
  }
}
