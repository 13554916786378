import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ReCaptcha2Component} from 'ngx-captcha';
import { NgControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit{

  siteKey = environment.recaptchaKey;

  loading = true;
    captchaError = false;

  @Output() confirmCaptcha = new EventEmitter<Boolean>();

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  captcha: any;
  constructor() { }

  ngOnInit() {
  }

  resetCaptcha() {
      this.confirmCaptcha.emit(false);
    this.captchaElem.resetCaptcha();
  }
    handleReset() {
        this.captchaError = false;
    }
    handleExpire() {
        this.captchaError = true;
        this.confirmCaptcha.emit(false);
    }
    handleError() {
        this.captchaError = true;
        this.confirmCaptcha.emit(false);
    }
    handleLoad() {
        this.loading = false;
    }
    handleSuccess(event) {
      this.confirmCaptcha.emit(true);
    }

}
