<header>
  <div class="row w-100 banner justify-content-center align-items-center pt-4 pb-4 m-0">
    <div class="col-sm-5 col-xs-12 text-center offset-sm-2">
      <p class="title-1">
        <span>Ahorrá con</span>
      </p>
      <img src="assets/img/movistar_disp_logo.png" class="logo-header">
      <div class="border_bottom"></div>
      <p class="title-2">Elegí tu nuevo equipo</p>
    </div>
    <div class="col-sm-5 col-xs-12 text-center text-lg-left" *ngIf="image">
      <img [src]="image.linkBackground" class="phone-img">
    </div>
  </div>
</header>
