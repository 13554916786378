import {SubsidiaryAddressModel} from './subsidiary-address.model';

export class SubsidiaryModel {
  public id: number;
  public tag: string;
  public name: string;
  public addresses: SubsidiaryAddressModel[];
  public info: {venta: string, reclamos: string, showTitle: boolean};
  public payment: {hb: HomeBankingModel[], th: string[], cp: PaymentCenterModel[] };
  public lat: number; // latitud y longitud de la ciudad
  public long: number;
  public polygon: any[]; // poligono para checkear localización. [ sup_izq, sup_der, inf_izq, inf_der]

  constructor(id: number, tag: string, name: string, lat = 0, long = 0) {
    this.id = id;
    this.tag = tag;
    this.name = name;
    this.addresses = [];
    this.info = {venta: null, reclamos: null, showTitle: true};
    this.payment = { hb: [], th: [], cp: []};
    this.lat = lat;
    this.long = long;
    this.polygon = [];
  }

  allowOnlinePayment(): boolean {
    return  this.tag !== 'san';
  }

  get isOtt(): boolean {
    return this.tag === 'ott';
  }
}

export class HomeBankingModel {
  public icon: string;
  public description: string;
  public href: string;


  constructor(icon, desc, href) {
    this.icon = icon;
    this.description = desc;
    this.href = href;
  }

  static get link() {
    return new HomeBankingModel('pago_link.png', 'Aboná en Linkpagos o en un cajero Link.', 'https://www.redlink.com.ar/link_pagos_y_cobranzas.html');
  }
  static get banelco() {
    return new HomeBankingModel('pago_pago_mis_cuentas.png', 'Aboná en PagoMisCuentas o en un cajero Banelco.', 'https://pagomiscuentas.com/');
  }
  static get credito() {
    return new HomeBankingModel('tarjeta_de_credito.png', 'Aboná con tarjeta de crédito o débito.', 'cliente/ingreso');
  }
}

export class PaymentCenterModel {
  public icon: string;
  public href: string;

  constructor(icon, href) {
    this.icon = icon;
    this.href = href;
  }
}
