import { Component, OnInit } from '@angular/core';
import { ProductFamilyModel } from 'src/app/models/product/product-family.model';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FamiliesService } from 'src/app/providers/products/families.service';
import { BaseComponent } from 'src/app/core/components/base-component';
import { takeUntil } from 'rxjs/operators';
import { ProductModel } from 'src/app/models/product/product.model';

@Component({
  selector: 'app-cart-family-products',
  templateUrl: './cart-family-products.component.html',
  styleUrls: ['./cart-family-products.component.scss']
})
export class CartFamilyProductsComponent extends BaseComponent implements OnInit {

  family: ProductFamilyModel;
  title: string;
  constructor(private _activeRoute: ActivatedRoute,
              private router: Router,
              private familyService: FamiliesService) {
                super();
              }

  ngOnInit() {
    super.ngOnInit();

      this._activeRoute.params
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe((params: Params) => {
              if (params['familyId']) {
                  const familyId = +params['familyId'];
                  this.family = this.familyService.getFamily(familyId);
                  this.title = `${this.family.name}`;
              } else {
                  this.goBack();
              }
          });
  }

  goBack(): void {
      this.router.navigate(['../..'], { relativeTo: this._activeRoute});
  }

  seeProduct(product: ProductModel): void {
      this.router.navigate([`${product.id}`], { relativeTo: this._activeRoute});
  }

}
