<app-modal-header *ngIf="!embebed" [title]="title" (close)="onClose()"></app-modal-header>

<div class="oficina-virtual formulario">
  <div name="formCart" [formGroup]="formCart">
    <div class="container py-4 mb-5">
      <div class="row">
        <div class="col-lg-6">
          <div class="titulo color-primary">Datos personales</div>

          <!-- nombre y apellido -->
          <div class="form-group row ">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary "
              >Nombre y apellido o razón social
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                type="text"
                class="form-control form-control-sm "
                formControlName="name"
                [ngClass]="{ 'is-invalid': validInput('name') }"
                placeholder="Ingresá nombre o razón social..."
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
              >Email
              <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="email"
                class="form-control form-control-sm"
                placeholder="Ingresá email..."
                formControlName="email"
                [ngClass]="{ 'is-invalid': validInput('email') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
              >Teléfono
              <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="tel"
                class="form-control form-control-sm"
                placeholder="(000) 000 0000"
                mask="(000) 000 0000"
                formControlName="phone"
                [ngClass]="{ 'is-invalid': validInput('phone') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
              >Documento
              <span class="required">*</span></label
            >
            <div class="col-sm-3">
              <select
                class="form-control form-control-sm "
                placeholder="Tipo..."
                formControlName="docType"
                [ngClass]="{ 'is-invalid': validInput('docType') }"
              >
                <option selected></option>
                <option *ngFor="let doc of documents">{{ doc.name }}</option>
              </select>
            </div>
            <div class="col-sm-6 input-in-mobile">
              <input
                type="tel"
                class="form-control form-control-sm"
                placeholder="Número..."
                formControlName="docNumber"
                [ngClass]="{ 'is-invalid': validInput('docNumber') }"
              />
            </div>
          </div>

            <div class="form-group row">
                <label
                  for="colFormLabelSm"
                  class="col-sm-3 col-form-label col-form-label-sm color-primary"
                  >Medios de pago
                  <span class="required">*</span>
                </label>
                <div class="col-sm-3">
                  <select
                    class="form-control form-control-sm "
                    placeholder="Tipo..."
                    formControlName="paymentMethod"
                    [ngClass]="{ 'is-invalid': validInput('paymentMethod') }"
                  >
                    <option selected></option>
                    <option value="tc">Tarjeta</option>
                    <option value="cbu">CBU</option>
                  </select>
                </div>
                <div class="col-sm-6 input-in-mobile">
                  <input
                    type="tel"
                    [disabled]="!paymentNumberEnabled"
                    class="form-control form-control-sm"
                    placeholder="Número..."
                    formControlName="paymentNumber"
                    [mask]="paymentMethodMask"
                    [ngClass]="{ 'is-invalid': validInput('paymentNumber') }"
                  />
                </div>
              </div>

        </div>
        <div class="col-lg-6">
          <div class="titulo color-primary">Domicilio para servicio</div>

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
              >Calle<span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Calle..."
                formControlName="street"
                [ngClass]="{ 'is-invalid': validInput('street') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
              >Altura <span class="required">*</span> | Piso | Depto
            </label>
            <div class="col-sm-3 input-in-mobile">
              <input
                type="tel"
                class="form-control form-control-sm"
                placeholder="Altura..."
                formControlName="streetNumber"
                [ngClass]="{ 'is-invalid': validInput('streetNumber') }"
              />
            </div>
            <div class="col-sm-3 input-in-mobile">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Piso..."
                formControlName="streetFloor"
                [ngClass]="{ 'is-invalid': validInput('streetFloor') }"
              />
            </div>
            <div class="col-sm-3 input-in-mobile">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Depto..."
                formControlName="streetDept"
                [ngClass]="{ 'is-invalid': validInput('streetDept') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary align-self-center"
              >Horario contacto<span class="required">*</span></label
            >
            <div class="col-sm-4 align-self-center">
              <input
                type="tel"
                formControlName="timeFrom"
                placeholder="hh:mm"
                class="form-control"
                mask="Hh:m0"
                formControlName="timeFrom"
                [ngClass]="{ 'is-invalid': validInput('timeFrom') }"
              />
            </div>
            <div class="col-sm-1 align-self-center"></div>
            <div class="col-sm-4 input-in-mobile align-self-center">
              <input
                type="tel"
                formControlName="timeUntil"
                placeholder="hh:mm"
                class="form-control"
                mask="Hh:m0"
                formControlName="timeUntil"
                [ngClass]="{ 'is-invalid': validInput('timeUntil') }"
              />
            </div>
          </div>

          <div class="form-group row">
              <label
                for="colFormLabelSm"
                class="col-sm-3 col-form-label col-form-label-sm color-primary"
                >Comentario</label
              >
              <div class="col-sm-9">
                <textarea
                  type="text"
                  rows="4"
                  class="form-control form-control-sm"
                  placeholder="Algo que nos quieras decir...."
                  formControlName="comment"
                ></textarea>
              </div>
            </div>

        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="alert alert-danger" role="alert">
            <i class="fa fa-exclamation-triangle"></i> Los campos marcados con (*) son obligatorios.
          </div>
        </div>
        <div class="col-sm-6 text-right">
          <app-modal-recaptcha
                  (confirmCaptcha)="toggleCaptcha($event)"
          ></app-modal-recaptcha>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
    <button
    [disabled]="!isValidForm"
    href="javascript:void(0)"
    class="btn btn-block text-white bg-color-primary d-block"
    (click)="onConfirm()"
  >
  CONFIRMÁ TU COMPRA
  </button>
</div>
