<section id="content" class="section-with-header lugares-de-pago">
  <div class="section-header">
    <div class="container">
      <h1>Lugares y medios de pago</h1>
    </div>
  </div>

  <div class="container section-content my-5">
    <div class="titulo-seccion color-primary">
      Home Banking
    </div>

    <div class="row">
      <div class="col-sm-4 text-center col-cajero" *ngFor="let hb of subsidiary.payment.hb">
        <div *ngIf="hb.icon"><img [src]="'assets/img/' + hb.icon" class="payment-image"/></div>
        <div class="info color-primary">
         {{hb.description}}
        </div>

        <div><a [href]="hb.href" target="_blank" class="btn btn-seccion bg-color-secondary">INGRESÁ AHORA</a></div>

      </div>
    </div>

    <div class="titulo-seccion color-primary mt-5">
      Tarjetas habilitadas
    </div>

    <div class="row tarjetas">
      <div class="col-auto" *ngFor="let th of subsidiary.payment.th">
        <img [src]="'assets/img/' + th" />
      </div>
      <div class="col-12 col-sm">
        <div class="info color-primary">
          Adherite con tu tarjeta de crédito a nuestro sistema de débito automático o aboná a través de tu cuenta bancaria con débito directo.
        </div>
      </div>
    </div>

    <div class="titulo-seccion color-primary mt-5">
      Centros de pago
    </div>

    <div class="row tarjetas">
      <div class="col-auto" *ngFor="let cp of subsidiary.payment.cp">
        <img [src]="'assets/img/' + cp.icon" (click)="goToExternalLink(cp)" class="cursor-pointer"/>
      </div>
      <div class="col-12 col-sm">
        <div class="info color-primary">
          Aboná tu factura mensual en cualquier sucursal de estas entidades recaudadoras.<br>
          Hacé clic en la imagen y conocé tu sucursal más cercana.
        </div>
      </div>
    </div>
  </div>
</section>
