<section id="content" class="section-with-header nuestra-empresa pb-5">
  <div class="section-header">
    <div class="container">
      <h1>Nuestra Empresa</h1>
    </div>
  </div>

  <div class="container section-content my-5">
    <div class="row">
      <div class="col-md-4">
        <div class="color-primary text-horario">
          Somos una organización dinámica y
          flexible que tiene como fin brindar
          soluciones de comunicación y
          entretenimiento para la familia con la
          premisa de ser la mejor compañía en su
          hogar brindando el mejor
          contenido con la atención personalizada
          y precisa que nuestro cliente necesita.
        </div>

        <img src="assets/img/ic_logo_navbar@2x.png" class="img-fluid logo-express"/>
      </div>
      <div class="col-md-4">
        <div class="color-secondary font-weight-bold">HISTORIA</div>
        <div class="info color-primary mb-2">
          Desde hace varios años CableHogar se ha
          transformado en una empresa de telecomunicaciones
          convergentes que tiene como fin brindar soluciones de
          comunicación y entretenimiento para la familia.
          Nuestro centro de entretenimiento Express nace con el
          objetivo de darle diversión, comunicación y
          entretenimiento pleno en la comodidad del hogar,
          todo por un mismo cable.
        </div>
        <div class="info">
          Cuando hablamos de CableHogar, hablamos de
          cercanía, somos rosarinos y trabajamos para
          rosarinos, sabemos cuánto nos gusta ser bien
          recibidos y personalizar esa atención es para nuestra
          empresa una premisa que se mantiene desde sus
          inicios. Cuando hablamos de express hablamos de lo
          máximo en entretenimiento, de mirar la mejor
          televisión, navegar con la máxima velocidady hablar
          fácil y económico con quien quieras, donde quieras. <br><br>
          Desde 2013 juntamos la atención de siempre con el
          mejor entretenimiento, una combinación mágica para
          hacer de tu hogar el mejor lugar para divertirse en
          familia.
        </div>
      </div>
      <div class="col-md-4">
        <div class="color-secondary font-weight-bold">INVERSORES</div>
        <div class="color-primary mb-2 info">
          Los fundadores y accionistas de TVSA son el Sr.
          Marcelo Bandiera con una participación del 50% y el Sr.
          Gabriel Bandiera con el restante 50%.
        </div>
        <div class="info">
          El liderazgo de sus accionistas se manifiesta en la
          gestión de Chairman que desempeña el Sr. Gabriel
          Bandiera y la participación en la junta de directores del
          Sr. Marcelo Bandiera. <br><br>
          Tanto el Sr. Marcelo Bandiera como el Sr. Gabriel
          Bandiera fueron responsables en la gestión de
          empresas del sector tales como: Cintelba (Distribudora
          Paramount Latinoamerica), Canal 7 Bahia Blanca,
          Imagen Satelital (actual Claxson, generadora de
          contenidos para tv), Canal 8 de Tucumán, Canal 8 de
          Mar del Plata y el desarrollo de Canales de Televisión
          por Cable en las ciudades de Asunción, Corrientes,
          Junin, Mar del Plata, Córdoba, Rosario, Salta y Santiago
          del Estero, entre otras. <br><br>
          CARPETA ACCESO PÚBLICO Res. N° 173/10 AFSCA.
          Dirección Nacional de Protección de Datos Personales.
        </div>
      </div>
    </div>


    <div class="titulo-seccion color-primary mt-5">
      Nuestros Valores
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="color-secondary font-weight-bold">FOCALIZAR EN EL CLIENTE</div>
        <div class="color-primary mb-2 info">
          Nuestro foco está puesto en el beneficio de nuestro
          cliente. Somos su compañía y los ayudamos a
          comunicarse con los demás. A conocer, a compartir, a
          disfrutar, a entretenerse, a sentirse cerca.
        </div>

        <div class="color-secondary font-weight-bold">HACER COMPAÑÍA</div>
        <div class="color-primary mb-2 info">
          Creemos que comunicarnos es hacernos compañía.
        </div>
      </div>
      <div class="col-md-4">
        <div class="color-secondary font-weight-bold">PERSONALIZAR LA ATENCIÓN</div>
        <div class="color-primary mb-2 info">
          Sabemos cuanto nos gusta ser bien recibidos y
          personalizar esa atención es para nuestra empresa
          una premisa que se mantiene desde sus inicios.
        </div>

        <div class="color-secondary font-weight-bold">MEJORA CONTINUA</div>
        <div class="color-primary mb-2 info">
          Todas estas motivaciones hacen que apuntemos a la
          superación continua en calidad de prestaciones,
          desarrollo de la red y atención al cliente.
        </div>
      </div>
      <div class="col-md-4">
        <div class="color-secondary font-weight-bold">VINCULAR LA TECNOLOGÍA CON EL ENTRETENIMIENTO</div>
        <div class="color-primary mb-2 info">
          Apuntamos a vincular la tecnología con el entretenimiento buscando nuevas formas de relacionarnos.
        </div>
      </div>
    </div>

    <div class="titulo-seccion color-primary mt-5">
      Nuestros Medios
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="logo text-center">
          <img src="assets/img/empresa_canal4.png" />
        </div>

        <div class="color-secondary font-weight-bold text-center">Canal 4</div>
        <div class="color-primary mb-2 text-center info">
          Información independiente. La mejor cobertura local y regional.
        </div>

      </div>
      <div class="col-md-4">
        <div class="logo text-center">
          <img src="assets/img/empresa_notiexpress.png" />
        </div>

        <div class="color-secondary font-weight-bold text-center">Portal de Noticias</div>
        <div class="color-primary mb-2 text-center info">
          La mejor cobertura de los hechos locales
          y nacionales. Newsletter diario.
          Noticias y servicios en un mismo lugar.
          Bolsa de trabajo, buscador inmobiliario y clasificados.
        </div>
      </div>
      <div class="col-md-4">
        <div class="logo text-center">
          <img src="assets/img/empresa_fmexpress.png" />
        </div>

        <div class="color-secondary font-weight-bold text-center">FM Express 101.5</div>
        <div class="color-primary mb-2 text-center info">
          Cuenta con programación diaria de informativos y
          magazines y la mejor música. Además, se emite en
          duplex por Canal 4.
        </div>
      </div>
    </div>
  </div>
</section>