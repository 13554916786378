import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {FaqComponent} from '../../../../main/static-sections/faq/faq.component';

@Component({
    selector: 'app-header-menu-client',
    templateUrl: './menu-client.component.html',
    styleUrls: ['./menu-client.component.scss']
})
export class MenuClientComponent implements OnInit {

    @Output() goToVirtualOffice: EventEmitter<void> = new EventEmitter<void>();
    @Output() goToStaticSection: EventEmitter<string> = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {
    }

    onGoToVirtualOffice() {
        this.goToVirtualOffice.emit();
    }

    onGoToStaticSection(section: string) {
        this.goToStaticSection.emit(section);
    }
}
