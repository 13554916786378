import { InitialBannerComponent } from './../../procedures/initial-banner/initial-banner.component';
import { Component, OnInit } from '@angular/core';
import { GraphsService } from 'src/app/providers/graphs.service';
import { SubsidiaryService } from 'src/app/providers/data/subsidiary.service';
import { BaseComponent } from 'src/app/core/components/base-component';
import { GraphModel } from '../../models/data/graph.model';
import { LayoutService } from 'src/app/providers/layout.service';
import { environment } from '../../../environments/environment';
import { ModalHelperFunctions } from '../../helpers/modal-functions';
import { take, takeUntil } from 'rxjs/operators';
import { GeneralResponse } from '../../models/general-response.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FamiliesService } from '../../providers/products/families.service';
import { Router } from '@angular/router';
import { ModalContactComponent } from '../procedures/modal-contact/modal-contact.component';
import { BomsService } from '../../providers/products/boms.service';
import { BomModel } from '../../models/product/bom.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {

  private bsModalRef: BsModalRef;
  private boms: BomModel[] = [];
  fixedBannersToShow = 0;

  constructor(private graphsService: GraphsService,
    private layoutService: LayoutService,
    private subsidiaryService: SubsidiaryService,
    private modalService: BsModalService,
    private familyService: FamiliesService,
    private router: Router,
    private bomsService: BomsService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.boms = this.bomsService.bomsForList;
    if (this.boms) {
      this.prepareBoms();
    }
    this.subsBoms();
    this.layoutService.showFooter = true;
    this.showBanner();
  }


  showBanner(): void {
    this.bsModalRef = this.modalService.show(InitialBannerComponent, {
      class: 'fit-content'
    });
  }

  subsBoms(): void {
    this.bomsService.bomsChange.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      this.boms = this.bomsService.bomsForList;
      this.prepareBoms();
    });
  }

  prepareBoms(): void {
    this.boms = this.boms.filter(x => x.home);
    this.fixedBannersToShow = 0;
    if (this.boms.length > 3) {
      this.boms = this.boms.slice(0, 3)
    }
    if (this.boms.length < 3) {
      // en caso de que haya menos de 3 banners va agregando las nenas que estaban antes al final
      this.fixedBannersToShow = 3 - this.boms.length;
    }
  }

  goToVirtualOffice() {
    this.router.navigate(['/oficina-virtual/panel']);
  }

  goToFinder() {
    this.router.navigate(['/buscador-de-programacion']);
  }

  goToRecomendador() {
    this.router.navigate(['/recomendador/home']);
  }

  goToElectronicBill() {
    this.router.navigate(['/oficina-virtual/cuenta-corriente/listado']);
  }

  showDnu(): void {
    window.open(environment.externalUris.dnu, '_blank');
  }

  get isCorporative(): boolean {
    return (this.subsidiaryService.corporative === 1);
  }

  get seccionMediaIzq(): GraphModel {
    return this.graphsService.seccionMediaIzq;
  }
  get seccionMediaDer(): GraphModel {
    return this.graphsService.seccionMediaDer;
  }
  get carousel(): GraphModel[] {
    return this.graphsService.carousel;
  }

  seePlans(tag) {
    if (this.isCorporative) {
      this.openModal();
    } else {
      switch (tag) {
        case 'now':
          window.open(environment.externalUris.now, '_blank');
          ///https://now.express.com.ar/
          break;
        case 'futbol':
          let link = '';
          switch (this.subsidiaryService.currentSubsidiary.tag) {
            case 'cbh':
              this.router.navigate(["carro-compras/productos/detalle/2/22"]);
              break;
            case 'sl':
              this.router.navigate(["carro-compras/productos/detalle/2/782"]);
              break;
            case 'san':
              this.router.navigate(["carro-compras/productos/detalle/2/503"]);
              break;
            case 'dtv':
              this.router.navigate(["carro-compras/productos/detalle/2/314"]);
              break;
          }
          break;
        case 'prods-by-day' :
          window.open(environment.externalUris.principalPanel, '_blank');
          break;
        default:
          const family = this.familyService.getFamilyByTag(tag);
          this.router.navigate([`carro-compras/productos/detalle/${family.id}`]);
          break;
      }

    }
  }

  openModal() {
    const initialState = {
      title: 'SOLICITUD DE CONTACTO'
    };
    this.bsModalRef = this.modalService.show(ModalContactComponent, {
      ...environment.modalConfig,
      initialState
    });
    // this.bsModalRef.setClass('modal-xl');

    // m subscribo a la respuesta del modal
    this.bsModalRef.content.onCloseModal
      .pipe(take(1))
      .subscribe((response: GeneralResponse) => {
        if (response) {
          const result = ModalHelperFunctions.showModalResult(
            response.error
              ? 'Solicitud de contacto no enviada'
              : 'Tu solicitud fue enviada',
            response.message,
            response.error,
            this.modalService
          );
          // m subscribo a la respuesta del modal result
          result.content.onCloseModal.pipe(take(1)).subscribe(() => { });
        }
      });
  }

  showBom(bom): void {
    this.router.navigate(['carro-compras/promos/detalle/' + `${bom.id}`]);
  }
}
