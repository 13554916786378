import { Serializable } from 'src/app/core/models/serializable.model';


export class GraphModel extends Serializable {

    id: number;
    title: string;
    linkBackground: string;
    linkImageLeft: string;
    linkImageRight: string;
    buttonText: string;
    buttonColor: string;
    corporative: boolean;
    order: number;
    link: string;
    section: any;

    newModel(data: any): GraphModel {
        const dataParsed = new GraphModel(data);

        return dataParsed;
    }
}
