import { Serializable } from 'src/app/core/models/serializable.model';
import { SubsidiaryModel } from '../data/subsidiary.model';
import { ProductModel } from '../product/product.model';
import { PlansService } from 'src/app/providers/products/plans.service';
import { HelperFunctions } from 'src/app/helpers/functions';
import { DeviceModel } from '../product/device.model';


export class ConnectionModel extends Serializable {

    id: number;
    code: number;
    street: string;
    number: string;
    floor: string;
    dept: string;
    nowUser: string;
    nowPassword: string;
    nowState: string;

    plans: ProductModel[];
    products: ProductModel[];
    devices: DeviceModel[];

    newModel(data: any): ConnectionModel {
        const dataParsed = new ConnectionModel(data);

        if (data.products) {

          // los productos vienen con los opcionales
          dataParsed.products = ProductModel.createArray(data.products, new ProductModel());

          // separo los planes con adicionales
          dataParsed.plans = this.fillPlans(dataParsed.products);
        }

        if (data.devices) {
          dataParsed.devices = DeviceModel.createArray(data.devices, new DeviceModel());
        }

        return dataParsed;
    }

    /**
     * Devuelve la direccion para mostrar
     */
    get fullAddress(): string {
      return (this.street) ? `${this.street} ${this.number} ${this.floor} ${this.dept}` : 'Virtual';
    }

    getBestPlanForFamily(familyId: number): ProductModel | null {
      const familyPlans = this.plans.filter(p => p.family.id === familyId);
      if (familyPlans) {
        // lo ordeno por peso y devuelvo el primero
        familyPlans.sort(HelperFunctions.compareValues('weight', 'desc'));
        return familyPlans[0];
      } else {
        return null;
      }
    }

    /**
     * crea los planes para la coneccion a partir de los productos
     */
    private fillPlans(products: ProductModel[]): ProductModel[] {

      let plans: ProductModel[] = [];

      // tomo los planes de los productos y los agrego como planes con todos los opcionales
      plans = products.filter(p => p.isPrincipal === true);

      // para cada plan busco en los productos, los q sean de la familia, no principales y vendibles
      // y si coninciden con un opcional del producto => lo seteo como selected.
      for (const plan of plans) {
        for (const optional of  products.filter(p =>
              p.isPrincipal === false &&
              p.saleable === true &&
              p.family.id === plan.family.id)) {
                // lo busco en los opcionales del plan, si no lo tiene lo pongo como q lo tiene.
                const optional_in_plan = plan.optionals.find(plan_opt => plan_opt.id === optional.id);
                if (optional_in_plan) {
                  optional_in_plan.selected = true;
                }
          }
          // ordeno los planes -> primero los seleccionados
          plan.optionals.sort(HelperFunctions.compareValues('selected', 'desc'));
      }

      return plans;
    }

    get internetDevices(): DeviceModel[] {
      if (this.devices != null) {
        return this.devices.filter(d => d.type === DeviceModel.INTERNET_DEVICE);
      }
      return  [];
    }
}
