import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductModel } from 'src/app/models/product/product.model';

@Component({
  selector: 'app-family-product-component',
  templateUrl: './family-product-component.component.html',
  styleUrls: ['./family-product-component.component.scss']
})
export class FamilyProductComponentComponent implements OnInit {

  @Input() title: string;
  @Input() plans: ProductModel[];

  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectPlan: EventEmitter<ProductModel> = new EventEmitter<ProductModel>();
  constructor() { }

  ngOnInit() {
  }

  onGoBack() {
    this.goBack.emit();
  }

  onSelectPlan(plan: ProductModel) {
    this.selectPlan.emit(plan);
  }

}
