<footer class="text-center pt-4 pb-4">
  <div class="row w-100 m-0">
    <div class="col-12">
      <img src="assets/img/Iconos-sin-fondo.png" class="logo-footer mb-2">
      <p class="title-1 m-0">Sumá también</p>
      <h2 class="title-2 m-0">HD + INTERNET + TELEFONÍA</h2>
      <p class="title-1 m-0">y obtené más descuentos!</p>

      <button href="javascript:void(0)"
        class="btn btn-text-small bg-color-white mt-4">
        Más info
      </button>
    </div>
  </div>
</footer>
