import { Component, OnInit } from '@angular/core';
import { ClientModel } from '../../../models/client/client.model';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PdfService } from '../../../shared/services/pdf.service';
import { ClientService } from '../../../providers/auth/client.service';
import { AccountService } from '../../../providers/auth/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../environments/environment';
import { GeneralResponse } from '../../../models/general-response.model';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-modal-pay-online',
    templateUrl: './modal-pay-online.component.html',
    styleUrls: ['./modal-pay-online.component.scss']
})
export class ModalPayOnlineComponent implements OnInit {

    title: string;
    selectedValue: string;
    currentClient: ClientModel;
    creditCard = { id: 0, name: 'Indica la tarjeta que vas a utilizar' };

    public onCloseResponse: Subject<{ selectedValue: string, creditCard: number }>;

    constructor(private bsModalRef: BsModalRef,
        private pdfService: PdfService,
        private clientsService: ClientService,
        private accountService: AccountService,
        private spinner: NgxSpinnerService,
        private toastService: ToastrService) {
    }

    ngOnInit() {
        this.onCloseResponse = new Subject();
        this.spinner.show();
        this.clientsService.getCurrentClientData().subscribe(
            (response: GeneralResponse) => {
                if (!response.error) {
                    this.currentClient = this.clientsService.currentClient;
                } else {
                    this.toastService.error(response.message);
                    this.bsModalRef.hide();
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toastService.error(err.message);
                this.bsModalRef.hide();
            }
        );
    }

    showAmounts(): boolean {
        return (this.currentClient && (+this.currentClient.paymentLast !== 0 ||
            +this.currentClient.paymentTotal !== 0 ||
            +this.currentClient.paymentMin !== 0));
    }

    onClose() {
        this.bsModalRef.hide();
    }

    get creditCards() {
        switch (this.clientsService.currentClient.subsidiary.tag) {
            case 'cbh':
                return environment.credit_cards_cbh;
            case 'sl':
                return environment.credit_cards_sl;
            case 'san':
                return environment.credit_cards_san;
            case 'dtv':
                return environment.credit_cards_dtv;
        }
    }

    onSetCreditCard(creditCard) {
        this.creditCard = creditCard;
    }

    pad(input, length, padding) {
        let str = input + '';
        return (length <= str.length) ? str : this.pad(str + padding, length, padding);
    }

    OnPayOnline(): void {
        this.onCloseResponse.next({ selectedValue: this.selectedValue, creditCard: null });
        this.onClose();
    }

    get hasPaymentLast(): boolean {
        return (this.currentClient.paymentLast !== 0);
    }

    get hasPaymentMin(): boolean {
        return (this.currentClient.paymentMin !== 0);
    }

    get hasPaymentTotal(): boolean {
        return (this.currentClient.paymentTotal !== 0);
    }

    get paymentEnabled(): boolean {
        //return (this.selectedValue && this.creditCard.id !== 0);
        return (this.selectedValue != null);
    }

}
