<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>
<div class="modal-body">
  <div class="container py-4 mb-5 oficina-virtual formulario">
    <!--<div class="row">-->
    <!--<div class="col-12">-->
    <!--<div class="titulo color-primary">{{title}}</div>-->
    <!--</div>-->
    <!--</div>-->
    <div *ngIf="showAmounts()">
      <div>
        <h4>Marcá la opcion que prefieras</h4>
      </div>
      <form>
        <div class="payment-opt" *ngIf="hasPaymentLast">
          <input type="radio" id="lastPayment" name="lastPayment" [value]="'last'" [(ngModel)]="selectedValue" >
          <label for="lastPayment" class="font-size-1rem">
            &nbsp;&nbsp;&nbsp; Última factura
            <strong class="color-primary">
              ${{currentClient.paymentLast}}
            </strong>
          </label>
        </div>

        <div class="payment-opt" *ngIf="hasPaymentMin">
          <input type="radio" name="minPayment" id="minPayment" [value]="'min'" [(ngModel)]="selectedValue" >
          <label for="minPayment" class="font-size-1rem">
            &nbsp;&nbsp;&nbsp; Monto mínimo
            <strong class="color-primary">
              ${{currentClient.paymentMin}}
            </strong>
          </label>
        </div>

        <div class="payment-opt" *ngIf="hasPaymentTotal">
          <input type="radio" name="totalPayment" id="totalPayment" [value]="'total'" [(ngModel)]="selectedValue">
          <label for="totalPayment" class="font-size-1rem">
            &nbsp;&nbsp;&nbsp; Total
            <strong class="color-primary">
              ${{currentClient.paymentTotal}}
            </strong>
          </label>
        </div>

        <!-- <div dropdown class="dropdown show  form-control select-accion">
          <a
                  id="dropdownMenuLink"
                  dropdownToggle
                  (click)="(false)"
                  aria-controls="conectionsDropdown"
                  class="btn dropdown-toggle dd-extend  d-md-block"
                  href="javascript:void(0);"
          >{{ creditCard.name }}

          </a>

          <div
                  id="conectionsDropdown"
                  *dropdownMenu
                  class="dropdown-menu"
                  role="menu"
                  aria-labelledby="dropdownMenuLink"
          >
            <a
                    *ngFor="let cc of creditCards"
                    class="dropdown-item"
                    href="javascript:void(0);"
                    (click)="onSetCreditCard(cc)"
            >
              {{cc.name}}
            </a>
          </div>
        </div> -->

      </form>
      <button class="btn btn-block btn-blue mt-4" type="submit" name="pay" (click)="OnPayOnline()" [disabled]="!paymentEnabled">PAGAR</button>
    </div>

    <div *ngIf="!showAmounts()">
      <h5>Estás al día.</h5>
    </div>
  </div>
</div>
