import { Component, OnInit } from '@angular/core';
import {DeviceModel} from '../../../models/movistar/device.model';
import {BaseComponent} from '../../../core/components/base-component';
import {DevicesService} from '../../../providers/movistar/devices.service';
import {takeUntil} from 'rxjs/operators';
import {GeneralResponse} from '../../../models/general-response.model';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {GraphsService} from '../../../providers/graphs.service';
import {GraphModel} from '../../../models/data/graph.model';

@Component({
  selector: 'app-movistar-landpage',
  templateUrl: './movistar-landpage.component.html',
  styleUrls: ['./movistar-landpage.component.scss']
})
export class MovistarLandpageComponent extends BaseComponent implements OnInit {

  devices: DeviceModel[];

  constructor(private devicesService: DevicesService,
              private subsidiaryService: SubsidiaryService,
              private spinner: NgxSpinnerService,
              private graphsService: GraphsService) {
    super();
  }

  ngOnInit() {
    this.getDevices();
    this.getGraph();
    this.subsidiaryService.changeSubsidiary
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
         this.getDevices();
      });
  }

  get itemsPerSlide(): number {
    return this.isMobileScreen(500) ? 1 : 4;
  }

  getDevices(): void {
    this.spinner.show();
    this.devicesService.getAll(this.subsidiaryService.currentSubsidiary.id)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((response: GeneralResponse) => {
        if (!response.error) {
          this.devices = response.data;
          this.devices = this.devices.sort((c1, c2) => {
            if (c1.order > c2.order) {
              return 1;
            }
            if (c1.order < c2.order) {
              return -1;
            }
            return 0;
          });
        }
      }, () => {
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
      });
  }

  getGraph(): void {
    this.spinner.show();
    this.graphsService.getGraphs(this.subsidiaryService.currentSubsidiary.id, 0)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((response: GeneralResponse) => {},
        () => {},
        () => {
          this.spinner.hide();
        });
  }

  get bannerHeader(): GraphModel {
    return this.graphsService.movistar;
  }

}
