<div class="carrito" [ngClass]="{'carrito-in-tab': !isBom}" *ngIf="product">

    <div class="nav-carrito nav-carrito-in-tab cursor-pointer" (click)="onGoBack()">
        <div class="container titulo font-size-1rem container-back-to-buy">
            <a class="cursor-pointer" ><i class="fa fa-arrow-left"></i></a>
            <span class="text-uppercase">{{product.name}}</span>
        </div>
    </div>

    <div class="lista-productos detalle-plan content-carrito py-4">
        <div class="titulos-tabla">
            <div class="row">
                <div class="col-12">
                    <div class="float-left">PRODUCTO</div>
                    <div class="float-right">COSTO MENSUAL</div>
                </div>
            </div>

        </div>

        <ul class="px-0 my-0">
            <li>
                <div class="row">
                    <div class="col-9">
                        <div class="row no-gutters">
                            <div class="col-auto">
                                <img [src]="product.iconUrl" class="img-fluid" [alt]="product.name">
                            </div>
                            <div class="col align-self-center text-left">
                                <div class="card-block px-2">
                                    <div class="nombre-plan text-uppercase">{{product.name}}</div>
                                    <div class="descripcion-plan">{{product.description}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 align-self-center">
                        <div class="precio color-primary float-right" [ngClass]="{'precio-tachado': isBom}">{{product.price | currency:'es-AR':'$'}}</div>
                    </div>
                </div>
            </li>
        </ul>

        <app-optionals
          *ngIf="optionalsOriginal.length > 0"
          [family]="this.family"
          [optionals]="optionalsOriginal"
          [optionalsSelected]="optionalsSelected"
          (changeSelectedOptional)="onChangeSelectedOptional($event)">
        </app-optionals>
    </div>


    <div class="container" *ngIf="this.family.isCable">
        <div class="row">
        <div class="col-sm-12">
          <div class="grilla">
              <div class="titulo color-primary font-weight-bold">GRILLA DE CANALES {{product.name}}</div>
              <div class="subtitulo">Incluídos en el PLAN {{product.name}}</div>
              <app-channels
                [channels]="product.channels"
              ></app-channels>
          </div>
      </div>
    </div>

      <div class="row">
        <div class="col-sm-6" *ngFor="let optional of currentProduct().optionalsWithChannels">
            <div class="grilla">
                <div class="titulo color-primary font-weight-bold">{{optional.name}} (OCASIONAL)</div>
                    <div class="subtitulo">{{optional.description}}</div>
                    <app-channels
                    [channels]="optional.channels"
                  ></app-channels>
            </div>
          </div>
        </div>
    </div>

    <div class="container" >
        <app-terms-conditions
        [terms]="currentProduct().planTermsAndConditions">
      </app-terms-conditions>
    </div>

</div>
