import {Component, OnDestroy, OnInit} from '@angular/core';
import { SubsidiaryService } from 'src/app/providers/data/subsidiary.service';
import { SubsidiaryModel } from 'src/app/models/data/subsidiary.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-header-subsidiaries',
  templateUrl: './header-subsidiaries.component.html',
  styleUrls: ['./header-subsidiaries.component.scss']
})
export class HeaderSubsidiariesComponent implements OnInit, OnDestroy {

  public componentDestroyed = new Subject();
  currentSubsidiary: SubsidiaryModel;

  constructor(private subsidiariesService: SubsidiaryService) { }

  ngOnInit() {
    this.currentSubsidiary = this.subsidiariesService.currentSubsidiary;

    this.subsidiariesService.changeSubsidiary
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(
        (subsidiary: SubsidiaryModel) => {
          this.currentSubsidiary = subsidiary;
        },
        (err) => { console.log(err); },
        () => { }
      );
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
  subsidiaries(): SubsidiaryModel[] {
    return this.subsidiariesService.subsidiaries;
  }

  onChangeSubsidiary(selectedSubsidiary: SubsidiaryModel): void {
    if (this.currentSubsidiary.id !== selectedSubsidiary.id) {
      this.subsidiariesService.currentSubsidiary = this.currentSubsidiary = selectedSubsidiary;
    }
  }
}
