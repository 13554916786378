import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MovistarRoutingModule } from './movistar-routing.module';
import { MovistarLandpageComponent } from './movistar-landpage/movistar-landpage.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MovistarHeaderComponent } from './components/movistar-header/movistar-header.component';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import { MovistarFormComponent } from './components/movistar-form/movistar-form.component';
import { MovistarFooterComponent } from './components/movistar-footer/movistar-footer.component';
import {RecaptchaComponent} from '../procedures/components/recaptcha/recaptcha.component';
import {ProceduresModule} from '../procedures/procedures.module';

@NgModule({
  declarations: [
    MovistarLandpageComponent,
    MovistarHeaderComponent,
    MovistarFormComponent,
    MovistarFooterComponent
  ],
  imports: [
    CommonModule,
    MovistarRoutingModule,
    HttpClientModule,
    FormsModule,
    CarouselModule.forRoot(),
    ReactiveFormsModule,
    ProceduresModule
  ],

})
export class MovistarModule { }
