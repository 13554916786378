<section id="content" class="section-with-header consejos-utiles pb-5">
  <div class="section-header">
    <div class="container">
      <h1>Consejos Útiles</h1>
    </div>
  </div>

  <div class="container section-content my-5">

    <!--TELEVISION-->
    <div class="titulo-seccion color-primary">
      Televisión
    </div>

    <accordion [closeOthers]="true" class="accordion">
      <accordion-group heading=" TV BÁSICO" id="headingOne">
        <div>

          <div class="color-primary titulo">Inconvenientes con la señal</div>
          En primer lugar, tenés que comprobar que la conexión del cable
          está correcta. En el caso de que la imagen se vea con defectos,
          le falte color o aparezcan líneas, debés ajustar la sintonía del
          aparato; y si no se ven los canales, verificá que esté activado en
          TV/VCR.
          <div class="color-primary titulo">Recomendaciones</div>
          En el caso de tormentas eléctricas, desconectá el cable coaxial
          y todos los aparatos. Cabe aclarar que los desperfectos
          ocasionados por fenómenos meteorológicos no son
          responsabilidad de la empresa.

          <!--<div class="color-primary titulo">SI NO TIENE SEÑAL</div>-->
          <!--Verifique que el cable de ingreso de la señal de Express a su TV está correctamente conectado. Si la conexión es correcta y la señal no aparece, puede solicitar ayuda a nuestros técnicos.-->

          <!--<div class="color-primary titulo">IMAGEN CON DEFECTOS </div>-->
          <!--Su televisor puede tener las siguientes dificultades: líneas, ruidos en el sonido, falta de color, etc. Ajuste la sintonía.-->

          <!--<div class="color-primary titulo">NO SE VEN LOS CANALES</div>-->
          <!--Si su aparato tiene CATV, verifique que esté activado TV/VCR ó el CATV y el canal seleccionado.-->

          <!--<div class="color-primary titulo">IMAGEN CON FASTASMA</div>-->
          <!--Si el problema está en todos los canales, compruebe que los conectores de los cables que ingresan y salen de su TV, VCR estén bien ajustados.-->

          <!--<div class="color-primary titulo">IMAGEN CON LLUVIA</div>-->
          <!--Verifique que la conexión de la TV o VCR estén bien ajustados. Si luego de esta verificación el problema permanece, puede solicitar ayuda a nuestros técnicos.-->

          <!--<div class="color-primary titulo">RECOMENDACIONES EN CASO DE TORMENTAS ELÉCTRICAS</div>-->
          <!--En caso de tormentas eléctricas, es aconsejable desconectar el cable coaxial, conversor, decodificador, home theater, televisor y todo aparato relacionado. <br>-->
          <!--Los desperfectos ocasionados por fenómenos meteorológicos no son responsabilidad de Cablehogar - Teledifusora SA.-->
        </div>
      </accordion-group>

      <accordion-group heading="TV DIGITAL" id="headingTwo">
        <div>
          <!--<div class="color-primary titulo">SI NO TIENE SEÑAL</div>-->
          <!--Desconectá el deco de la electricidad por unos segundos, y luego conectalo nuevamente. <br>-->
          <!--Verificá si este problema se repite en todos los canales y en todos los televisores. <br>-->
          <!--Revisá si las conexiones de tu TV y/o decodificador se encuentran correctas. <br>-->
          <!--Verificá que tu televisor se encuentre en la opción TV/ video.-->

          <!--<div class="color-primary titulo">SI EL DECODIFICADOR NO ENCIENDE</div>-->
          <!--Presioná la tecla STB y luego la tecla Power. <br>-->
          <!--Verificá el estado de las pilas.-->

          <!--<div class="color-primary titulo">GUIAS PARA DESCARGAR</div>-->
          <!--<div><a href="#">Idiomas</a></div>-->
          <!--<div><a href="#">Subtítulos</a></div>-->
          <!--<div><a href="#">Guía en Pantalla</a></div>-->
          <!--<div><a href="#">Control de Padres</a></div>-->

          <!--<div class="color-primary titulo">MANUALES</div>-->
          <!--<div><a href="#">Manual de Usuario AC-N310PD2A</a></div>-->

          <div class="color-primary titulo">Inconvenientes con la señal</div>
          En primer lugar, desconectá el decodificador de la electricidad
          por unos segundos, y luego conectalo nuevamente. Verificá si
          este problema se repite en todos los canales y en todos los
          televisores. Por otra parte, revisá si las conexiones de tu TV y/o
          decodificador se encuentran correctas y corroborá que tu
          televisor se encuentre en la opción TV/ video.

          <div class="color-primary titulo">Inconvenientes con el decodificador</div>
          Si el decodificador no enciende, presioná la tecla STB y luego la
          tecla Power. No te olvides de revisar el estado de las pilas.
        </div>
      </accordion-group>

      <accordion-group heading="TV HD" id="headingThree">
        <div>
          <div class="color-primary titulo">Inconvenientes con la señal</div>
          En primer lugar, desconectá el decodificador de la electricidad
          por unos segundos, y luego conectalo nuevamente. Verificá si
          este problema se repite en todos los canales y en todos los
          televisores. Por otra parte, revisá si las conexiones de tu TV y/o
          decodificador se encuentran correctas y corroborá que tu
          televisor se encuentre en la opción TV/ video.

          <div class="color-primary titulo">Inconvenientes con el decodificador</div>
          Si el decodificador no enciende, presioná la tecla STB y luego la
          tecla Power. No te olvides de revisar el estado de las pilas.
          <!--<div class="color-primary titulo">SI NO TIENE SEÑAL</div>-->
          <!--Desconectá el deco de la electricidad por unos segundos, y luego conectalo nuevamente. <br>-->
          <!--Verificá si este problema se repite en todos los canales y en todos los televisores. <br>-->
          <!--Revisá si las conexiones de tu TV y/o decodificador se encuentran correctas. <br>-->
          <!--Verificá que tu televisor se encuentre en la opción TV/ video.-->

          <!--<div class="color-primary titulo">SI EL DECODIFICADOR NO ENCIENDE</div>-->
          <!--Presioná la tecla STB y luego la tecla Power. <br>-->
          <!--Verificá el estado de las pilas.-->

          <!--<div class="color-primary titulo">GUIAS STB HD ARION PARA DESCARGAR</div>-->
          <!--<div><a href="#">Idiomas</a></div>-->
          <!--<div><a href="#">Subtítulos</a></div>-->
          <!--<div><a href="#">Guía en Pantalla</a></div>-->
          <!--<div><a href="#">Si ves la imagen entre dos barras</a></div>-->
          <!--<div><a href="#">Editar un canal</a></div>-->

          <!--<div class="color-primary titulo">MANUALES STB HD ARION</div>-->
          <!--<div><a href="#">Manual de Usuario ARC-1010H</a></div>-->
          <!--<div><a href="#">Manual de Usuario ARC-5210HR</a></div>-->
          <!--<div><a href="#">Manual de Usuario AC-2710VHD PR</a></div>-->

          <!--<div class="color-primary titulo">GUIAS STB HD ARION PARA DESCARGAR</div>-->
          <!--<div><a href="#">Idiomas</a></div>-->
          <!--<div><a href="#">Subtítulos</a></div>-->
          <!--<div><a href="#">Guía en Pantalla</a></div>-->
          <!--<div><a href="#">Si ves la imagen entre dos barras</a></div>-->
          <!--<div><a href="#">Control de Padres</a></div>-->

          <!--<div class="color-primary titulo">GUIAS STB HD INTEK PARA DESCARGAR</div>-->
          <!--<div><a href="#">Idiomas</a></div>-->
          <!--<div><a href="#">Subtítulos</a></div>-->
          <!--<div><a href="#">Manual de Usuario INTEK HD-20CX</a></div>-->
        </div>
      </accordion-group>
    </accordion>
    <!--FIN TELEVISION-->

    <!--INTERNET-->
    <div class="titulo-seccion color-primary pt-3">
      Internet
    </div>
    <div>
      <div class="color-primary titulo">Consultas técnicas</div>
      En caso de encontrarse con falta de conexión realice la
      siguiente verificación:<br>
      1- Apagá la PC<br>
      2- Desconectá el módem de la electricidad por unos segundos.<br>
      3- Conectá todo nuevamente.<br>
      4- Confirmá el funcionamiento de tu conexión abriendo el
      navegador.<br>
      Si el inconveniente persiste comunicate con nosotros al
      08105553977
    </div>


    <!--<accordion [closeOthers]="true" class="accordion">-->
      <!--<accordion-group heading="Consultas Técnicas" id="headingOne">-->
        <!--<div>-->
          <!--<div class="color-primary titulo">¿QUÉ HAGO SI NO PUEDO NAVEGAR?</div>-->
          <!--En caso de encontrarse con falta de conexión realice la siguiente verificación: <br>-->
          <!--1- Apague la PC<br>-->
          <!--2- Desconecte el módem de la electricidad por unos segundos.<br>-->
          <!--3- Conecte todo nuevamente.<br>-->
          <!--4- Verifique el funcionamiento de su conexión abriendo el navegador.<br>-->
          <!--Si el inconveniente persiste comuniquese con nuestra mesa de ayuda.-->

          <!--<div class="color-primary titulo">CÓMO CONFIGURAR MI CUENTA DE E-MAIL EN:</div>-->
          <!--<div><a href="#">Outlook Express</a></div>-->
          <!--<div><a href="#">Outlook 2000</a></div>-->
          <!--<div><a href="#">Outlook XP</a></div>-->

          <!--<div class="color-primary titulo">MANUALES</div>-->
          <!--<div><a href="#">Manual de Usuario Arris CM550</a></div>-->
          <!--<div><a href="#">Manual de Usuario Arris TM501A</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cable Modem SB900</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cable Modem SBG901</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cable Modem DPR2320R2</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cisco DPC2100</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cisco DPC2203</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cisco DPC2320</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cisco DPC2420</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cisco DPC2425</a></div>-->
          <!--<div><a href="#">Manual de Usuario Cisco DPC-EPC2325</a></div>-->
          <!--<div><a href="#">Manual de Usuario Motorola 5100-5101</a></div>-->
          <!--<div><a href="#">Manual de Usuario SMC8014W-G</a></div>-->
        <!--</div>-->
      <!--</accordion-group>-->

      <!--<accordion-group heading="Consejos de seguridad"  id="headingThree">-->
        <!--<div>-->
          <!--<div class="color-primary titulo">ANTIVIRUS GRATUITOS</div>-->
          <!--<div><a href="http://www.grisoft.com" target="_blank">www.grisoft.com</a></div>-->
          <!--<div><a href="http://www.free-av.com" target="_blank">www.free-av.com</a></div>-->
          <!--<div><a href="http://www.clamav.net" target="_blank">www.clamav.net</a></div>-->
          <!--<div><a href="http://www.avast.com" target="_blank">www.avast.com</a></div>-->
          <!--<div><a href="http://www.openantivirus.org" target="_blank">www.openantivirus.org</a></div>-->
          <!--<div><a href="http://www.bitdefender.es" target="_blank">www.bitdefender.es</a></div>-->

          <!--<div class="color-primary titulo">CALENDARIO DE VIRUS</div>-->
          <!--<div><a href="http://us.mcafee.com" target="_blank">us.mcafee.com</a></div>-->

          <!--<div class="color-primary titulo">VIRUSOMETRO</div>-->
          <!--<div><a href="http://www.pandasecurity.co" target="_blank">www.pandasecurity.co</a></div>-->

        <!--</div>-->
      <!--</accordion-group>-->
    <!--</accordion>-->
    <!--FIN INTERNET-->

    <!--TELEFONIA-->
    <div class="titulo-seccion color-primary pt-3">
      Telefonía
    </div>

    <div>
        <div class="color-primary titulo">Inconvenientes con la señal</div>
      Verificá que tu conexión a Internet funcione correctamente. Si
      no es así, desconectá el módem por 30 segundos, volvé a
      enchufarlo y realizá una llamada.

      <div class="color-primary titulo">Servicio técnico</div>
      Para consultas técnicas marcá el 114 desde tu línea Express.

      <!--<div class="col-md-6">-->
        <!--<div class="pregunta">-->
          <!--No tengo tono en el teléfono-->
        <!--</div>-->
        <!--<div class="respuesta">-->
          <!--Verifica que tu conexión a Internet funcione correctamente. Caso contrario:-->
          <!--Desconecta el módem de la electricidad por 30 segundos.<br>-->
          <!--Volvé a realizar la llamada telefónica. <br>-->
          <!--NOTA: Recordá tener el abono al día para no sufrir suspensiones en el servicio.-->
        <!--</div>-->

        <!--<div class="pregunta">-->
          <!--¿Qué hago si no puedo llamar a un número?-->
        <!--</div>-->
        <!--<div class="respuesta">-->
          <!--Verifica que el número de destino sea el correcto, de ser así aguarda unos minutos e-->
          <!--intenta efectuar la llamada nuevamente. Si el problema persiste anota el número al-->
          <!--cual quieres realizar la llamada, la hora y el mensaje que da la operadora (ejemplo:-->
          <!--“el número solicitado no corresponde a un cliente en servicio”) y comunícate con-->
          <!--nuestro servicio técnico marcado 114 desde tú Línea Express.-->
        <!--</div>-->

      <!--</div>-->

      <!--<div class="col-md-6">-->

        <!--<div class="pregunta">-->
          <!--¿Qué hago si escucho eco o ruidos en la línea?-->
        <!--</div>-->
        <!--<div class="respuesta">-->
          <!--La mayoría de las veces los problemas de sonido son generados por teléfonos-->
          <!--inalámbricos o por algún problema técnico. Intenta conectar otro aparato telefónico-->
          <!--si el problema persiste comunicante con nuestro servicio técnico marcando 114-->
          <!--desde tú Línea Express.-->
        <!--</div>-->

        <!--<div class="pregunta">-->
          <!--¿Cómo hago para verificar mí consumo?-->
        <!--</div>-->
        <!--<div class="respuesta">-->
          <!--Ingresando a Oficina Virtual en la sección “Mis Consumos” podrá acceder al detalle-->
          <!--de todas las llamadas realizadas a través de su línea Express en el último período.-->
          <!--Necesitará registrarse para acceder a ésta información.-->
        <!--</div>-->

      <!--</div>-->
    </div>
    <!--FIN TELEFONIA-->
  </div>

</section>
