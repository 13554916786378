import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

import {BaseService} from '../../core/services/base.service';
import {catchError, map} from 'rxjs/internal/operators';
import {Observable, Subject} from 'rxjs';
import {GeneralResponse} from '../../models/general-response.model';
import {DataModel} from '../../models/data/data.model';
import { ProcedureKindModel } from 'src/app/models/data/procedure-kind.model';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { HelperFunctions } from 'src/app/helpers/functions';

const cacheBusterForList$ = new Subject<void>();

@Injectable({providedIn: 'root'})
export class ProceduresService extends BaseService {

    constructor(public router: Router, public http: HttpClient) {
        super(router);
    }

    /**
     * Envia a crear un tramite
     * @param procedure ProcedureModel => tramite a enviar
     * @param openRoutes boolean => indica si va a ruta abierta o cerrada
     */
    @CacheBuster({
      cacheBusterNotifier: cacheBusterForList$
    })
    saveProcedure(procedure: ProcedureModel,
      openRoutes: boolean = false): Observable<{} | GeneralResponse> {
        let url = `${this._api}client/`;
        if (openRoutes) {
          url = url + 'procedures';
        } else {
          url = url + 'me/procedures';
        }
        return this.http.post<GeneralResponse>(url, procedure.prepareForStore())
            .pipe(
                map(res => {
                    return new GeneralResponse(res.message, res.error, false, null);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }


  @Cacheable({
      cacheBusterObserver: cacheBusterForList$
  })
  getProcedures(): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/me/procedures?include=kind,state,client,connection`;
    return this.http.get<GeneralResponse>(url)
      .pipe(
          map(res => {
            let procedures = null;
            if (!res.error) {
              procedures = ProcedureModel.createArray(res.data, new ProcedureModel());
              // ordeno por id descendente
              procedures.sort(HelperFunctions.compareValues('id', 'desc'));
            }
            return new GeneralResponse(res.message, res.error, false, procedures);
          }),
          catchError(err => {
              return this.handleError(err);
          })
      );
  }

  getProcedure(procedureId: number): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/me/procedures/${procedureId}?include=products,kind,state,data_values,devices`;
    return this.http.get<GeneralResponse>(url)
      .pipe(
          map(res => {
            if (!res.error) {
              const procedure = ProcedureModel.createOne(res.data, new ProcedureModel());
              return new GeneralResponse(res.message, res.error, false, procedure);
            } else {
              return new GeneralResponse(res.message, res.error, false, null);
            }
          }),
          catchError(err => {
              return this.handleError(err);
          })
      );
  }
}
