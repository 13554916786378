import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BaseService} from '../../core/services/base.service';
import { Observable } from 'rxjs';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { map, catchError } from 'rxjs/operators';
import { ClientModel } from 'src/app/models/client/client.model';
import { KeyValueModel } from 'src/app/models/data/key-value.model';

@Injectable({providedIn: 'root'})
export class DirectPaymentService extends BaseService {

    currentClient: ClientModel;

    constructor(public router: Router, public http: HttpClient) {
        super(router);
    }

    login(code: string, subs_id: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}totem/login-totem?include=subsidiary`;
        // const subs_id = localStorage.getItem('subsidiary-totem');
        return this.http.post<GeneralResponse>(url, {dni_code: code, subsidiary_id: subs_id})
            .pipe(
                map((res: any) => {
                    // localStorage.setItem('idClient', res.data.id);
                    // localStorage.setItem('code', res.data.code);
                    // localStorage.setItem('client', JSON.stringify(res.data));
                    this.currentClient = ClientModel.createOne(res.data, new ClientModel());
                    return new GeneralResponse('', false, false, null);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    getAccountPaymentData(
        clientId: number,
        accountId: number,
        selectedPayment: string,
        creditCard: number,
        email: string
    ): Observable<{} | GeneralResponse> {
        const url = `${
            this._api
            }totem/app/${clientId}/accounts/payment/${accountId}/${selectedPayment}`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                if (!res.error) {
                    if (res.data.error) {
                        return new GeneralResponse(res.data.message, true, false, null);
                    } else {
                        const keyValues = KeyValueModel.createArray(
                            res.data,
                            new KeyValueModel()
                        );
                        return new GeneralResponse('', false, false, keyValues);
                    }
                } else {
                    return new GeneralResponse(res.message, true, false, null);
                }
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    setAccountPaid(
        clientId: number,
        response_code: string, 
        response_description: string,
        verificationCode: string  
    ): Observable<{} | GeneralResponse> {
        const url = `${
            this._api
            }totem/${clientId}/accounts/paid`;
        return this.http.put<GeneralResponse>(url,
            {'verification_code': verificationCode, 'response_code': response_code, 'response_description': response_description}
        ).pipe(
            map(res => {
                return new GeneralResponse(res.message, res.error, false, null);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

}