import { Serializable } from '../../core/models/serializable.model';

export class ProcedureStateModel extends Serializable {

    public id: number;
    public tag: string;
    public name: string;
    public description: string;
    public color: string;

    newModel(data: any): ProcedureStateModel {
        return new ProcedureStateModel(data);
    }

    isColor(color: string): boolean {
      return this.color === color;
    }
}
