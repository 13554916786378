<section>
  <div class="row w-100 pt-4 pb-4 m-0">
    <div class="col-12 text-center">
      <h2 class="title">Registrate y te llamamos</h2>
    </div>
    <div class="col-md-4 offset-md-4 col-10 offset-1 pt-2">
      <form name="formChangePassword" [formGroup]="form">

        <!--CURRENT-->
        <div class="form-group">
          <div class="mb-3">
            <input type="text"  formControlName="name" required
                   class="form-control form-control-sm" placeholder="Nombre"
                   [ngClass]="{ 'is-invalid': validInput('name') }">
            <p class="error-msg mb-0" *ngIf="validInput('name')">
              <small>¡Ingresá tu nombre!</small>
            </p>
          </div>

          <div class="mb-3">
            <input type="tel"  formControlName="phone" required
                   class="form-control form-control-sm" placeholder="Teléfono"
                   [ngClass]="{ 'is-invalid': validInput('phone') }">
            <p class="error-msg mb-0" *ngIf="validInput('phone')">
              <small>¡Ingresá tu teléfono!</small>
            </p>
          </div>

          <div class="mb-3">
            <input type="email" formControlName="email" required
                   class="form-control form-control-sm" placeholder="Email"
                   [ngClass]="{ 'is-invalid': validInput('email') }">
              <span *ngIf="form.get('email').touched">
                <p class="error-msg mb-0" *ngIf="form.get('email').hasError('required')">
                  <small>¡Ingresá tu email!</small>
                </p>
                <p class="error-msg mb-0" *ngIf="form.get('email').hasError('email')">
                  <small>¡Ingresá un email válido!</small>
                </p>
              </span>
          </div>

          <div class="mb-3" role="group">
            <p class="m-0">¿Ya sos cliente de Express?</p>
            <div>
              <input id="si" type="radio" formControlName="isClient" [value]="1">
              <label for="si" class="radio-label">&nbsp;Si</label>
            </div>
            <div>
              <input id="no" type="radio" formControlName="isClient" [value]="0">
              <label for="no" class="radio-label">&nbsp;No</label>
            </div>
          </div>

          <div class="text-center mb-2 w-75">
            <app-modal-recaptcha
              (confirmCaptcha)="toggleCaptcha($event)"
            ></app-modal-recaptcha>
          </div>

          <button
            [disabled]="!isValidForm"
            href="javascript:void(0)"
            class="btn btn-block text-white bg-color-secondary d-block rounded"
            (click)="onConfirm()"
          >
            Solicitar
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
