import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { LoginModel } from 'src/app/models/auth/login.model';
import { ResetDataModel } from 'src/app/models/auth/reset-data.model';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { ClientService } from './client.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService extends  BaseService {

  constructor(public router: Router,
              public http: HttpClient,
              public clientService: ClientService) {
      super(router);
  }

  login(login: LoginModel): Observable<{} | GeneralResponse> {
    const url = `${this._api}login`;
    this.clientService.resetClients();
    return this.http.post<GeneralResponse>(url, login)
      .pipe(
          map((res: any) => {
              if (!res.data.verified) {
                  this.logout();
                  return new GeneralResponse('Debe realizar la validación de correo electrónico. Por favor verifique su bandeja de entrada.', true, false, null);
              } else {
                  localStorage.setItem('user', res.data.user);
                  localStorage.setItem('email', res.data.email);
                  localStorage.setItem('token', res.data.token);
                  localStorage.setItem('tokenType', res.data.tokenType);
                  localStorage.setItem('clients', JSON.stringify(res.data.clients));
                  this.clientService.initClients();
                  return new GeneralResponse('', false, false, res.data);
              }

          }),
          catchError(err => {
              return this.handleError(err);
          })
      );
  }

  logout(): void {
    this.http.post<GeneralResponse>(`${this._api}logout`, null)
        .pipe(
            map((response) => {
                return new GeneralResponse('', false, false, response);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        )
        .subscribe(
            () => {
                localStorage.clear();
                location.reload();
            });
  }


    sendResetLink(email): Observable<{} | GeneralResponse> {
        const url = `${this._api}password/mail`;
        const data = {
            'email' : email
        };
        return this.http.post<GeneralResponse>(url, data)
            .pipe(
                map(response => {
                    return new GeneralResponse('', false, false, response);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }


    resetPassword(data: ResetDataModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}password/reset`;
        return this.http.post<GeneralResponse>(url, data.resetPasswordPrepareStore())
            .pipe(
                map(res => {
                    localStorage.setItem('user', res.data.user);
                    localStorage.setItem('email', res.data.email);
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('tokenType', res.data.tokenType);
                    localStorage.setItem('clients', JSON.stringify(res.data.clients));

                    this.clientService.initClients();

                    return new GeneralResponse('', false, false, null);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }


  getToken(): string {
    const token = this.getItemFromStorage('token');
    if (token) {
        return `${this.getItemFromStorage('tokenType')} ${token}`;
    } else {
        return null;
    }
  }

  loggedIn(): boolean {
    return this.getToken() !== null;
  }

}

