import { Serializable } from '../../core/models/serializable.model';

export class CouponModel extends Serializable {

    public codigoEmpresa: string;
    public fechaEmision: string;
    public fechaVencimiento: string;
    public fechaEmisionJuliano: string;
    public fechaVencimientoJuliano: string;
    public numeroCliente: string;
    public numeroComprobante: string;
    public monto: string;
    public recargoPrimerVencimiento: string;
    public recargoSegundoVencimiento: string;
    public diasEntrePrimerSegundoVencimiento: string;
    public diasEntrePrimerTercerVencimiento: string;
    public digitoVerificador: string;
    public barcode: string;
    public montoPesos: string;

    newModel(data: any): CouponModel {
        return new CouponModel(data);
    }

    setBarCode() {
        this.barcode = this.codigoEmpresa + this.monto + this.fechaVencimientoJuliano
            + this.numeroCliente + this.recargoPrimerVencimiento + this.diasEntrePrimerSegundoVencimiento
            + this.recargoSegundoVencimiento + this.diasEntrePrimerTercerVencimiento + this.numeroComprobante;
        this.calcularDigitoVerificador();
        this.barcode += this.digitoVerificador;
    }

    private calcularDigitoVerificador(): void {
        const cadena = '135793579357935793579357935793579357935793579';
        let suma = 0;
        let index = 0;
        for (const i of cadena) {
            suma += (+this.barcode.charAt(index)) * (+i);
            index++;
        }
        const resultado = Math.trunc(suma / 2);
        this.digitoVerificador = resultado.toString().charAt(resultado.toString().length - 1);
    }
}
