import { Serializable } from 'src/app/core/models/serializable.model';
import { SubsidiaryModel } from '../data/subsidiary.model';
import { ConnectionModel } from './connection.model';
import { AccountModel } from './account.model';


export class ClientModel extends Serializable {

    id: number;
    code: number;
    document: string;
    firstName: string;
    lastName: string;
    invoiceElectronic: number;
    invoiceElectronicEmail: string;
    accountBalance: number;

    subsidiary: SubsidiaryModel;

    connections: ConnectionModel[] | null;

    accounts: AccountModel[] | null;

    paymentMin: number;
    paymentTotal: number;
    paymentLast: number;
    creditCardVerified: boolean;

    newModel(data: any): ClientModel {
        const dataParsed = new ClientModel(data);

        if (data.subsidiary) {
          dataParsed.subsidiary = new SubsidiaryModel(data.subsidiary.id, data.subsidiary.tag, data.subsidiary.name);
        }

        dataParsed.connections = null;

        dataParsed.accounts = null;

        dataParsed.paymentMin = +dataParsed.paymentMin;
        dataParsed.paymentTotal = +dataParsed.paymentTotal;
        dataParsed.paymentLast = +dataParsed.paymentLast;


        return dataParsed;
    }

    get fullName(): string {
      return `${this.firstName} ${this.lastName}`;
    }
}
