import { Component, OnInit} from '@angular/core';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { BaseComponent } from 'src/app/core/components/base-component';
import { Router } from '@angular/router';
import { SubsidiaryService } from 'src/app/providers/data/subsidiary.service';
import {environment} from '../../../../environments/environment';
import {take} from 'rxjs/operators';
import {GeneralResponse} from '../../../models/general-response.model';
import {ModalHelperFunctions} from '../../../helpers/modal-functions';
import {ModalHomeGenericRequestComponent} from '../../../main/procedures/modal-home-generic-request/modal-home-generic-request.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

  menuCollapse = false;

  constructor(private authService: AuthService,
              private subsidiaryService: SubsidiaryService,
              private router: Router,
              private bsModalRef: BsModalRef,
              private modalService: BsModalService) {
    super();
  }

  ngOnInit() {
    // super.ngOnInit();
    if (this.isMobileScreen()) {
      this.menuCollapse = true;
    }
  }

  collapseMenu() {
    if (this.isMobileScreen()) {
      this.menuCollapse = true;
    }
  }

  isLogged(): boolean {
    return this.authService.loggedIn();
  }

  logout(): void {
    this.authService.logout();
  }

  goToVirtualOffice() {
    this.navigateTo(['/oficina-virtual/panel']);
  }

  goToStaticSection($event) {
    this.navigateTo([`/${$event}`]);
  }

  goToCart() {
    if (this.authService.loggedIn()) {
      this.navigateTo(['/oficina-virtual/panel/comprar/familias']);
    } else {
      this.navigateTo(['/carro-compras/productos']);
    }
  }

  goToBoms() {
    this.navigateTo(['/carro-compras/promos']);
  }

  goToRecommend() {
    this.navigateTo(['/recomendador/home']);
  }

  goHome() {
    this.navigateTo(['/']);
  }

  navigateTo(route: string[]) {
    this.router.navigate(route);
    this.collapseMenu();
  }

  toggleCorporative() {
    this.subsidiaryService.corporative = (this.subsidiaryService.corporative === 1) ? 0 : 1;
  }

  showHogar() {
    this.subsidiaryService.corporative === 1 ?
    this.subsidiaryService.corporative = 0 : null;
  }

  showCorporative() {
    this.subsidiaryService.corporative === 0 ? this.subsidiaryService.corporative = 1 : null;
  }

  get isCorporative(): boolean {
    return (this.subsidiaryService.corporative === 1);
  }

  get showTurnos(): boolean {
    return !this.isCorporative && !this.isSanLorenzo;
  }

  get isSantiago(): boolean {
    return this.subsidiaryService.currentSubsidiary.tag == 'san';
  }

  get isSanLorenzo(): boolean {
    return this.subsidiaryService.currentSubsidiary.tag == 'sl';
  }

  get tel(): string {
    if (this.isCorporative) {
      return '0810 555 2677';
    } else {
      return '0810 555 3977';
    }
  }

  openRequestModal(): void {
    this.collapseMenu();
    const initialState = {
      title: 'CONSULTA'
    };
    this.bsModalRef = this.modalService.show(ModalHomeGenericRequestComponent, {
      ...environment.modalConfig,
      initialState
    });
    this.bsModalRef.setClass('modal-xl');

    // m subscribo a la respuesta del modal
    this.bsModalRef.content.onCloseModal
      .pipe(take(1))
      .subscribe((response: GeneralResponse) => {
        if (response) {
          const result = ModalHelperFunctions.showModalResult(
            response.error
              ? 'Solicitud de contacto no enviada'
              : 'Tu consulta fue enviada!',
            response.message,
            response.error,
            this.modalService
          );
          // m subscribo a la respuesta del modal result
          result.content.onCloseModal.pipe(take(1)).subscribe(() => {});
        }
      });
  }

  goToTotem(): void {
    window.open('https://www.express.com.ar/totem/ingreso', '_blank');
  }
}
