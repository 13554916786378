import { Injectable } from '@angular/core';
import { ProductFamilyModel } from 'src/app/models/product/product-family.model';
import { ProductModel } from 'src/app/models/product/product.model';
import { Subject } from 'rxjs';
import { HelperFunctions } from 'src/app/helpers/functions';

@Injectable({providedIn: 'root'})
export class FamiliesService {

    familiesChange = new Subject<ProductFamilyModel[]>();

    constructor() {
        this._families = [
            new ProductFamilyModel(2, 'Televisión', 'cable', 'Televisión', 'c-tv'),
            new ProductFamilyModel(9999, 'NOW Express', 'now', 'NOW Express', 'c-now'),
            new ProductFamilyModel(1, 'Internet', 'internet', 'Internet', 'c-internet'),
            new ProductFamilyModel(3, 'Telefonía Fija', 'telefono', 'Telefonía fija', 'c-tel'),
        ];
    }

    // gets and sets
    private _families: ProductFamilyModel[];
    // private _currentFamily: ProductFamilyModel;
    // private currentProduct:
    get families(): ProductFamilyModel[] {
        return this._families;
    }

    // get currentFamily(): ProductFamilyModel {
    //     return this._currentFamily;
    // }

    // public setCurrentFamily(familyId: number): void {
    //     this._currentFamily =  this._families.find(family => family.id === familyId);
    // }


    // public methods
    public addPlansToFamilies(familyId: number, plans: ProductModel[]): void {
        const family = this._families.find(f => f.id === familyId);
        if (family) {
            plans.sort(HelperFunctions.compareValues('weight', 'asc'));
            family.plans = plans;
        }
    }

    public getFamily(familyId: number): ProductFamilyModel {
       return this._families.find(f => f.id === familyId);
    }

    public emitFamiliesChanges(): void {
        this.familiesChange.next(this._families);
    }

    public getFamilyByTag(familyTag: string): ProductFamilyModel {
        return this._families.find(f => f.tag === familyTag);
    }


}
