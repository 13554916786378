<div class="modal-header modal-header-blue">
  <div class="nav-oficina-virtual">
    <div class="container">
      <div class="container titulo text-uppercase">
        <div class="row">
          <div class="col-1"><i class="fa fa-2x fa-clipboard-list"></i></div>
          <div class="col align-self-center">
            <span>{{ title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    type="button"
    class="close pull-right text-white"
    aria-label="Close"
    (click)="onClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
