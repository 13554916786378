import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base-component';
import { BomsService } from 'src/app/providers/products/boms.service';
import { takeUntil } from 'rxjs/operators';
import { BomModel } from 'src/app/models/product/bom.model';
import { LayoutService } from 'src/app/providers/layout.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent extends BaseComponent implements OnInit {
  showPromo = false;

  constructor(
    private bomsService: BomsService,
    private layoutService: LayoutService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.layoutService.showFooter = false;

    this.bomsService.emitHasPromo
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((bom: BomModel | null) => {
        if (bom) {
          this.showPromo = true;
        } else {
          this.showPromo = false;
        }
      });
  }
}
