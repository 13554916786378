<div class="agregar-container text-center">
    <div class="row inner-content justify-content-center">
        <!--<div class="col-auto existe-promo" *ngIf="showPromo">-->
            <!--<div class="col">-->
                <!--<i class="fa fa-circle"></i>-->
                <!--HAY DISPONIBLE UNA PROMO SIMILAR A TU COMBO POR <strong>{{promoPrice | currency:'es-AR':'$'}}</strong>-->
            <!--</div>-->
        <!--</div>-->
       <div class="col-auto text-right">
        <button (click)="onApply()" class="btn text-white bg-color-primary">{{buttonText}}</button>
       </div>
       <div class="col-auto text-left">
       <div class="precio-container">
          <div class="precio color-primary font-weight-bold">{{price | currency:'es-AR':'$'}}</div>
          <div>I.V.A INCLUÍDO</div>
        </div>
       </div>
    </div>


</div>
