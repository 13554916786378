import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';
import { Cacheable } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { GeneralResponse } from 'src/app/models/general-response.model';

import { ProductModel } from 'src/app/models/product/product.model';
import { FamiliesService } from './families.service';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class PlansService extends BaseService {
  constructor(
    public router: Router,
    public http: HttpClient,
    private familiesService: FamiliesService
  ) {
    super(router);
  }

  // @Cacheable({
  //   cacheBusterObserver: cacheBuster$
  // })
  getPlans(
    subsidiary: number,
    corporative: number = 0
  ): Observable<{} | GeneralResponse> {
    const url = `${
      this._api
    }client/subsidiaries/${subsidiary}/plans/${corporative}?` +
    `include=products.optionals,products.family,products.channels,products.optionals.channels,products.dailies`;
    return this.http.get<GeneralResponse>(url).pipe(
      map(res => {
        for (const family of res.data) {
          this.familiesService.addPlansToFamilies(
            family.id,
            ProductModel.createArray(family.products, new ProductModel())
          );
        }
        this.familiesService.emitFamiliesChanges();
        return new GeneralResponse('', false, false, null);
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  getPlan(familyId: number, planId: number): any {
    return this.familiesService
      .getFamily(familyId)
      .plans.find(plan => plan.id === planId);
  }
}
