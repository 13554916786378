import { Serializable } from '../core/models/serializable.model';

export class ImageModel extends Serializable {

  public file: string;
  public tag: string;
  public name: string;

  newModel(data: any): ImageModel {
    const dataParsed = new ImageModel(data);
    return dataParsed;
  }
}
