import { ProcedureProductModel } from './../../../models/procedure/procedure-product.model';
import { takeUntil } from 'rxjs/operators';
import { OttProductsComponent } from './../../virtual-office/panel/principal-ott/ott-products/ott-products.component';
import { ProductsOttService } from './../../../providers/ott/products-ott.service';
import { ClientService } from 'src/app/providers/auth/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductModel } from './../../../models/product/product.model';
import { Subject } from 'rxjs';
import { GeneralResponse } from './../../../models/general-response.model';
import { BaseComponent } from './../../../core/components/base-component';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-adquire-product-ott',
  templateUrl: './modal-adquire-product-ott.component.html',
  styleUrls: ['./modal-adquire-product-ott.component.scss']
})
export class ModalAdquireProductOttComponent extends BaseComponent implements OnInit {


  public onCloseModal = new Subject<GeneralResponse>();
  product: ProductModel;
  title: string;


  constructor(
    private bsModalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    private clientService: ClientService,
    private prodsOttService: ProductsOttService
  ) {
    super();
  }


  ngOnInit() {
    super.ngOnInit();
  }

  makeProcedure(): void {
    this.spinner.show();
    const procedure = {
      client_id: this.clientService.currentClient.id,
      client_connection_id: this.clientService.currentConection.id,
      products: [this.product.id],
      products_for_procedure:[{product_id: this.product.id, product_price: this.product.price}]
    }


    this.prodsOttService.buyProduct(procedure)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((response: GeneralResponse) => {
        this.onClose(response);
      }, (err) => {
        this.onClose(err);
      }
      );
  }

  onClose(response: GeneralResponse | null = null) {
    this.spinner.hide();
    this.onCloseModal.next(response);
    this.bsModalRef.hide();
  }



}
