import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './main/home/home.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { AuthInterceptor } from './core/auth/auth.interceptor';

import localeArg from '@angular/common/locales/es-AR';
import localeArgExtra from '@angular/common/locales/extra/es-AR';
import {registerLocaleData} from '@angular/common';
import { CartModule } from './main/cart/cart.module';

import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';


import { ProceduresModule } from './main/procedures/procedures.module';
import { ModalDownServiceComponent } from './procedures/modal-down-service/modal-down-service.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaqComponent } from './main/static-sections/faq/faq.component';
import { PaymentPlacesComponent } from './main/static-sections/payment-places/payment-places.component';
import { HelpfulTipsComponent } from './main/static-sections/helpful-tips/helpful-tips.component';
import { ContactCentersComponent } from './main/static-sections/contact-centers/contact-centers.component';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CompanyComponent } from './main/static-sections/company/company.component';
import { NowExpressComponent } from './main/static-sections/now-express/now-express.component';
import {FinderComponent} from './main/static-sections/finder/finder.component';
import { InitialBannerComponent } from './procedures/initial-banner/initial-banner.component';
import { MovistarComponent } from './main/static-sections/movistar/movistar.component';
import { AppMobileModule } from './main/app-mobile/app-mobile.module';
import {MovistarModule} from './main/movistar/movistar.module';
import {CollapseModule} from 'ngx-bootstrap/collapse';

registerLocaleData(localeArg, 'es-AR', localeArgExtra);

@NgModule({
    declarations: [
        AppComponent,
        ModalDownServiceComponent,
        FaqComponent,
        PaymentPlacesComponent,
        HelpfulTipsComponent,
        ContactCentersComponent,
        CompanyComponent,
        NowExpressComponent,
        FinderComponent,
        InitialBannerComponent,
        MovistarComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,

    HttpClientModule,

    // spinner
    NgxSpinnerModule,

    ModalModule.forRoot(),
    BrowserAnimationsModule,

    ToastrModule.forRoot(), // ToastrModule added

    LayoutModule,
    HomeModule,
    CartModule,
    ProceduresModule,
    AccordionModule.forRoot(),
    CollapseModule,
    AppMobileModule,
    MovistarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    BsModalRef
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    InitialBannerComponent
  ]
})
export class AppModule { }
