import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

import {environment} from '../../../environments/environment';
import { throwError} from 'rxjs/index';
import { GeneralResponse } from 'src/app/models/general-response.model';


@Injectable()
export class BaseService {

    public _api = environment.api;
    public _headers = new HttpHeaders();

    constructor(public router: Router) {
        this._headers.set('Accept', 'application/json')
            .append('Access-Control-Allow-Origin', '*')
            .append('Content-type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');
    }

    public handleError(error: HttpErrorResponse | any): any {
        const response = new GeneralResponse();
        response.data = [];
        response.error = true;

        if (error.error.data) {
            response.message = error.error.data.message;

            if (error.error.data.httpCode === 401) {
                this.router.navigate(['/cliente/ingreso']);
            }

            if (error.error.data.errors) {

                const arrayErrors = Object.keys(error.error.data.errors).map(i => error.error.data.errors[i]);
                for (const err of arrayErrors) {
                    response.data.push(err);
                }
            }
        }

        /**
         * errors: {email: ["correo electrónico ya ha sido registrado."]}
         email: ["correo electrónico ya ha sido registrado."]
         0: "correo electrónico ya ha sido registrado."
         e
         */

        if (response.message === '') {
            response.message = 'Ocurrió un problema con la comunicación con el servidor. Verfique su conexion y vuelva a intentarlo.';
        }
        return throwError(response);
    }

    getItemFromStorage(name: string): string {
      return localStorage.getItem(name);
    }
}
