import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export class BaseComponent implements OnInit, OnDestroy {

  public componentDestroyed = new Subject();

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  /**
   * check current windows width and evaluate.
   */
  isMobileScreen(width: number = 991): boolean {
    return (window.innerWidth <= width);
  }
}
