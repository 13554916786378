import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../../../providers/layout.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
   this.layoutService.showFooter = true;
  }

}
