import { Serializable } from '../../core/models/serializable.model';

export class KeyValueModel extends Serializable {

    public key: string;
    public value: string;

    newModel(data: any): KeyValueModel {
        return new KeyValueModel(data);
    }
}
