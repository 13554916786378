import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { BaseComponent } from "src/app/core/components/base-component";
import { FamiliesService } from "src/app/providers/products/families.service";
import { takeUntil } from "rxjs/operators";
import { ProductFamilyModel } from "src/app/models/product/product-family.model";
import { SubsidiaryService } from "../../../../providers/data/subsidiary.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header-menu-products",
  templateUrl: "./menu-products.component.html",
  styleUrls: ["./menu-products.component.scss"],
})
export class MenuProductsComponent extends BaseComponent implements OnInit {
  families: ProductFamilyModel[] = [];
  familyMovil: ProductFamilyModel;

  @Output() goToFamilyProducts: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  constructor(
    private familiesService: FamiliesService,
    private subsidiaryService: SubsidiaryService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.families = this.familiesService.families;
    // agrego la nueva familia solamente en esta pantalla
    this.setFamilyMovil();

    this.familiesService.familiesChange
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((families: ProductFamilyModel[]) => {
        this.families = families;
        this.setFamilyMovil();
      });
  }
  setFamilyMovil(): void {
    // si no es san lorenzo, agego movistar
    if (this.subsidiaryService.currentSubsidiary.tag !== "sl") {
      this.familyMovil = new ProductFamilyModel(
        null,
        "Telefonía móvil",
        "",
        "Telefonía móvil",
        "c-tel-mov"
      );
    }
  }

  getFamily(tag: string) {
    return this.families.find((f) => f.tag === tag);
  }

  onGoToFamilyProducts(familyId: number) {
    if (familyId < 1000) {
      this.goToFamilyProducts.emit([
        `carro-compras/productos/detalle/${familyId}`,
      ]);
    } else {
      this.goToNowHD();
    }
  }

  onGoToProductDetails(familyId: number, planId: number) {
    this.goToFamilyProducts.emit([
      `carro-compras/productos/detalle/${familyId}/${planId}`,
    ]);
  }

  goToMovistarPage(): void {
    this.router.navigate(["/movistar"]);
  }

  onGoToDevicesMovistar(): void {
    this.router.navigate(["/movistar-dispositivos"]);
  }

  goToNowHD(): void {
    this.router.navigate(["/oficina-virtual/panel/principal"]);
  }
}
