import { Serializable } from '../../core/models/serializable.model';

export class ProcedureKindModel extends Serializable {

    public id: number;
    public tag: string;
    public name: string;
    public description: string;
    public color: string;
    public loginRequired: boolean;

    newModel(data: any): ProcedureKindModel {
        return new ProcedureKindModel(data);
    }
}
