<div *ngIf="showTitle && leftFamilies" class="mt-3">Agregar:</div>
<div *ngFor="let f of familiesLeft()">
    <button (click)="goToFamilyProducts(f.id)" class="btn btn-carrito">{{f.name}}</button>
</div>
<div *ngIf="canShowMovistar">
  <button (click)="goToMovistar()" class="btn btn-carrito">
    <img src="assets/img/movistar-h.png" class="logo-movistar">
  </button>
  <button (click)="goToDevices()" class="btn btn-carrito">Dispositivos</button>
</div>
