import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProceduresService} from '../../../../providers/procedures/procedures.service';
import {ProceduresBaseComponent} from '../../../procedures/procedures-base-component';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProcedureModel} from '../../../../models/procedure/procedure.model';
import {ProcedureDataModel} from '../../../../models/procedure/procedure-data.model';
import {ProcedureKind} from '../../../../models/data/procedure-kind.enum';
import {DataService} from '../../../../providers/data/data.service';
import {SubsidiaryService} from '../../../../providers/data/subsidiary.service';
import {GeneralResponse} from '../../../../models/general-response.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-movistar-form',
  templateUrl: './movistar-form.component.html',
  styleUrls: ['./movistar-form.component.scss']
})
export class MovistarFormComponent extends ProceduresBaseComponent implements OnInit {

  form: FormGroup;

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private _formBuilder: FormBuilder,
    private dataService: DataService,
    private subsidiaryService: SubsidiaryService,
    private toastr: ToastrService,
  ) {
    super(bsModalRef, spinner, proceduresService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initForm();

    this.onCloseModal.subscribe((response: GeneralResponse) => {
      this.form.reset();
      if (!response.error) {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
    });
  }

  get isValidForm(): boolean {
    return this.form.valid && this.captchaValid;
  }

  initForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      isClient: ['', [Validators.required]]
    });
  }

  validInput(input: string): boolean {
    const control = this.form.get(input);
    if (control.dirty) {
      return !this.form.get(input).valid;
    } else {
      return false;
    }
  }

  makeProcedure(): ProcedureModel {
    const procedure = new ProcedureModel();

    procedure.initForNewProcedure();
    procedure.subsidiary = this.subsidiaryService.currentSubsidiary;
    procedure.clientDetails = '';
    procedure.contactPhone = this.form.value.phone;
    procedure.contactTime = '';

    procedure.procedureKind = this.dataService.procedureKind
      .find(pk => pk.tag === ProcedureKind.MOVISTAR_DEVICE_REQUEST);
    procedure.data = [];
    procedure.data.push(new ProcedureDataModel('nombre', this.form.value.name));
    procedure.data.push(new ProcedureDataModel('email', this.form.value.email));
    procedure.data.push(new ProcedureDataModel('es cliente', this.form.value.isClient ? 'Si' : 'No'));
    procedure.data.push(new ProcedureDataModel('telefono', this.form.value.phone));
    return procedure;
  }
}
