<section  class="py-5 container">
  <alert type="success p-4" *ngIf="resultOk">
    <h6 class="alert-heading text-center">
      <i class="fa fa-question-circle"></i>&nbsp;
      <span>El pago ha sido procesado satisfactoriamente</span>      
      <br/><br/>
      <p>
        Para verlo en la aplicación, por favor refresca la sección de pagos tirando hacia abajo la pantalla.
      </p>
    </h6>
  </alert>

  <alert type="warning" *ngIf="!resultOk">
    <h4 class="alert-heading text-center" >
      <i class="fa fa-exclamation-triangle"></i>&nbsp;
      <span>El pago no ha sido procesado. Por favor vuelva a intentarlo</span>
      <p><b>Detalle del problema: </b> {{response_description}}</p>
    </h4>
  </alert>

  <button class="btn btn-block btn-blue" (click)="printPdf()" >Imprimir comprobante</button>
</section>
