<div class="content-carrito seleccion-producto">
  <div class="text-center">
    <div *ngIf="!hasProducts">
      <!--<img src="assets/img/ic_combo_inicio.png" />-->
      <p>
        Podés armar el plan a tu medida. <br />
        Seleccioná los productos que te interesa agregar.
      </p>
    </div>
    <div *ngIf="hasProducts">
      <app-cart-selected-products
          [products]="cartProducts()"
          [isBom]="false"
          (quitProduct)="onQuitProduct($event)"
          (editProduct)="onEditProduct($event)">
        </app-cart-selected-products>
    </div>

    <div class="row justify-content-center promo-container mt-1">
      <div class="col-auto existe-promo" *ngIf="showPromo">
          <i class="fa fa-circle"></i>
          HAY DISPONIBLE UNA PROMO SIMILAR A TU COMBO POR <strong>{{promoPrice | currency:'es-AR':'$'}}</strong>
      </div>

      <div class="col-auto" *ngIf="hasProducts" >
        <button (click)="print()" class="btn bg-color-secondary text-white"><i class="fa fa-print" aria-hidden="true"></i></button>
      </div>
    </div>


    <app-cart-family-buttons [showTitle]="hasProducts" [families]="families">
    </app-cart-family-buttons>

    <div class="container" >
        <app-terms-conditions
        [terms]="termsAndConditions">
      </app-terms-conditions>
    </div>
  </div>
</div>

<app-cart-select-price-bar
  *ngIf="hasProducts"
  [buttonText]="'COMPRAR'"
  [price]="totalPrice"
  [promoSubscribe]="true"
  (apply)="onApply()"
></app-cart-select-price-bar>

