import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PdfService } from 'src/app/shared/services/pdf.service';
import { DirectPaymentService } from 'src/app/providers/direct-payment/direct-payment.service';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-direct-payment-ok',
  templateUrl: './direct-payment-ok.component.html',
  styleUrls: ['./direct-payment-ok.component.scss']
})
export class DirectPaymentOkComponent implements OnInit {
    
    resultOk: boolean;
  verification_code;
  response_code;
  response_description;
  date;
  fecha;
  hora;
  client_code;
  client_id;
  ammount;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private pdfService: PdfService,
    private directPaymenService : DirectPaymentService
  ) { }

  ngOnInit() {

    this.spinner.show();
    this.verification_code = this.route.snapshot.queryParamMap.get('PASSWORD');
    this.response_code = this.route.snapshot.queryParamMap.get('CODRTA');
    this.response_description = this.route.snapshot.queryParamMap.get('DESCRTA');
    this.date = new Date();
    this.fecha = this.date.getDate() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getFullYear();
    this.hora = this.date.getHours() + ':' + this.date.getMinutes() + ':' + this.date.getSeconds();
    
    this.client_code = localStorage.getItem('direct-payment-clientCode');
    this.client_id = localStorage.getItem('direct-payment-clientId');      
    this.ammount = localStorage.getItem('direct-payment-ammount');      
    // this.pdfService.printPaymentProof(verification_code, fecha, hora, client_code);
    // this.spinner.hide();

    if (this.response_code === '1174-00') {
        this.resultOk =  true;
    } else { 
        this.resultOk =  false;
    }

    this.directPaymenService.setAccountPaid(this.client_id, this.response_code, this.response_description, this.verification_code)
      .pipe(take(1))
      .subscribe(
        (response: GeneralResponse) => {
          // no quieren q se haga nada con esto asi q nada ...
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  printPdf(): void {
    this.pdfService.printPaymentProof(this.verification_code, this.fecha, this.hora, this.client_code, this.ammount);
  }

}
