import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ProductModel } from 'src/app/models/product/product.model';
import { ProductFamilyModel } from 'src/app/models/product/product-family.model';

@Component({
  selector: 'app-optionals',
  templateUrl: './optionals.component.html',
  styleUrls: ['./optionals.component.scss']
})
export class OptionalsComponent implements OnInit {

  @Input() optionals: ProductModel[];
  @Input() optionalsSelected: ProductModel[];
  @Input() family: ProductFamilyModel;
  @Output() changeSelectedOptional: EventEmitter<{checked: boolean, optional: ProductModel}> = new EventEmitter();
  isCollapsed = false;
  constructor() { }

  ngOnInit() {
  }

  isSelected(optionalId: number): boolean {
    return (this.optionalsSelected.find(os => os.id === optionalId) != null);
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  changeSelection($event, product): void {
      this.changeSelectedOptional.emit({ checked: $event.srcElement.checked, optional: product});
  }

  noEditableOptional(optional: ProductModel): boolean {
    return (optional.price === 0);
  }
}
