<div class="container">
  <div class="row w-100">
    <div
      *ngFor="let family of families"
      class="c-tv"
      [ngClass]="[
        'sub-menu mb-xl-0 mb-4',
        family.colorCssClass,
        familyMovil ? 'col-sm-3' : 'col-sm-4'
      ]"
    >
      <h3>{{ family.name }}</h3>

      <ul class="list-unstyled" *ngIf="family.id && !family.isNow">
        <h5 class="menu-subtitle" *ngIf="family.isTelefono">Fija</h5>
        <li *ngFor="let plan of family.plans">
          <a
            class="menu-item pl-0"
            href="javascript:void(0)"
            (click)="onGoToProductDetails(plan.family.id, plan.id)"
          >
            <div class="nombre">{{ plan.name }}</div>
            <div class="info">{{ plan.description }}</div>
          </a>
        </li>

        <div *ngIf="family.isTelefono">
          <h5 class="menu-subtitle">Móvil</h5>
          <a
            class="menu-item pl-0"
            href="javascript:void(0)"
            (click)="goToMovistarPage()"
          >
            <div class="nombre">Planes</div>
          </a>
          <a
            class="menu-item pl-0"
            href="javascript:void(0)"
            (click)="onGoToDevicesMovistar()"
          >
            <div class="nombre">Dispositivos</div>
          </a>
        </div>
      </ul>

      <ul class="list-unstyled" *ngIf="family.isNow">
        <li>
          <a
            class="menu-item pl-0"
            href="javascript:void(0)"
            (click)="goToNowHD()"
          >
            <div class="nombre">NOW Express HD</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="row mt-3 w-100">
    <div
      *ngFor="let family of families"
      [ngClass]="[
        'sub-menu mb-xl-0 mb-4 c-tv',
        family.colorCssClass,
        familyMovil ? 'col-sm-3' : 'col-sm-4'
      ]"
    >
      <a
        (click)="onGoToFamilyProducts(family.id)"
        href="javascript:void(0);"
        class="btn cursor-pointer"
        >VER</a
      >
    </div>
  </div>
</div>
