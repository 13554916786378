<div class="lista-productos">
    <div class="titulos-tabla">
      <div class="row">
        <div class="col-12">
          <div class="float-left">PRODUCTO</div>
          <div class="float-right">COSTO MENSUAL</div>
        </div>
      </div>
    </div>
    <ul class="px-0 my-0">
        <app-cart-selected-product-detail
          *ngFor="let product of products"
          [product]="product"
          [isBom]="isBom"
          (quitProduct)="onQuitProduct($event)"
          (editProduct)="onEditProduct($event)">
        </app-cart-selected-product-detail>
<!--      <div *ngIf="isBom" class="movistar-container">-->
<!--        <input type="checkbox" id="movistar" name="movistar" >-->
<!--        <label for="movistar" class="font-size-1rem">-->
<!--          <span class="movistar">Sumá tu plan <img id="movistar-logo" src="assets/img/movistar-cyan-small.png"> Movistar</span>-->
<!--        </label>-->
<!--        <p><small>*Nos pondremos en contacto para informarte sobre las promociones preferenciales</small></p>-->
<!--      </div>-->
    </ul>
  </div>
