<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container py-4 mb-5">
    <div class="row">
      <div class="col-lg-6">
        <div class="titulo color-primary" *ngIf="hasPlans">Planes seleccionados</div>

        <div *ngFor="let plan of plans">
          <div class="row align-self-center">
            <span class="col-sm-3 color-primary  font-weight-bold">Plan:</span>
            <div class="col-sm-9 ">
              <span>{{ plan.name }}</span>
            </div>
          </div>

          <div class="row align-self-center" *ngIf="plan.hasOptionals">
            <span class="col-sm-3 color-primary  font-weight-bold">Adicionales:</span>
            <div class="col-sm-9 ">
              <span>{{ optionalsText(plan) }}</span>
            </div>
          </div>

          <hr/>

          <app-modal-recaptcha
                  (confirmCaptcha)="toggleCaptcha($event)"
          ></app-modal-recaptcha>
        </div>

          <div class="titulo color-primary mt-2" *ngIf="hasOptionals">Servicios seleccionados</div>

        <div *ngFor="let optional of optionals">
            <div class="row align-self-center">
              <span class="col-sm-3 color-primary  font-weight-bold">Servicio:</span>
              <div class="col-sm-9 ">
                <span>{{ optional.optional.name }} - {{ optional.plan.name }}</span>
              </div>
            </div>
            <hr/>
          </div>

      </div>

        <div class="col-lg-6 margin-top-mobile-1">
          <app-modal-contact-date [(comment)]="comment" [(timeFrom)]="timeFrom" [(timeUntil)]="timeUntil" [(phone)]="phone"></app-modal-contact-date>

        </div>

    </div>

  </div>

  <div class="modal-footer">

    <button [disabled]="!isValidForm" href="javascript:void(0)"
    class="btn btn-block text-white bg-color-primary d-block"
      (click)="onConfirm()">
      CONFIRMAR BAJA
    </button>

  </div>
