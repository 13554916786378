import { Component, OnInit } from '@angular/core';
import {MovistarPlanModel} from './movistar-plan.model';
import {BaseComponent} from '../../../core/components/base-component';
import {ModalContactMovilComponent} from '../../procedures/modal-contact-movil/modal-contact-movil.component';
import {environment} from '../../../../environments/environment';
import {take} from 'rxjs/operators';
import {GeneralResponse} from '../../../models/general-response.model';
import {ModalHelperFunctions} from '../../../helpers/modal-functions';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';

@Component({
  selector: 'app-movistar',
  templateUrl: './movistar.component.html',
  styleUrls: ['./movistar.component.scss']
})
export class MovistarComponent extends BaseComponent implements OnInit {

  planes: MovistarPlanModel[];
  isMobile = false;
  isCollapsed = false;
  termsConditions: string;

  constructor(private bsModalRef: BsModalRef,
              private modalService: BsModalService,
              private subsidiaryService: SubsidiaryService) {
    super();
  }

  ngOnInit() {
    this.isMobile = this.isMobileScreen(500);
    this.setConditions();
    this.planes = this.getAllPlans();
    this.planes.forEach(x => x.open = !this.isMobileScreen(500));
  }

  toggleOpen(plan: MovistarPlanModel): void {
    plan.open = !plan.open;
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  setConditions(): void {
    this.termsConditions = 'Promoción válida del 01/05/21 hasta el 17/05/21 para personas humanas que contraten de manera conjunta cualquiera de los servicios de internet provistos por EXPRESS y, a su vez, realicen la portabilidad numérica a Movistar y/o contraten cualquiera de los planes de telefonía móvil pospago de la oferta vigente de Movistar a través de EXPRESS. El descuento del 30% se aplicará sobre el precio de lista total y mensual del plan de telefonía móvil contratado, vigente al momento de la contratación, durante doce (12) meses corridos contados a partir de la activación. Adicionalmente al descuento mencionado anteriormente, se aplicará un descuento de $100 sobra la factura Movistar. A partir del mes trece (13) el cliente deberá abonar el precio de lista total y mensual vigente en ese momento para cada uno de los productos, según corresponda. La promoción se encontrará vigente siempre y cuando el cliente mantenga contratados todos los servicios de EXPRESS y Movistar incluidos. Para más información de los servicios ingresar en https://www.express.com.ar/movistar. Promoción no renovable ni combinable con otras promociones. La permanencia en la promoción está sujeta al pago en términos de las facturas. Condiciones generales de los servicios en www.express.com.ar';
  }

  getAllPlans(): MovistarPlanModel[] {
    const plans = [];
    const plan1 = new MovistarPlanModel('20 GIGAS', '30% OFF X 12 MESES');
    plan1.price = { number: '3.332', decimal: ''};
    plan1.listPrice = { number: '4.760', decimal: ''};
    plan1.includes = [
      {name: 'Roaming en el mundo', description: '', hasTitle: true},
      {name: 'Familia Movistar', description: 'más líneas, más descuentos', hasTitle: true},
      {name: null, description: 'Guardá tus gigas gratis para usarlos el mes que viene.<br><br>Pasá y pedí gigas gratis a cualquier Movistar.<br><br>Hablá libre a celulares y teléfonos fijos de cualquier compañía.<br><br>Enviá SMS libres a móviles.<br><br> WhatsApp GRATIS + 30 GB gratis.', hasTitle: false},
    ];
    plan1.terms = 'Precio para nuevas líneas y cambio de plan. Incluye 30% de descuento por 12 meses. Precio de Lista del plan: $4760.';
    plans.push(plan1);

    const plan2 = new MovistarPlanModel('15 GIGAS', '30% OFF X 12 MESES');
    plan2.price = { number: '2.786', decimal: ''};
    plan2.listPrice = { number: '3.980', decimal: ''};
    plan2.includes = [
      {name: 'Familia Movistar', description: 'más líneas, más descuentos', hasTitle: true},
      {name: null, description: 'Guardá tus gigas gratis para usarlos el mes que viene.' +
          '<br><br>Pasá y pedí gigas gratis a cualquier Movistar.' +
          '<br><br>Hablá libre a celulares y teléfonos fijos de cualquier compañía.' +
          '<br><br>Enviá SMS libres a móviles.' +
          '<br><br>Whatsapp GRATIS + 30 GB gratis.', hasTitle: false},
    ];
    plan2.terms = 'Precio para nuevas líneas y cambio de plan. Incluye 30% de descuento por 12 meses. Precio de Lista del plan: $3980.';
    plans.push(plan2);

    const plan3 = new MovistarPlanModel('8 GIGAS', '30% OFF X 12 MESES');
    plan3.price = { number: '2.184', decimal: ''};
    plan3.listPrice = { number: '3.120', decimal: ''};
    plan3.includes = [
      {name: 'Familia Movistar', description: 'más líneas, más descuentos', hasTitle: true},
      {name: null, description: 'Guardá tus gigas gratis para usarlos el mes que viene.' +
          '<br><br>Pasá y pedí gigas gratis a cualquier Movistar.' +
          '<br><br>Hablá libre a celulares y teléfonos fijos de cualquier compañía.' +
          '<br><br>Enviá SMS libres a móviles.' +
          '<br><br>Whatsapp GRATIS + 30 GB gratis.', hasTitle: false},
    ];
    plan3.terms = 'Precio para nuevas líneas y cambio de plan. Incluye 30% de descuento por 12 meses. Precio de Lista del plan: $3120.';
    plans.push(plan3);

    const plan4 = new MovistarPlanModel('5 GIGAS', '30% OFF X 12 MESES');
    plan4.price = { number: '1.491', decimal: ''};
    plan4.listPrice = { number: '2.130', decimal: ''};
    plan4.includes = [
      {name: null, description: 'Guardá tus gigas gratis para usarlos el mes que viene.' +
          '<br><br>Pasá y pedí gigas gratis a cualquier Movistar.' +
          '<br><br>Hablá libre a celulares y teléfonos fijos de cualquier compañía.' +
          '<br><br>Enviá SMS libres a móviles.' +
          '<br><br>Whatsapp GRATIS + 30 GB gratis.', hasTitle: false},
    ];
    plan4.terms = 'Precio para nuevas líneas y cambio de plan. Incluye 30% de descuento por 12 meses. Precio de Lista del plan: $2130.';
    plans.push(plan4);

    const plan5 = new MovistarPlanModel('3 GIGAS', '30% OFF X 12 MESES');
    plan5.price = { number: '1.092', decimal: ''};
    plan5.listPrice = { number: '1.560', decimal: ''};
    plan5.includes = [
      {name: null, description: 'Guardá tus gigas gratis para usarlos el mes que viene.' +
          '<br><br>Pasá y pedí gigas gratis a cualquier Movistar.' +
          '<br><br>Hablá libre a celulares y teléfonos fijos de cualquier compañía.' +
          '<br><br>Enviá SMS libres a móviles.' +
          '<br><br>Whatsapp GRATIS + 30 GB gratis.', hasTitle: false},
    ];
    plan5.terms = 'Precio para nuevas líneas y cambio de plan. Incluye 30% de descuento por 12 meses. Precio de Lista del plan: $1560.';
    plans.push(plan5);

    const plan6 = new MovistarPlanModel('2 GIGAS', '30% OFF X 12 MESES');
    plan6.price = { number: '711', decimal: ''};
    plan6.listPrice = { number: '1.015', decimal: ''};
    plan6.includes = [
      {name: null, description: 'Guardá tus gigas gratis para usarlos el mes que viene.' +
          '<br><br>Pasá y pedí gigas gratis a cualquier Movistar.' +
          '<br><br>Hablá libre a celulares y teléfonos fijos de cualquier compañía.' +
          '<br><br>Enviá SMS libres a móviles.' +
          '<br><br>Whatsapp GRATIS + 30 GB gratis.', hasTitle: false},
    ];
    plan6.terms = 'Precio para nuevas líneas y cambio de plan. Incluye 30% de descuento por 12 meses. Precio de Lista del plan: $1015.';
    plans.push(plan6);

    return plans;
  }

  openModal(plan: MovistarPlanModel): void {
    const initialState = {
      title: 'REGISTRATE',
      planName: plan.name
    };
    this.bsModalRef = this.modalService.show(ModalContactMovilComponent, {
      ...environment.modalConfig,
      initialState
    });
    this.bsModalRef.setClass('modal-xl');

    // m subscribo a la respuesta del modal
    this.bsModalRef.content.onCloseModal
      .pipe(take(1))
      .subscribe((response: GeneralResponse) => {
        if (response) {
          const result = ModalHelperFunctions.showModalResult(
            response.error
              ? 'Solicitud de contacto no enviada'
              : 'Tu solicitud fue enviada',
            response.message,
            response.error,
            this.modalService
          );
          // m subscribo a la respuesta del modal result
          result.content.onCloseModal.pipe(take(1)).subscribe(() => {});
        }
      });
  }

}
