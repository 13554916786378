import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalProcedureResultComponent } from '../main/procedures/modal-procedure-result/modal-procedure-result.component';

// function for dynamic sorting
export class ModalHelperFunctions {
  /**
   * Abre el modal de resultado
   * @param title titulo del modal
   * @param message mensaje
   * @param error mostrar error?
   * @param modalService servicio del modal
   */
  static showModalResult(
    title: string, message: string, error: boolean,
    modalService: BsModalService
    ): BsModalRef {
    const initialState = {
      title: title,
      message: message,
      error: error
    };

    // setTimeout(() => {
      return  modalService.show(
        ModalProcedureResultComponent,
        { initialState }
      );
    // }, 100);
  }
}
