<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="oficina-virtual formulario">
  <div name="form" [formGroup]="form">
    <div class="container py-4">
      <div class="row">
        <h4 class="color-primary col-12"><strong>Ingresá aquí tu consulta</strong></h4>
        <div class="col-lg-6 col-md-12">

          <div class="titulo color-primary">Datos personales</div>
          <!--nombre o razon social-->
          <div class="form-group row ">
            <label
              class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Nombre y apellido o razón social
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                type="text"
                class="form-control form-control-sm "
                formControlName="name"
                [ngClass]="{ 'is-invalid': validInput('name') }"
                placeholder="Ingresá nombre o razón social..."
              />
            </div>
          </div>

          <div class="form-group row">
            <label

              class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Email
              <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="email"
                class="form-control form-control-sm"
                placeholder="Ingresá email..."
                formControlName="email"
                [ngClass]="{ 'is-invalid': validInput('email') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Teléfono
              <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="tel"
                class="form-control form-control-sm"
                placeholder="(000) 000 0000"
                mask="(000) 000 0000"
                formControlName="phone"
                [ngClass]="{ 'is-invalid': validInput('phone') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label  class="col-sm-3 col-form-label col-form-label-sm color-primary">
              Documento <span class="required">*</span>
            </label>
            <div class="col-sm-9 align-self-center">
              <div class="row">
                <div class="col-4 pr-sm-0">
                  <select formControlName="documentType" class="form-control form-control-sm">
                    <option *ngFor="let dt of documentTypesArray" [value]="dt.id">{{dt.name}}</option>
                  </select>
                </div>
                <div class="col-8 pl-sm-0">
                  <input type="number" formControlName="documentNumber" class="form-control form-control-sm"
                         placeholder="Número..."  [ngClass]="{ 'is-invalid': validInput('documentNumber') }">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="p-4"></div>
          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Calle<span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Calle..."
                formControlName="street"
                [ngClass]="{ 'is-invalid': validInput('street') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Altura <span class="required">*</span> | Piso | Depto
            </label>
            <div class="col-sm-3 input-in-mobile">
              <input
                type="tel"
                class="form-control form-control-sm"
                placeholder="Altura..."
                formControlName="streetNumber"
                [ngClass]="{ 'is-invalid': validInput('streetNumber') }"
              />
            </div>
            <div class="col-sm-3 input-in-mobile">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Piso..."
                formControlName="streetFloor"
                [ngClass]="{ 'is-invalid': validInput('streetFloor') }"
              />
            </div>
            <div class="col-sm-3 input-in-mobile">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Depto..."
                formControlName="streetDept"
                [ngClass]="{ 'is-invalid': validInput('streetDept') }"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label col-form-label-sm color-primary">
              Sucursal<span class="required">*</span>
            </label>
            <div class="col-sm-9">
              <select formControlName="subsidiary" class="form-control form-control-sm">
                <option *ngFor="let s of subsidiariesArray" [value]="s.id">{{s.name}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label  class="col-sm-3 col-form-label col-form-label-sm color-primary align-self-center">
              Número de cliente
            </label>
            <div class="col-sm-9">
              <input type="number" placeholder="Ingreśa tu número de cliente"
                     class="form-control" formControlName="clientNumber"/>
            </div>
          </div>

          <!-- Comentario -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label col-form-label-sm color-primary">
              Consulta</label>
            <div class="col-sm-9">
                    <textarea type="text" rows="4" class="form-control form-control-sm"
                              placeholder="Algo que nos quieras decir..."
                              formControlName="comment"></textarea>
            </div>
          </div>

        </div>

      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="alert alert-danger" role="alert">
            <i class="fa fa-exclamation-triangle"></i> Los campos marcados con (*) son obligatorios.
          </div>
        </div>
        <div class="col-lg-6 col-md-12 text-right">
          <app-modal-recaptcha
            (confirmCaptcha)="toggleCaptcha($event)"
          ></app-modal-recaptcha>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button
        [disabled]="!isValidForm"
        href="javascript:void(0)"
        class="btn btn-block text-white bg-color-primary d-block"
        (click)="onConfirm()"
      >
        CONTACTAR
      </button>
    </div>

  </div>
</div>
