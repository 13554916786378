import { Injectable } from '@angular/core';
import { HomeBankingModel, PaymentCenterModel, SubsidiaryModel } from 'src/app/models/data/subsidiary.model';
import { localStorageKeys } from '../../shared/constants';
import { Subject } from 'rxjs';
import { SubsidiaryAddressModel } from '../../models/data/subsidiary-address.model';

@Injectable({ providedIn: 'root' })
export class SubsidiaryService {

  changeSubsidiary = new Subject<SubsidiaryModel>();
  changeCorporative = new Subject<number>();

  constructor() {
    this._subsidiaries = [
      new SubsidiaryModel(1, 'cbh', 'Rosario'),
      new SubsidiaryModel(2, 'dtv', 'Salta', -24.721862, -65.424344),
      new SubsidiaryModel(3, 'san', 'Santiago', -27.568930, -64.251613),
      new SubsidiaryModel(5, 'sl', 'San Lorenzo'),
    ];
    this._subsidiaries[1].polygon = [
      [-23.279695, -66.944070],
      [-23.539951, -64.268365],
      [-25.877272, -66.689385],
      [-25.986248, -64.547984]
    ];
    this._subsidiaries[2].polygon = [
      [-26.119624, -66.714814],
      [-25.621338, -61.438977],
      [-29.800647, -65.296446],
      [-30.056905, -62.165187]
    ];
    this._subsidiaries[3].polygon = [
      [-32.71957592, -60.79067007],
      [-32.76000525, -60.78655019],
      [-32.76202623, -60.72063223],
      [-32.72102014, -60.72166219]
    ];
    this.initSubsidiariesMaps();
    this.initSubsidiaryInfo();

    // get subsidiary from storage if exists or ROSARIO
    let tag = 'cbh';
    if (localStorage.getItem(localStorageKeys.SUBSIDIARY)) {
      tag = localStorage.getItem(localStorageKeys.SUBSIDIARY);
      this.setSubsidiaryByTag(tag);
    } else {
      this.setSubsidiaryByTag(tag);
      // evalua localización y setea la sucursal
      this.setSubsidiaryByLocation();
    }




    // get corporative from storage if exists or FALSE
    this._corporative = 0;
    if (localStorage.getItem(localStorageKeys.CORPORATIVE)) {
      this._corporative = +localStorage.getItem(localStorageKeys.CORPORATIVE);
    }
  }

  // gets and sets
  private _currentSubsidiary: SubsidiaryModel;
  get currentSubsidiary(): SubsidiaryModel {
    return this._currentSubsidiary;
  }

  set currentSubsidiary(subsidiary: SubsidiaryModel) {
    this._currentSubsidiary = subsidiary;
    localStorage.setItem(localStorageKeys.SUBSIDIARY, subsidiary.tag);
    // fire the new subsidiary
    this.changeSubsidiary.next(this._currentSubsidiary);
  }

  private _subsidiaries: SubsidiaryModel[];
  get subsidiaries(): SubsidiaryModel[] {
    return this._subsidiaries;
  }

  private _corporative: number;
  get corporative(): number {
    return this._corporative;
  }

  set corporative(corporative: number) {
    this._corporative = corporative;
    localStorage.setItem(localStorageKeys.CORPORATIVE, String(corporative));
    this.changeCorporative.next(this._corporative);
  }

  // private methods
  private setSubsidiaryByTag(tag: string): void {
    const sub = this._subsidiaries.find(s => s.tag === tag);
    this.currentSubsidiary = sub ? sub : this._subsidiaries[0];
  }

  private initSubsidiariesMaps(): void {
    // rosario
    this._subsidiaries.forEach((s) => {
      switch (s.tag) {
        case 'cbh':
          s.addresses.push(
            new SubsidiaryAddressModel(
              1,
              'CASA CENTRAL',
              'Ovidio Lagos 502',
              'Lunes a Viernes de 9 a 17hs',
              '-32.938675',
              '-60.660778',
              'mapa-casa-central'
            )
          );
          s.addresses.push(
            new SubsidiaryAddressModel(
              2,
              'SUCURSAL CENTRO',
              'Sarmiento 624',
              'Lunes a Viernes de 9 a 17hs',
              '-32.944299',
              '-60.637829',
              'mapa-centro'
            )
          );
          break;
        case 'sl':
          s.addresses.push(
            new SubsidiaryAddressModel(
              1,
              'CASA CENTRAL',
              'Catamarca 809',
              'Lunes a Viernes de 9 a 17hs',
              '-32.75460859',
              '-60.73652297',
              'mapa-casa-central'
            )
          );
          break;
        case 'dtv':
          s.addresses.push(
            new SubsidiaryAddressModel(
              1,
              'CASA CENTRAL',
              'España 111',
              'Lunes a viernes de 8 a 14 y de 14.30 a 20.30hs',
              '-24.789069',
              '-65.404441',
              'mapa-casa-central'
            )
          );
          s.addresses.push(
            new SubsidiaryAddressModel(
              2,
              'PORTAL SALTA SHOPPING - JUMBO',
              '20 de Febrero 1437 - Local 1007',
              'Lunes a sábados de 09:00 a 21:00 hs',
              '-24.772082',
              '-65.412681',
              'mapa-salta-shopping'
            )
          );
          s.addresses.push(
            new SubsidiaryAddressModel(
              3,
              'PASEO SALTA SHOPPING - LIBERTAD',
              'Av. Tavella y Av. Ex Combatientes de Malvinas – Local 470',
              'Lunes a sábados de 09:00 a 21:00 hs y domingos de 10:00 a 16:00 hs',              
              '-24.830259',
              '-65.428463',
              'mapa-paseo-salta'
            )
          );
          break;
        case 'san':
          s.addresses.push(
            new SubsidiaryAddressModel(
              1,
              'CASA CENTRAL',
              'Av Belgrano Sur 623 – Santiago del Estero',
              'Lunes a viernes de 8 a 13 y de 17.30 a 20.30hs.',
              '-27.789855',
              '-64.259664',
              'mapa-casa-central'
            )
          );
          s.addresses.push(
            new SubsidiaryAddressModel(
              2,
              'CHANGOMAS SUR',
              'Colectora Circunvalación y Av. Contreras – Santiago del Estero',
              'Lunes a viernes de 9 a 21hs.',
              '-27.841623',
              '-64.262016',
              'mapa-changomas'
            )
          );
          s.addresses.push(
            new SubsidiaryAddressModel(
              3,
              'HIPER LIBERTAD',
              'Autopista J. D. Perón SN – La Banda',
              'Lunes a viernes de 9 a 21hs.',
              '-27.765328',
              '-64.247538',
              'mapa-hiper'
            )
          );
          s.addresses.push(
            new SubsidiaryAddressModel(
              4,
              'LA BANDA',
              'Alberdi 231 – La Banda ',
              'Lunes a viernes de 8.30 a 13.00 y de 17:30 a 20.30hs.',
              '-27.732285',
              '-64.245120',
              'mapa-la-banda'
            )
          );

          break;
      }
    });

  }

  private initSubsidiaryInfo(): void {
    this._subsidiaries.forEach((s) => {
      switch (s.tag) {
        case 'cbh':
          s.info.venta = 'Lunes a viernes de 8 a 20hs.';
          s.info.reclamos = 'Lunes a sábados de 8 a 22. Domingos y feriados de 10 a 22hs.';
          s.payment.hb.push(HomeBankingModel.link, HomeBankingModel.credito, HomeBankingModel.banelco);
          s.payment.th.push('tarjeta_visa.png', 'tarjeta_naranja-express.png', 'tarjeta_master.png', 'tarjeta_cabal.png', 'tarjeta_american-express.png');
          s.payment.cp = this.getCPRosario();
          break;
        case 'sl':
          s.info.venta = 'Lunes a viernes de 8 a 20hs.';
          s.info.reclamos = 'Lunes a sábados de 8 a 22. Domingos y feriados de 10 a 22hs.';
          s.payment.hb.push(HomeBankingModel.link, HomeBankingModel.credito, HomeBankingModel.banelco);
          s.payment.th.push('tarjeta_visa.png', 'tarjeta_naranja-express.png', 'tarjeta_master.png', 'tarjeta_cabal.png', 'tarjeta_american-express.png');
          s.payment.cp = this.getCPSanLorenzo();
          break;
        case 'dtv':
          s.info.venta = 'Lunes a viernes de 8 a 20hs.';
          s.info.reclamos = 'Lunes a sábados de 8 a 22. Domingos y feriados de 10 a 22hs.';
          s.payment.hb.push(HomeBankingModel.link, HomeBankingModel.credito, HomeBankingModel.banelco);
          s.payment.th.push('tarjeta_visa.png', 'tarjeta_naranja-express.png', 'tarjeta_master.png', 'su-credito.png');
          s.payment.cp = this.getCPSalta();

          break;
        case 'san':
          s.info.venta = 'Lunes a viernes de 8 a 20hs.';
          s.info.reclamos = 'Lunes a sábados de 8 a 22. Domingos y feriados de 10 a 22hs.';
          s.payment.hb.push(HomeBankingModel.link, HomeBankingModel.credito, HomeBankingModel.banelco);
          s.payment.th.push('unica.png', 'tarjeta_visa.png', 'tarjeta_naranja-express.png', 'tarjeta_master.png', 'tarjeta_cabal.png', 'su-credito.png');
          s.payment.cp = this.getCPSantiago();
          break;

      }
    });
  }

  private setSubsidiaryByLocation(): void {
    let tag;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        if (this.latLongInsidePolygon([lat, long], this.subsidiaries[1].polygon)) {
          tag = 'dtv';
        } else if (this.latLongInsidePolygon([lat, long], this.subsidiaries[2].polygon)) {
          tag = 'san';
        } else if (this.latLongInsidePolygon([lat, long], this.subsidiaries[3].polygon)) {
          tag = 'sl';
        } else {
          tag = 'cbh';
        }
        this.setSubsidiaryByTag(tag);

      }, () => {
        this.setSubsidiaryByTag('cbh');
      }
      );
    }
  }

  private latLongInsidePolygon(point: number[], polygon): boolean {
    const x = point[0];
    const y = point[1];

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0];
      const yi = polygon[i][1];
      const xj = polygon[j][0];
      const yj = polygon[j][1];

      const intersect = ((yi > y) !== (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) {
        inside = !inside;
        break;
      }
    }
    return inside;
  }

  private getCPRosario(): PaymentCenterModel[] {
    const cps = [];
    cps.push(new PaymentCenterModel('entidad_santafe.png', 'https://www.pluspagos.com/'));
    cps.push(new PaymentCenterModel('entidad_rapipago.png', 'https://www.rapipago.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_pagofacil.png', 'https://www.e-pagofacil.com/'));
    //cps.push(new PaymentCenterModel('entidad_cobroexpress.png', 'https://www.cobroexpress.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_municipal.png', 'https://www.bmros.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_galicia.png', 'https://www.bancogalicia.com/'));
    cps.push(new PaymentCenterModel('entidad_bica.png', 'https://www.bancobica.com.ar/'));
    return cps;
  }

  private getCPSanLorenzo(): PaymentCenterModel[] {
    const cps = [];
    cps.push(new PaymentCenterModel('entidad_santafe.png', 'https://www.pluspagos.com/'));
    cps.push(new PaymentCenterModel('entidad_rapipago.png', 'https://www.rapipago.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_pagofacil.png', 'https://www.e-pagofacil.com/'));
    //cps.push(new PaymentCenterModel('entidad_cobroexpress.png', 'https://www.cobroexpress.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_municipal.png', 'https://www.bmros.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_galicia.png', 'https://www.bancogalicia.com/'));
    cps.push(new PaymentCenterModel('entidad_bica.png', 'https://www.bancobica.com.ar/'));
    return cps;
  }

  private getCPSalta(): PaymentCenterModel[] {
    const cps = [];
    cps.push(new PaymentCenterModel('entidad_rapipago.png', 'https://www.rapipago.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_pagofacil.png', 'https://www.e-pagofacil.com/'));
    cps.push(new PaymentCenterModel('CPA.png', 'http://www.cajapopular.gov.ar/'));
    //cps.push(new PaymentCenterModel('entidad_cobroexpress.png', 'https://www.cobroexpress.com.ar/'));
    return cps;
  }

  private getCPSantiago(): PaymentCenterModel[] {
    const cps = [];
    cps.push(new PaymentCenterModel('Sol-pagos.png', 'https://www.bse.com.ar/maps/solpagos'));
    cps.push(new PaymentCenterModel('entidad_rapipago.png', 'https://www.rapipago.com.ar/'));
    cps.push(new PaymentCenterModel('entidad_pagofacil.png', 'https://www.e-pagofacil.com/'));
    //cps.push(new PaymentCenterModel('entidad_cobroexpress.png', 'https://www.cobroexpress.com.ar/'));
    cps.push(new PaymentCenterModel('Caja-municipal.png', 'https://cajamunicipalsde.com.ar/'));
    cps.push(new PaymentCenterModel('bco-de-santiago-del-estero.png', 'https://www.bse.com.ar/'));
    return cps;
  }
}
