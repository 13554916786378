<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container pt-4 pb-2">
    <div class="row">
      <div class="col-12">
        <div class="titulo color-primary">Pack a adquirir</div>

        <div class="row align-self-center">
          <span class="col-sm-3 color-primary  font-weight-bold"
          >Producto:</span
          >
          <div class="col-sm-9 ">
            <span>{{ product.name }}</span>
          </div>
        </div>

        <div class="row align-self-center">
          <span class="col-sm-3 color-primary  font-weight-bold">Precio:</span>
          <div class="col-sm-9 ">
            <span>{{ product.price | currency: 'es-AR':'$' }}</span>
          </div>
        </div>

        <div class="row align-self-center" *ngIf="plan">
          <span class="col-sm-3 color-primary  font-weight-bold">Plan:</span>
          <div class="col-sm-9 ">
            <span>{{ plan.name }}</span>
          </div>
        </div>

        <div class="carrito mt-3">
          <div class="lista-productos">
            <p>Seleccioná los días de activación.</p>
            <div class="row no-gutters mt-1">
              <div class="vo-adicionales mb-3" >
                <div class="col-12 my-1"
                *ngFor="let d of daysToSelect">
                  <input
                    [id]="d.id"
                    type="checkbox"
                    [(ngModel)]="d.selected"
                    [checked]="d.selected"
                    [disabled]="!d.available"
                    (change)="checkAvailability()"/>
                  <label [for]="d.id" class="font-size-1rem ml-2">
                    <span>{{d.value | date: 'EEEE dd MMMM' | capitalize}}</span>
                  </label>
                </div>
              </div>
              <alert type="danger" *ngIf="selectedDays.length === 0" >
                <small class="form-text">
                  <i class="fa fa-exclamation-triangle"></i> Debés seleccionar al menos un día
                </small>
              </alert>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<div class="modal-footer">

  <button
    [disabled]="!isValidForm"
    href="javascript:void(0)"
    class="btn btn-block text-white bg-color-primary d-block"
    (click)="makeProcedure()"
  >
    CONFIRMAR COMPRA
  </button>

</div>

