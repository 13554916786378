<div class="titulo color-primary">Datos de contacto</div>

<alert type="info" class="">
  <small class="form-text">
    <i class="fa fa-info-circle"></i> Opcional - por si tenemos que hacerte una consulta
  </small>
</alert>

<div class="form-group row">
  <label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm color-primary">Teléfono</label>
  <div class="col-sm-9">
    <input (keyup)="onChangePhone()" type="tel" class="form-control form-control-sm" placeholder="(000) 000 0000" mask="(000) 000 0000"
      [(ngModel)]="phone" />
  </div>
</div>

<div class="form-group row">
  <label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm color-primary align-self-center">Horario
    contacto</label>
  <div class="col-sm-4 align-self-center">
    <input (keyup)="onChangeTimeFrom()" type="tel" placeholder="hh:mm" class="form-control" mask="Hh:m0" [(ngModel)]="timeFrom" />
  </div>
  <div class="col-sm-1 align-self-center"></div>
  <div class="col-sm-4 input-in-mobile align-self-center">
    <input (keyup)="onChangeTimeUntil()" type="tel" [(ngModel)]="timeUntil" placeholder="hh:mm" class="form-control"
      mask="Hh:m0" />
  </div>
</div>

<div class="form-group row">
  <label for="colFormLabelSm" class="col-sm-3 col-form-label col-form-label-sm color-primary">Comentario</label>
  <div class="col-sm-9">
    <textarea (keyup)="onChangeComment()" type="text" rows="4" class="form-control form-control-sm" placeholder="Algo que nos quieras decir...."
      [(ngModel)]="comment"></textarea>
  </div>
</div>
