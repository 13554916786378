

    <div class="nav-carrito nav-carrito-in-tab cursor-pointer" (click)="onGoBack()">
        <div class="container titulo container-back-to-buy">
            <a class="cursor-pointer" ><i class="fa fa-arrow-left"></i></a>
            <span class="text-uppercase">{{title}}</span>
        </div>
    </div>

    <div class="lista-productos content-carrito py-4">
        <div class="titulos-tabla">
            <div class="row">
                <div class="col-12">
                    <div class="float-left">PRODUCTO</div>
                </div>
            </div>

        </div>
        <ul class="px-0">
            <li *ngFor="let plan of plans">
                <div class="row cursor-pointer w-100" (click)="onSelectPlan(plan)" >
                    <div class="col-9">
                        <div class="row no-gutters">
                            <div class="col-auto">
                                <img [src]="plan.iconUrl" class="img-fluid" [alt]="plan.name">
                            </div>
                            <div class="col align-self-center text-left">
                                <div class="card-block px-2">
                                    <div class="nombre-plan text-uppercase">{{plan.name}}</div>
                                    <div class="descripcion-plan">{{plan.description}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 align-self-center text-right">
                        <!--<a class="btn btn-carrito float-right ver">VER</a>-->
                        <div class="nombre-plan text-uppercase">{{plan.price | currency:'es-AR':'$'}}</div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
