<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="default"
  color="#fff"
  fullScreen="false"
  type="ball-pulse-sync">
 <p style="font-size: 20px; color: white">Cargando...</p>

</ngx-spinner>

<body class="home">
  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-footer *ngIf="showFooter"></app-footer>
</body>
