<div class="row">
  <div  class="col-12 text-center">
    <div *ngIf="loading">
      <i class="fa fa-spinner fa-spin"></i>&nbsp;<small>Cargando Captcha...</small>
    </div>
    <div *ngIf="captchaError">
      <alert type="danger" class="">
        <small class="form-text text-muted">
          <i class="fa fa-info-circle"></i>&nbsp;No se pudo cargar el captcha
          <button class="btn btn-sm btn-blue" (click)="resetCaptcha()">recargar</button>
        </small>
      </alert>
    </div>
    <ngx-recaptcha2 #captchaElem
                    [(ngModel)]="captcha"
                    [siteKey]="siteKey"
                    (reset)="handleReset()"
                    (expire)="handleExpire()"
                    (error)="handleError()"
                    (load)="handleLoad()"
                    (success)="handleSuccess($event)"
                    [useGlobalDomain]="false"
                    [size]="'normal'"
                    [hl]="'es-419'"
                    [theme]="'light'">
    </ngx-recaptcha2>
  </div>
</div>