import { Injectable, Renderer2 } from '@angular/core';
import { ProductModel } from '../../models/product/product.model';
import { TermCondition } from '../../models/product/term-condition.model';
import { environment } from '../../../environments/environment';
import { Observable, Observer } from 'rxjs';
import { CartBomModel } from '../../models/cart/cart-bom.model';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from '../../../../PdfMake/vfs_fonts.js';
import { ClientModel } from '../../models/client/client.model';
import { CouponModel } from '../../models/data/coupon.model';
import * as JsBarcode from 'jsbarcode';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
  }
};


@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() {
    pdfMake.tableLayouts = {
      productsTableLayout: {
        hLineWidth: function (i, node) {
          return (i === node.table.headerRows || i === node.table.body.length - 1) ? 1 : 0;
        },
        vLineWidth: function () {
          return 0;
        },
        hLineColor: function () {
          return '#666666';
        },
        paddingTop: function (i) {
          return (i === 0) ? 0 : 10;
        },
        paddingBottom: function (i, node) {
          return (i === 0) ? 5 : 10;
        },
      },
      headerLayout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        paddingTop: function () {
          return 10;
        },
        paddingBottom: function () {
          return 10;
        },
      },
      totalAmountLayout: {
        hLineWidth: function (i) {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
      },
    };
  }

  printProductsResume(products: ProductModel[], termsAndConditions: TermCondition[], total: number): void {
    const name = 'Express- Armá tu combo.pdf';

    const pdfContent = this.createProductsPdfContent(products, termsAndConditions, total);

    pdfMake.createPdf(pdfContent).print();

  }

  printBomResume(cartBom: CartBomModel, showMovistarPlan, showTvNow): void {
    const name = cartBom.bom.name + '.pdf';

    const pdfContent = this.createBomPdfContent(cartBom, showMovistarPlan, showTvNow);

    pdfMake.createPdf(pdfContent).print();

  }

  printPaymentCoupon(client: ClientModel, coupon: CouponModel): void {
    const name = 'Express- cupon de pago.pdf';

    const pdfContent = this.createCouponPdf(client, coupon);

    pdfMake.createPdf(pdfContent).open();

  }

  printPaymentProof(verifCode, fecha, hora, clientCode, ammount): void {
    const name = 'Express- comprobante de pago.pdf';
    const pdfContent = this.createPaymentProof(verifCode, fecha, hora, clientCode, ammount);
    pdfMake.createPdf(pdfContent).open();

  }

  // crea comprobante de pago
  private createPaymentProof(verifCode, fecha, hora, clientCode, ammount): any {
    // const payment = localStorage.getItem('payment-totem');
    const content = {
      header: {
        margin: [40, 40, 40, 40],
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'COMPROBANTE DE PAGO CON TARJETA DE CRÉDITO',
                alignment: 'center',
                fontSize: 14,
              }
            ],
            [
              {
                image: 'logo.png',
                fit: [200, 400],
                margin: [0, 20, 0, 0],
                alignment: 'center',
              }
            ],
            [
              {
                text: '',
                border: [false, false, false, true]
              }
            ]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      },
      content: [
        {
          table: {
            widths: ['*', '*', '*'],
            heights: ['auto', 30, 'auto', 30, 'auto', 'auto'],
            body: [
              [
                { text: 'FECHA', alignment: 'left', style: 'header' },
                { text: 'HORA', alignment: 'center', style: 'header' },
                { text: 'NÚMERO DE CLIENTE', alignment: 'right', style: 'header' }
              ],

              [
                { text: fecha, alignment: 'left', style: 'content' },
                { text: hora, alignment: 'center', style: 'content' },
                { text: clientCode, alignment: 'right', style: 'content' }
              ],

              [
                { text: 'CÓDIGO DE CONFIRMACIÓN', colSpan: 3, alignment: 'left', style: 'header' },
                {},
                {}
              ],
              [
                { text: verifCode, colSpan: 3, alignment: 'left', style: 'content' },
                {},
                {}
              ],

              [
                { text: 'IMPORTE', colSpan: 3, alignment: 'left', style: 'header' },
                {},
                {}
              ],
              [
                { text: '$' + ammount, colSpan: 3, alignment: 'left', style: 'content' },
                {},
                {}
              ],
            ],
          },
          layout: {
            defaultBorder: false,
          }
        },
      ],

      pageSize: 'A4',
      pageMargins: [40, 150, 40, 40],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          decoration: 'underline'
        },
        content: {
          fontSize: 12,
        },
      }
    };
    return content;
  }

  // cupón de pago
  private createCouponPdf(client: ClientModel, coupon: CouponModel): any {
    const subsInfo = this.getSubsidiaryInfo(client);
    const subsFooter = this.getSubsidiaryFooter(client);
    const content = {
      header: {
        margin: [40, 20, 40, 40],
        columns: [
          {
            image: 'logo_ext.png',
            fit: [200, 400],
            margin: [0, 20, 0, 0]
          },
          {
            ul: subsInfo,
            alignment: 'right'
          },
        ]
      },
      content: [
        {
          table: {
            headerRows: 1,
            widths: [150, '*'],
            body: [
              [{ text: 'DATOS DE LA OPERACIÓN', style: 'couponTableHeader', colSpan: 2, alignment: 'center', fontSize: 10 }, {}],
              [
                { text: 'Cliente', fontSize: 10 },
                { text: client.code + ' ' + client.fullName, fontSize: 10 }
              ],
              [
                { text: 'Monto', fontSize: 10 },
                { text: coupon.montoPesos, fontSize: 10 }
              ],
              [
                { text: 'Moneda', fontSize: 10 },
                { text: 'Pesos', fontSize: 10 }
              ],
              [
                { text: 'Fecha Emisión', fontSize: 10 },
                { text: coupon.fechaEmision, fontSize: 10 }
              ],
              [
                { text: 'Fecha Vencimiento', fontSize: 10 },
                { text: coupon.fechaVencimiento, fontSize: 10 }
              ],
              [
                { text: 'Código de barras', fontSize: 10 },
                { text: coupon.barcode, fontSize: 10 }
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 1 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 1 : 0;
            },
          }
        },
        { image: this.getBarcode(coupon.barcode), fit: [400, 500], alignment: 'center', margin: [0, 100, 0, 20] },
        { image: subsFooter, width: 450, alignment: 'center', margin: [0, 40, 0, 0] },
        {
          style: 'footerText',
          table: {
            widths: ['*'],
            body: [
              ['Para informar pagos comunicarse al 0810-555-3977 - opción 5']]
          },
          layout: 'noBorders'
        }
      ],

      styles: {
        couponTableHeader: {
          fillColor: '#c9e7ed',
        },
        footerText: {
          fillColor: '#cccccc',
          color: '#000000',
          fontSize: 9,
          alignment: 'center'
        },
      },
      pageSize: 'A4',
      pageMargins: [40, 150, 40, 40],
    };
    return content;
  }

  // presupuesto productos
  private createProductsPdfContent(products: ProductModel[], termsAndConditions: TermCondition[], total: number): any {
    products.forEach((p) => {
      if (!p.base64Icon) {
        this.getBase64ImageFromURL(environment.iconsUrl + p.icon).subscribe(
          (resp) => {
            p.base64Icon = resp;
          }
        );
      }
    });

    const tableBody = this.createTable(products, total);

    let termsConditions;
    if (termsAndConditions) {
      termsConditions = this.createTermsAndConditions(termsAndConditions);
    }

    const content = {
      header: this.createHeader(),
      content: [
        {
          table: {
            widths: ['*'],
            body: [[
              { text: 'LISTADO DE PRODUCTOS', alignment: 'center' }
            ]
            ]
          },
          style: 'headerTable',
          layout: 'headerLayout'
        },
        {
          style: 'tableProducts',
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: (tableBody) ? tableBody : []
          },
          layout: 'productsTableLayout'
        },

        termsConditions
      ],
      styles: this.getStyles(),
      pageSize: 'A4',
      pageMargins: [40, 80, 40, '*'],

    };

    return content;
  }

  // presupuesto BOM
  private createBomPdfContent(cartBom: CartBomModel, showMovistar = false, showTvNow = false): any {
    cartBom.products.forEach((p) => {
      if (!p.base64Icon) {
        this.getBase64ImageFromURL(environment.iconsUrl + p.icon).subscribe(
          (resp) => {
            p.base64Icon = resp;
          }
        );
      }
    });
    const tableBody = this.createTable(cartBom.products, cartBom.price, true, showMovistar);

    let movistar;
    if (showMovistar) {
      movistar = this.createMovistarSection();
    }
    let tvNow;
    if (showTvNow) {
      tvNow = this.createTvNowSection();
    }
    let termsAndCond;
    if (cartBom.bom.termsAndConditions) {
      termsAndCond = this.createTermsAndConditions(cartBom.termsAndConditions);
    }
    const content = {
      header: this.createHeader(),
      content: [
        {
          table: {
            widths: ['*'],
            body: [
              [
                { text: cartBom.bom.name, alignment: 'center' },
              ]
            ],
          },
          style: 'headerTable',
          layout: 'headerLayout'
        },
        {
          style: 'tableProducts',
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: (tableBody) ? tableBody : []
          },
          layout: 'productsTableLayout'
        },
        tvNow,
        movistar,
        termsAndCond
      ],
      styles: this.getStyles(),
      pageSize: 'A4',
      pageMargins: [40, 80, 40, 70],

    };

    return content;
  }

  // crea la tabla de productos con precios para el presupuesto
  private createTable(products: ProductModel[], total: number, isBom: boolean = false, showMovistar = false): any[] {
    const rows = [];
    let hasOptionals = false;
    // header
    rows.push([{ text: 'PRODUCTO', style: 'tableHeader' }, { text: 'COSTO MENSUAL', style: 'tableHeader' }]);

    // rows
    for (const p of products) {
      let price;
      if (isBom) {
        price = { text: '$ 0,00', decoration: 'lineThrough', decorationColor: 'red', margin: [0, 15, 0, 0], style: 'price' };
      } else {
        price = { text: '$ ' + p.price.toLocaleString('es-AR', { minimumFractionDigits: 2 }), style: 'price', margin: [0, 15, 0, 0] };
      }

      rows.push(
        [
          {
            table: {
              body: [
                [
                  { image: p.base64Icon, width: 30 },
                  {
                    table: {
                      body: [
                        [p.name],
                        [{ text: p.description, style: 'description' }]
                      ]
                    },
                    layout: 'noBorders'
                  }],
              ]
            },
            // margin: [0, 10, 0, 0],
            layout: 'noBorders'
          },
          price
        ]
      );
      if (p.optionals.length > 0) {
        hasOptionals = true;
        for (const opt of p.optionals) {
          rows.push(
            [
              {
                table: {
                  widths: [30, 'auto'],
                  body: [
                    [
                      '',
                      { text: '+ ' + opt.name, style: 'description' }],
                  ]
                },
                layout: 'noBorders'
              },
              { text: '$ ' + opt.price.toLocaleString('es-AR', { minimumFractionDigits: 2 }), style: 'price' }
            ]
          );
        }
      }
    }
    let totalText = 'Total ';
    if (isBom) {
      totalText += 'Promo ';
      if (hasOptionals) {
        totalText += '+ Opcionales';
      }
    }
    rows.push(
      [
        { text: totalText, alignment: 'right', color: '#1971ba' },
        { text: '$ ' + total.toLocaleString('es-AR', { minimumFractionDigits: 2 }), color: '#1971ba', bold: true, style: 'price' },
      ]);

    return rows;
  }

  private createMovistarSection() {
    // const rows = [
    //   {
    //     image: 'movistar',
    //     fit: [60, 100],
    //     margin: [0, 0, 0, 0],
    //     alignment: 'left',
    //   },
    //   {text: 'Sumá tu plan Movistar', style: 'movistar'}
    // ];
    const rows = {
      ul: [
        { text: 'Solicitaste además información de Movistar', style: 'movistar' }
      ]
    };


    return rows;

  }

  private createTvNowSection() {
    const rows = {
      ul: [
        { text: 'Solicitaste además sumar TV HD + NOW', style: 'tvNow' }
      ]
    };
    return rows;
  }


  // crea la sección de términos y condiciones para los presupuestos
  private createTermsAndConditions(termsArray: TermCondition[]): any {
    let termsAndCond;
    const terms = [];
    terms.push({ text: 'CONDICIONES COMERCIALES: ', style: 'conditionsHeader' });
    for (const t of termsArray) {
      terms.push({ text: t.product + ' ', bold: true, style: 'conditionsBody' });
      terms.push({ text: t.text + '. ', style: 'conditionsBody' });
    }
    termsAndCond = [
      {
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 512, y2: 0, lineWidth: 1, color: '#004f9e' }],
        alignment: 'center',
        margin: [0, 25, 0, 0]

      },
      {
        text: terms,
        alignment: 'center',
        margin: [0, 10, 0, 0]

      }];
    return termsAndCond;

  }

  // crea la caabecera de los pdf
  private createHeader(): any {
    const fecha = new Date().toLocaleString('es-AR');
    return {
      margin: [40, 40, 40, 40],
      columns: [
        {
          image: 'logo_ext.png',
          fit: [200, 500],
        },
        { text: '\nFecha: ' + fecha, style: 'date' },
      ]

    };
  }

  private getStyles(): any {
    return {
      date: {
        alignment: 'right',
      },
      tableProducts: {
        margin: [0, 15, 0, 15]
      },
      tableHeader: {
        bold: false,
        fontSize: 11,
        color: '#666666'
      },
      description: {
        fontSize: 10,
        color: '#666666'
      },
      conditionsHeader: {
        fontSize: 9,
        color: '#004f9e',
      },
      conditionsBody: {
        fontSize: 9,
        color: '#666666'
      },
      headerTable: {
        fontSize: 14,
        bold: true,
        fillColor: '#dddddd',
        color: '#1971ba'
      },
      totalAmountTable: {
        fontSize: 12,
        bold: true,
        color: '#1971ba',
        margin: [0, 0, 0, 25],
      },
      price: {
        alignment: 'right'
      },
      movistar: {
        color: '#28aae1',
        bold: true
      },
      tvNow : {
        color: '#004f9e',
        bold: true
      }
    };
  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  // obtiene el código de barras para el cupón de pago
  getBarcode(code) {
    const canvas1 = document.createElement('canvas');
    JsBarcode(canvas1, code, { displayValue: false, format: 'ITF' });
    return canvas1.toDataURL('image/png');
  }

  // para cupón de pago
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img = new Image();
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getSubsidiaryInfo(client: ClientModel): any[] {
    switch (client.subsidiary.tag) {
      // rosario
      case 'cbh':
        return [
          { text: 'Teledifusora S.A', listType: 'none', bold: true },
          { text: 'Av. Ovidio Lagos 502 | Sarmiento 624', listType: 'none', bold: false, fontSize: 9 },
          { text: 'S2000LNS - Rosario | Santa Fe', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Tel: 0810-555-3977 | Fax: 472-4880', listType: 'none', bold: false, fontSize: 9 },
          { text: 'C.U.I.T N° 30-69368185-1', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Ingresos brutos N° 30-69368185-1', listType: 'none', bold: false, fontSize: 9 },
        ];
        break;

      // SAN LORENZO
      case 'sl':
        return [
          { text: 'Teledifusora S.A', listType: 'none', bold: true },
          { text: 'Av. Ovidio Lagos 502 | Sarmiento 624', listType: 'none', bold: false, fontSize: 9 },
          { text: 'S2000LNS - Rosario | Santa Fe', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Tel: 0810-555-3977 | Fax: 472-4880', listType: 'none', bold: false, fontSize: 9 },
          { text: 'C.U.I.T N° 30-69368185-1', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Ingresos brutos N° 30-69368185-1', listType: 'none', bold: false, fontSize: 9 },
        ];
        break;

      // santiago
      case 'san':
        return [
          { text: 'MEGAVISION SANTIAGO S.A.', listType: 'none', bold: true },
          { text: 'Av. Belgrano S 623 - 4200 Capital Santiago del Estero', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Tel 428-4888', listType: 'none', bold: false, fontSize: 9 },
          { text: 'www.express.com.ar', listType: 'none', bold: false, fontSize: 9 },
          { text: 'C.U.I.T.: 30-69977031-7', listType: 'none', bold: false, fontSize: 9 },
          { text: 'ING.B.C.M.: 9227706184', listType: 'none', bold: false, fontSize: 9 },
        ];
        break;
      // salta
      case 'dtv':
        return [
          { text: 'DECOTEVE S.A', listType: 'none', bold: true },
          { text: 'Los Bardos 665 - Salta', listType: 'none', bold: false, fontSize: 9 },
          { text: 'CUIT 30-63779893-2', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Act. Económicas 917-620729-9', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Act. Varias 44389', listType: 'none', bold: false, fontSize: 9 },
          { text: 'Inicio de Actividades 22/07/1992', listType: 'none', bold: false, fontSize: 9 },
        ];
        break;
    }
  }

  getSubsidiaryFooter(client: ClientModel): string {
    switch (client.subsidiary.tag) {
      // rosario
      case 'cbh':
        return 'centros_pago_rosario.png';
        break;
      // santiago
      case 'san':
        return 'centros_pago_santiago.png';
        break;
      // salta
      case 'dtv':
        return 'centros_pago_salta.png';
        break;
    }
  }
}
