import { Component, OnInit } from '@angular/core';
import { ProceduresBaseComponent } from '../procedures-base-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';
import { ClientService } from 'src/app/providers/auth/client.service';
import { DataService } from 'src/app/providers/data/data.service';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { DeviceModel } from 'src/app/models/product/device.model';
import { ProcedureDataModel } from 'src/app/models/procedure/procedure-data.model';
import { ProcedureKind } from 'src/app/models/data/procedure-kind.enum';

@Component({
  selector: 'app-modal-wifi-changes',
  templateUrl: './modal-wifi-changes.component.html',
  styleUrls: ['./modal-wifi-changes.component.scss']
})
export class ModalWifiChangesComponent extends ProceduresBaseComponent
  implements OnInit {
  devices: DeviceModel[];
  devicesSelected: DeviceModel[] = [];

  wifiChanges = [
    {tag: ProcedureKind.WIFI_CHANGE_NAME, text: 'Nombre'},
    {tag: ProcedureKind.WIFI_CHANGE_PASSWORD, text: 'Contraseña'},
    {tag: ProcedureKind.WIFI_CHANGE_CHANNEL, text: 'Canal'},
  ];

  title: string;

  wifiName = '';
  wifiPass = '';
  wifiPassConfirm = '';
  wifiChannel = '';

  settingToChange: string;

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private clientsService: ClientService,
    private dataService: DataService
  ) {
    super(bsModalRef, spinner, proceduresService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initForm();
  }

  initForm() {}

  validInput(): boolean {
    switch (this.settingToChange) {
      case 'WIFI_CHANGE_PASSWORD':
        if (this.wifiPass === '' || this.wifiPassConfirm === '') {
          return true;
        }

        if (this.wifiPass !== this.wifiPassConfirm) {
          return true;
        }

        return false;

      case 'WIFI_CHANGE_NAME':
        if (this.wifiName === '') {
          return true;
        }

        return false;

      case 'WIFI_CHANGE_CHANNEL':
        if (this.wifiChannel === '') {
          return true;
        }

        return false;

      default:
        return true;
    }
  }

  get sectedAtLeastOneDevice(): boolean {
    return (this.devicesSelected.length > 0);
  }

  get isValidForm(): boolean {
    return !this.validInput() && this.sectedAtLeastOneDevice && this.captchaValid;
  }

  /** checkea si la conf a cambiar es la elegida */
  checkSettingToChange(setting: string): boolean {
    return setting === this.settingToChange;
  }

  selectDevice($event, device: DeviceModel): void {
    if ($event.srcElement.checked) {
      this.devicesSelected.push(device);
    } else {
      this.devicesSelected.splice(this.devicesSelected.indexOf(device), 1);
    }
  }

  makeProcedure(): ProcedureModel {
    const procedure = new ProcedureModel();

    procedure.initForNewProcedure();

    procedure.client = this.clientsService.currentClient;

    procedure.subsidiary = this.clientsService.currentClient.subsidiary;
    procedure.clientDetails = null;
    procedure.contactTime = null;
    procedure.contactPhone = null;
    procedure.procedureKind = this.dataService.procedureKind.find(
      pk => pk.tag === this.settingToChange
    );

    procedure.data = [];

    switch (this.settingToChange) {
      case 'WIFI_CHANGE_PASSWORD':
        procedure.data.push(new ProcedureDataModel('password', this.wifiPass));
        break;
      case 'WIFI_CHANGE_NAME':
        procedure.data.push(new ProcedureDataModel('nombre', this.wifiName));
        break;
      case 'WIFI_CHANGE_CHANNEL':
        procedure.data.push(new ProcedureDataModel('canal', this.wifiChannel));
        break;
    }

    procedure.devices = this.devicesSelected;

    return procedure;
  }

  get passswordMatch(): boolean {
    return this.wifiPass == this.wifiPassConfirm
  }
}
