<section id="content" class="section-with-header movistar pb-5">
  <div class="section-header p-4">
    <div class="text-center">
      <h5 class="title">Ahora con Express sumá tu plan</h5>
      <img src="assets/img/movistar-h.png" class="logo">
    </div>
  </div>

  <div class="container section-content my-4">
    <div class="row">
      <div class="col-lg-2 col-md-4 col-sm-6 col-xs-3" *ngFor="let plan of planes">
        <div class="card">
          <div class="card-header">{{plan.name}}</div>
          <div class="card-subtitle">{{plan.desc}}</div>
          <div class="card-content" [ngClass]="isMobile ? 'm-3' : 'm-2'">
            <div class="align-top">
              <div class="price-content">
                <span class="signo-peso-bigger">$</span>
                <span>
                  <p class="precio">{{plan.price.number}}</p>
                  <span class="centavos">{{plan.price.decimal}}</span>
                </span>
              </div>
              <div class="description">Por mes</div>
              <div class="price-content">
                <span class="signo-peso">$</span>
                <span>
              <p class="precio-anterior">{{plan.listPrice.number}}</p>
            </span>
              </div>
              <div class="description">Precio de lista</div>

              <div class="text-center align-items-center mt-4">
                <div class="div-h-line mb-2" *ngIf="isMobile">
                  <button type="button" class="btn green btn-sm" (click)="toggleOpen(plan)" >
                    <div class="btn-text-small">INCLUYE <i [class]="plan.open ? 'fa fa-caret-down' : 'fa fa-caret-right'"></i></div>
                  </button>
                </div>
                <div class="div-h-line" *ngIf="!isMobile">
                  <p class="text-v-line mb-1">INCLUYE</p>
                </div>
                <div *ngIf="plan.open">
                  <div *ngFor="let item of plan.includes">
                    <div *ngIf="item.hasTitle">
                      <p class="titulo-green">{{item.name}}</p>
                      <p class="description">{{item.description}}</p>
                    </div>
                    <div *ngIf="!item.hasTitle" class="mt-2">
                      <p class="description left" [innerHTML]="item.description"></p>
                    </div>
                    <div class="div-h-line" *ngIf="item.hasTitle">
                      <p class="text-v-line mb-1">+</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div class="align-bottom align-self-end">
              <button type="button" class="btn blue btn-block" (click)="openModal(plan)">Lo quiero</button>
            </div>
          </div>
        </div>
        <p class="terms-plan text-break-all">{{plan.terms}}</p>
      </div>
    </div>

    <div class="row mt-2 border-blue">
      <div class="condiciones text-justify text-break-all mt-2">
        <a class="btn font-weight-bold color-primary btn-terms cursor-pointer"
           aria-expanded="false"
           aria-controls="collapseTerms"
           (click)="toggleCollapse()">
          <i class="fa" [ngClass]="{'fa-caret-right': isCollapsed, 'fa-caret-up':!isCollapsed}"></i>
          CONDICIONES COMERCIALES
        </a>
        <div id="collapseTerms" [collapse]="isCollapsed">
          <p>
            {{termsConditions}}&nbsp;
          </p>
        </div>
      </div>
    </div>
  </div>

</section>
