import {Component, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LayoutService} from '../../../providers/layout.service';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';
import {SubsidiaryModel} from '../../../models/data/subsidiary.model';

@Component({
  selector: 'app-contact-centers',
  templateUrl: './contact-centers.component.html',
  styleUrls: ['./contact-centers.component.scss']
})
export class ContactCentersComponent implements OnInit {

  subsidiary: SubsidiaryModel;
  constructor(private router: Router,
              private elementRef: ElementRef,
              private layoutService: LayoutService,
              private subsidiaryService: SubsidiaryService) { }

  ngOnInit() {
      this.subsidiary = this.subsidiaryService.currentSubsidiary;
      this.layoutService.showFooter = true;
      // init maps
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB18-t6zisiHtQq1z3yZK5r_Vbz7R5noi8&callback=initMap';

      this.elementRef.nativeElement.appendChild(s);
      const mapsContent = this.initMaps();
      this.elementRef.nativeElement.appendChild(mapsContent);
  }

  goToVirtualOffice(): void {
      this.router.navigate(['/oficina-virtual/panel']);
  }

  initMaps(): any {
      const maps = document.createElement('script');
      maps.type = 'text/javascript';

      let content = '';
      for (let a of this.subsidiary.addresses) {
          content +=
          'var pin' + a.id + '= {lat: ' + a.lat + ', lng: ' + a.long + '};' +
              ' var center' + a.id + ' = pin' + a.id + '; ' +
              'setMap("' + a.tag + '", center' + a.id + ', pin' + a.id + ');';
      }

      maps.innerHTML = '  function initMap() {' + content  + '}' +
          '      function setMap(divId, center, pin) {\n' +
          '          var map = new google.maps.Map(document.getElementById(divId), {\n' +
          '              zoom: 16,\n' +
          '              center: center,\n' +
          '              scrollwheel: false,\n' +
          '              mapTypeControl: false\n' +
          '          });\n' +
          '\n' +
          '          var styles = [\n' +
          '              {\n' +
          '                  featureType: "poi",\n' +
          '                  stylers: [\n' +
          '                      { visibility: "off" }\n' +
          '                  ]\n' +
          '              }\n' +
          '          ];\n' +
          '          map.setOptions({styles: styles});\n' +
          '\n' +
          '          var marker = new google.maps.Marker({\n' +
          '              position: pin,\n' +
          '              map: map\n' +
          '          });\n' +
          '      }';

      return maps;
  }

  mapAddress(center): string {
      return 'http://maps.google.com/?q=' + center.lat + ',' + center.long;
  }

}
