import { Component, OnInit } from '@angular/core';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConnectionModel} from '../../../models/client/connection.model';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {ClientService} from '../../../providers/auth/client.service';
import {DataService} from '../../../providers/data/data.service';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {HelperFunctions} from '../../../helpers/functions';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';
import {AuthService} from '../../../providers/auth/auth.service';
import {CartService} from '../../../providers/cart/cart.service';
import {CartBomService} from '../../../providers/cart/cart-bom.service';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';
import {DataModel} from '../../../models/data/data.model';
import {SubsidiaryModel} from '../../../models/data/subsidiary.model';

@Component({
  selector: 'app-modal-home-generic-request',
  templateUrl: './modal-home-generic-request.component.html',
  styleUrls: ['./modal-home-generic-request.component.scss']
})
export class ModalHomeGenericRequestComponent extends ProceduresBaseComponent implements OnInit {

  form: FormGroup;
  title: string;

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private dataService: DataService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private clientsService: ClientService,
    private cartService: CartService,
    private cartBomService: CartBomService,
    private subsidiaryService: SubsidiaryService,) {
    super(bsModalRef, spinner, proceduresService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initForm();
  }

  initForm() {
    this.form = this._formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      documentType: ['', Validators.required],
      documentNumber: ['', Validators.required],
      street: ['', [Validators.required]],
      streetNumber: ['', [Validators.required]],
      streetFloor: [''],
      streetDept: [''],
      comment: [''],
      subsidiary: [null, Validators.required],
      clientNumber: ['']
    });
  }


  validInput(input: string): boolean {
    const control = this.form.get(input);
    if (control.dirty) {
      return !this.form.get(input).valid;
    } else {
      return false;
    }
  }

  get isValidForm(): boolean {
    return this.form.valid && this.captchaValid;
  }

  compare(x, y): boolean {
    return x && y ? x.id === y.id : x === y;
  }

  makeProcedure(): ProcedureModel {
    const procedure = new ProcedureModel();

    procedure.initForNewProcedure();
    procedure.subsidiary = this.subsidiariesArray.find(x => x.id === +this.form.value.subsidiary);
    procedure.clientDetails = '';
    procedure.contactPhone = this.form.value.phone;
    procedure.contactTime = '';

    procedure.procedureKind = this.dataService.procedureKind
      .find(pk => pk.tag === ProcedureKind.HOME_GENERIC_REQUEST);

    procedure.data = [];
    if (this.form.value.clientNumber !== '') {
      procedure.data.push(new ProcedureDataModel('número de cliente', this.form.value.clientNumber.toString()));
    }
    procedure.data.push(new ProcedureDataModel('nombre', this.form.value.name));
    procedure.data.push(new ProcedureDataModel('email', this.form.value.email));
    procedure.data.push(new ProcedureDataModel('documento', this.form.value.documentType + ' ' + this.form.value.documentNumber));
    procedure.data.push(new ProcedureDataModel('telefono', this.form.value.phone));
    procedure.data.push(new ProcedureDataModel('calle', this.form.value.street));
    procedure.data.push(new ProcedureDataModel('número', this.form.value.streetNumber));
    if (this.form.value.streetFloor !== '') {
      procedure.data.push(new ProcedureDataModel('piso', this.form.value.streetFloor));
    }
    if (this.form.value.streetDept !== '') {
      procedure.data.push(new ProcedureDataModel('depto', this.form.value.streetDept));
    }
    if (this.form.value.comment !== '') {
      procedure.data.push(new ProcedureDataModel('comentario', this.form.value.comment));
    }
    return procedure;
  }

  get documentTypesArray(): DataModel[] {
    return this.dataService.documents;
  }

  get subsidiariesArray(): SubsidiaryModel[] {
    return this.subsidiaryService.subsidiaries;
  }
}
