import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductModel } from 'src/app/models/product/product.model';

@Component({
  selector: 'app-cart-selected-product-detail',
  templateUrl: './selected-product-detail.component.html',
  styleUrls: ['./selected-product-detail.component.scss']
})
export class SelectedProductDetailComponent implements OnInit {

  @Input() product: ProductModel;
  @Input() isBom: boolean;
  @Output() quitProduct: EventEmitter<ProductModel> = new EventEmitter<ProductModel>();
  @Output() editProduct: EventEmitter<ProductModel> = new EventEmitter<ProductModel>();

  constructor() { }

  ngOnInit() {
  }

  onQuitProduct() {
    this.quitProduct.emit(this.product);
  }

  onEditProduct() {
    this.editProduct.emit(this.product);
  }

  underlineOptional(optional: ProductModel): boolean {
    return (this.isBom && optional.price === 0);
  }

}
