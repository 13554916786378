import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../../core/services/base.service';
import { GeneralResponse } from '../../models/general-response.model';
import { BomModel } from '../../models/product/bom.model';

import { ProductModel } from 'src/app/models/product/product.model';
import { HelperFunctions } from 'src/app/helpers/functions';

const cacheBuster$ = new Subject<void>();

@Injectable({
    providedIn: 'root'
})
export class BomsService extends BaseService {

    /**
     * emite cambios en la promo si va null es porque no hay promo
     */
    emitHasPromo = new Subject<BomModel | null>();

    /** boms activos en la base de datos */
    private _boms: BomModel[];

    /**
     * boms que encuadran con lo seleccionado en el carrito
     */
    private _bomsByCartSelection: BomModel[] = [];

    bomsChange = new Subject<BomModel[]>();

    constructor(
        public router: Router,
        public http: HttpClient) {
        super(router);
    }

    /**
     * Devuelve los todos los boms
     */
    get boms(): BomModel[] {
        return this._boms;
    }

    /**
     * Devuelve los boms filtrados segun el carrito
     */
    get bomsByCartSelection(): BomModel[] {
        return this._bomsByCartSelection;
    }

    /**
     * Devuelve el mejor bom de los filtrados segun el carrito o null
     */
    get bomBestPromo(): BomModel | null {
        if (this._bomsByCartSelection && this._bomsByCartSelection.length > 0) {
            return this._bomsByCartSelection[0];
        } else {
            return null;
        }
    }

    /**
     * deveulve los boms para la lista (activos)
     */
    get bomsForList(): BomModel[] {
        if (this.boms) {
            return this.boms.filter(b => b.visibility === true);
        } else {
            return [];
        }
    }

    @Cacheable({
        cacheBusterObserver: cacheBuster$
    })
    /**
     * Trae los boms del server
     * @param subsidiaryId id de la sucursal
     */
    getBoms(subsidiaryId: number, corporative: number = 0): Observable<{} | GeneralResponse> {
        const url = `${
            this._api
            }client/boms?filter[subsidiary]=${subsidiaryId}&filter[corporative]=${corporative}&include=images,products.product.channels,products.optionals`;
        return this.http.get<GeneralResponse>(url).pipe(
            map(res => {
                this._boms = BomModel.createArray(res.data, new BomModel());
                this.emitBomChanges();
                return new GeneralResponse('', false, false, null);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    public emitBomChanges(): void {
        this.bomsChange.next(this._boms);
    }

    /**
     * busca los boms segun si tienen prod iguales o mejores q los del carrito
     */
    public findBestBoms(productsInCart: ProductModel[]) {
        this._bomsByCartSelection = [];
        // si el carro tiene al menos 2 productos
        if (productsInCart.length >= 2) {
            // por cada bom (visible y no visible)
            bom_loop:
                for (const b of this._boms) {
                    // por cada producto en el carro
                    for (const prodInCart of productsInCart) {
                        // busco para la familia prods de igual o mayor peso
                        if (b.products.filter(bp => bp.family.id === prodInCart.family.id && bp.weight >= prodInCart.weight)
                            .length === 0) {
                            // si no encuentro nada paso al otro bom
                            continue bom_loop;
                        }
                    }
                    // si llego aca agrego el bom como amigable con la promo
                    this._bomsByCartSelection.push(b);
                }
        }

        if (this._bomsByCartSelection.length > 0) {
            // ordeno desc por precio
            this._bomsByCartSelection.sort(HelperFunctions.compareValues('price'));
            this.emitHasPromo.next(this._bomsByCartSelection[0]);
        } else {
            this.emitHasPromo.next(null);
        }
    }

    /**
     * limpia el mejor bom segun el carrito
     */
    cleanBestBom() {
        this._bomsByCartSelection = [];
        this.emitHasPromo.next(null);
    }
}
