import { Component, OnInit } from '@angular/core';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {DataService} from '../../../providers/data/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {HelperFunctions} from '../../../helpers/functions';
import {ConnectionModel} from '../../../models/client/connection.model';
import {ClientService} from '../../../providers/auth/client.service';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';

@Component({
  selector: 'app-modal-technical-claim',
  templateUrl: './modal-technical-claim.component.html',
  styleUrls: ['./modal-technical-claim.component.scss']
})
export class ModalTechnicalClaimComponent extends ProceduresBaseComponent implements OnInit {


    form: FormGroup;
    title: string;
    currentConnection: ConnectionModel;

    constructor(
        bsModalRef: BsModalRef,
        spinner: NgxSpinnerService,
        proceduresService: ProceduresService,
        private clientsService: ClientService,
        private dataService: DataService,
        private _formBuilder: FormBuilder
    ) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.currentConnection = this.clientsService.currentConection;
        this.initForm();
    }

    initForm() {
        this.form = this._formBuilder.group({
            comment: ['', [Validators.required]],
            timeFrom: ['0900', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            timeUntil: ['1800', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
        });
    }

    get isValidForm(): boolean {
        return this.form.valid && this.captchaValid;
    }

    validInput(input: string): boolean {
        const control = this.form.get(input);
        if (control.dirty) {
            return !this.form.get(input).valid;
        } else {
            return false;
        }
    }

    makeProcedure(): ProcedureModel {
        const procedure = new ProcedureModel();

        procedure.initForNewProcedure();
        procedure.client = this.clientsService.currentClient;
        procedure.connection = this.currentConnection;
        procedure.subsidiary = this.clientsService.currentClient.subsidiary;
        procedure.contactPhone = null;
        procedure.contactTime = HelperFunctions.formatContactTime(this.form.value.timeFrom, this.form.value.timeUntil);
        procedure.clientDetails = null;
        procedure.procedureKind = this.dataService.procedureKind.find(
            pk => pk.tag === ProcedureKind.TECHNICAL_CLAIM
        );
        procedure.data = [
            new ProcedureDataModel('comentario', this.form.value.comment)
        ];
        return procedure;
    }
}
