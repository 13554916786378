import { Component, OnInit } from '@angular/core';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {NgxSpinnerService} from 'ngx-spinner';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {HelperFunctions} from '../../../helpers/functions';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientService} from '../../../providers/auth/client.service';
import {DataService} from '../../../providers/data/data.service';
import {ConnectionModel} from '../../../models/client/connection.model';

@Component({
    selector: 'app-modal-change-address',
    templateUrl: './modal-change-address.component.html',
    styleUrls: ['./modal-change-address.component.scss']
})
export class ModalChangeAddressComponent extends ProceduresBaseComponent  implements OnInit {

    type: string; // bill | service
    title: string;
    form: FormGroup;
    currentConnection: ConnectionModel;

    constructor( bsModalRef: BsModalRef,
                 spinner: NgxSpinnerService,
                 proceduresService: ProceduresService,
                 private _formBuilder: FormBuilder,
                 private clientsService: ClientService,
                 private dataService: DataService) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.currentConnection = this.clientsService.currentConection;
        this.initForm();
    }
    get connectionType(): string {
      return (this.type === 'bill' ? 'facturación' : 'servicio');
    }

    initForm() {
        this.form = this._formBuilder.group({
            street: ['', [Validators.required]],
            streetNumber: ['', [Validators.required]],
            streetFloor: [''],
            streetDept: [''],
            postalCode: ['', Validators.required],
            timeFrom: ['0900', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            timeUntil: ['1800', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
        });
    }

    get isValidForm(): boolean {
        return this.form.valid && this.captchaValid;
    }

    makeProcedure(): ProcedureModel {
        const procedure = new ProcedureModel();

        procedure.initForNewProcedure();
        procedure.client = this.clientsService.currentClient;
        procedure.connection = this.currentConnection;
        procedure.subsidiary = this.clientsService.currentClient.subsidiary;
        procedure.contactPhone = null;
        procedure.contactTime = HelperFunctions.formatContactTime(this.form.value.timeFrom, this.form.value.timeUntil);
        procedure.clientDetails = null;
        if (this.type === 'bill') {
            procedure.procedureKind = this.dataService.procedureKind.find(
                pk => pk.tag === ProcedureKind.CHANGE_BILL_ADDRESS
            );
        } else {
            procedure.procedureKind = this.dataService.procedureKind.find(
                pk => pk.tag === ProcedureKind.CHANGE_SERVICE_ADDRESS
            );
        }

        procedure.data = [
            new ProcedureDataModel('calle', this.form.value.street),
            new ProcedureDataModel('número', this.form.value.streetNumber),
            new ProcedureDataModel('piso', this.form.value.streetFloor),
            new ProcedureDataModel('depto', this.form.value.streetDept),
            new ProcedureDataModel('código postal', this.form.value.postalCode.toString()),
        ];
        return procedure;
    }

    validInput(input: string): boolean {
        const control = this.form.get(input);
        if (control.dirty) {
            return !this.form.get(input).valid;
        } else {
            return false;
        }
    }

}
