import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AccountModel } from 'src/app/models/client/account.model';
import { KeyValueModel } from 'src/app/models/data/key-value.model';
import { environment } from 'src/environments/environment';
import {ClientService} from "../../../providers/auth/client.service";

@Component({
  selector: 'app-modal-payment-confirm',
  templateUrl: './modal-payment-confirm.component.html',
  styleUrls: ['./modal-payment-confirm.component.scss']
})
export class ModalPaymentConfirmComponent implements OnInit {

  title = 'Confirmar Pago';
  account: AccountModel;
  payment_type: string;
  payment_total: number;
  keyValues: KeyValueModel[];

  @ViewChild('wondersoftForm') wondersoftForm: ElementRef;
  constructor(private bsModalRef: BsModalRef,
              private clientService: ClientService) { }

  ngOnInit() {
    switch (this.payment_type) {
      case 'last':
        this.payment_total = this.clientService.currentClient.paymentLast;
        break;
      case 'min':
        this.payment_total = this.clientService.currentClient.paymentMin;
        break;
      case 'total':
        this.payment_total = this.clientService.currentClient.paymentTotal;
        break;
      case 'ott':
        this.payment_total = 3;
        break;
    }
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onSubmitRccpagosForm() {
    this.wondersoftForm.nativeElement.submit();
  }

  get wsUri(): string {
      return environment.externalUris.wsPayment;
  }

  get isPaymentLast(): boolean {
    return (this.payment_type === 'last');
  }

  get isPaymentMin(): boolean {
    return (this.payment_type === 'min');
  }

  get isPaymentTotal(): boolean {
    return (this.payment_type === 'total');
  }

  get isPaymentOTT(): boolean {
    return (this.payment_type === 'ott');
  }
}
