export class MovistarPlanModel {

  public name: string;
  public desc: string;
  public open: boolean;
  public price: {number: string, decimal: string};
  public listPrice: {number: string, decimal: string};
  public includes: {name: string, description: string, hasTitle: boolean}[];
  terms: string;

  constructor(name, desc) {
    this.name = name;
    this.desc = desc;
    this.open = true;
  }
}
