<section id="content" class="section-with-header preguntas-frecuentes pb-5">
  <div class="section-header">
    <div class="container">
      <h1>Preguntas Frecuentes</h1>
    </div>
  </div>

  <div class="container section-content my-5">

    <div class="row preguntas">

      <div class="col-md-6">
        <div class="color-secondary font-weight-bold mb-3 datos-utiles">Sobre la empresa</div>

        <div class="pregunta">
          ¿Qué es Express?
        </div>
        <div class="respuesta">
          Somos una empresa de telecomunicaciones con sedes en Buenos Aires, Rosario,
          Salta y Santiago del Estero.
        </div>

        <div class="pregunta">
          ¿Qué servicios ofrece?
        </div>
        <div class="respuesta">
          Nuestros servicios son televisión en alta definición, internet y telefonía digital fija.
          Además, contamos con Ecorp, un servicio de internet corporativo y <a href="http://now.express.com.ar/" target="_blank">NOW Express</a>
          una aplicación que le permite a nuestros abonados ver los mejores contenidos en todos sus dispositivos.
        </div>

        <div class="pregunta">
          ¿Por qué elegir nuestros servicios?
        </div>
        <div class="respuesta">
          Invertimos constantemente en la mejor tecnología para prestar servicios de calidad. Contamos con un centro de
          operación de redes propio (Network Operation Center) que nos permite garantizar la estabilidad del producto y
          resolver inconvenientes de manera inmediata. También, tenemos centros de atención disponibles todos los días de
          la semana y un equipo de operarios capacitados para resolver diferentes problemas.
        </div>

        <div class="pregunta">
          ¿Qué es la TV HD?
        </div>
        <div class="respuesta">
          La televisión HD brinda imágenes y videos con una mayor resolución que la definición estándar. Nuestros abonados
          podrán contar con mejor definición en colores, imágenes y detalles (pantalla 16:9 Wide Screen) y mejores sonidos
          (Dolby Digital 5.1 Surround). Además, los clientes tendrán acceso a guía interactiva en pantalla, información de
          programación, selección de favoritos, control de acceso para menores, entre otras aplicaciones.
        </div>

        <div class="pregunta">
          ¿Cuáles son los requisitos para acceder a la TV HD?
        </div>
        <div class="respuesta">
          Para acceder a la TV HD es necesario el decodificador de Express que se puede solicitar en nuestras sucursales, por Oficina Virtual o llamando al 08105553977.
        </div>

        <div class="pregunta">
          ¿Cómo conectar el decodificador a la TV?
        </div>
        <div class="respuesta">
          Para conectar correctamente el decodificador a la TV, seguí los pasos de este <a href="https://youtu.be/mbBsN4-Z6Xc" target="_blank">tutorial</a>.
        </div>
      </div>

      <div class="col-md-6">
        <div class="color-secondary font-weight-bold mb-3 datos-utiles">Información para Abonados</div>

        <div class="pregunta">
          Factura Electrónica
        </div>
        <div class="respuesta">
          Podés adherite a Factura Electrónica desde Oficina Virtual, en nuestras sucursales o llamando al 0810 555 3977.
        </div>

        <div class="pregunta">
          Pago fuera de término
        </div>
        <div class="respuesta">
          En el caso de presentar más de dos facturas vencidas, debés contactarte con nuestro centro de atención al cliente para acordar cómo realizar el pago. Transcurrido ese período, estamos obligados a interrumpir la conexión.
          Asimismo, la baja de los servicios ante la falta de pago no implica el cese de su obligación de cancelar la deuda.

        </div>

        <div class="pregunta">
          Baja del servicio
        </div>
        <div class="respuesta">
          Podés gestionar la cancelación del servicio desde Oficina Virtual, por teléfono al 08105553977 o personalmente en nuestras sucursales, preferentemente antes de los primeros quince días del mes anterior a la cancelación.
          Al momento de la desvinculación, deberás devolver los dispositivos cedido por la empresa.
        </div>

        <div class="pregunta">
          Cambio de domicilio
        </div>
        <div class="respuesta">
          Este trámite lo podés gestionar en Oficina Virtual o en nuestras sucursales. Es importante tener en claro que el nuevo domicilio tiene que estar dentro de nuestras zonas de cobertura.
        </div>

        <div class="pregunta">
          Servicio técnico
        </div>
        <div class="respuesta">
          El personal debe presentarse con el uniforme de la empresa. Ningún representante está autorizado a cobrar importe alguno, ni a retirar equipos propios o ajenos.
        </div>
      </div>
    </div>
  </div>
</section>