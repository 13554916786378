<app-plan-details *ngIf="product"
  [family]="product.family"
  [product]="product"
  [productOriginal]="productOriginal"
  [isBom]="true"
  [optionalsSelected]="product.optionals"
  [optionalsOriginal]="optionalsOriginal"
  (goBack)="goBack()"
  (changeSelectedOptional)="onChangeSelectedOptional($event)">
  </app-plan-details>

<app-cart-select-price-bar
  *ngIf="product"
  [buttonText]="'AGREGAR A PROMO'"
  [price]="totalPrice"
  [promoSubscribe]="false"
  (apply)="addToCart()">
</app-cart-select-price-bar>
