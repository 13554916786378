<section id="content" class="section-with-header centros-de-atencion pb-5">
  <div class="section-header">
    <div class="container">
      <h1>Centros de Atención</h1>
    </div>
  </div>

  <div class="container section-content my-5">
    <div class="titulo-seccion color-primary">
      Medios de contacto
    </div>

    <div class="row">
      <div class="col-atencion col-sm-12 col-md-6 col-lg-4 align-self-center">
        <div class="col-auto text-center">
          <img src="assets/img/ic_phone_venta_telefonica1.png" class="img-ventas img-fluid"/>
        </div>
      </div>
      <div class="col-atencion ol-sm-12 col-md-6 col-lg-4 align-self-center">
        <div class="color-secondary font-weight-bold text-uppercase" *ngIf="subsidiary.info.showTitle">Venta</div>
        <div class="color-primary text-horario">{{subsidiary.info.venta}}</div>

        <div class="color-secondary font-weight-bold mt-2 text-uppercase" *ngIf="subsidiary.info.reclamos">Reclamos técnicos</div>
        <div class="color-primary text-horario" *ngIf="subsidiary.info.reclamos">{{subsidiary.info.reclamos}}</div>
      </div>
      <div class="col-atencion col-sm-12 col-md-12 col-lg-4">
        <div class="color-secondary font-weight-bold text-uppercase">Oficina Virtual </div>
        <div class="color-primary text-horario">Ingresá a tu cuenta para realizar tus consultas y trámites online.</div>
        <div class="mt-4">
          <a href="javascript:void(0)" (click)="goToVirtualOffice()" class="btn btn-seccion bg-color-secondary">ENTRAR</a>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-lg-6" *ngFor="let center of subsidiary.addresses">
        <div class="titulo-seccion color-primary mt-5">
          {{center.name}}
        </div>

        <div class="row">
          <div class="col-auto pr-0 pt-1">
            <img src="assets/img/ic_pin_mapa.png" />
          </div>
          <div class="col">
            <div class="color-secondary font-weight-bold">{{center.address}}</div>
            <div class="color-primary text-horario">{{center.hours}}</div>
          </div>
        </div>

        <div class="row justify-content-end mr-1">
          <a target="_blank" [href]="mapAddress(center)"><small>ABRIR MAPA</small></a>
        </div>
        <div class="mapa mt-3"  [id]="center.tag"></div>
      </div>
    </div>
  </div>
</section>
