import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DataModel } from "src/app/models/data/data.model";
import { DataService } from "src/app/providers/data/data.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { ProcedureModel } from "src/app/models/procedure/procedure.model";
import { AuthService } from "src/app/providers/auth/auth.service";
import { ClientService } from "src/app/providers/auth/client.service";
import { CartService } from "src/app/providers/cart/cart.service";
import { ProcedureDataModel } from "src/app/models/procedure/procedure-data.model";
import { ProcedureKind } from "src/app/models/data/procedure-kind.enum";
import { HelperFunctions } from "src/app/helpers/functions";
import { CartBomService } from "src/app/providers/cart/cart-bom.service";
import { ProceduresBaseComponent } from "../procedures-base-component";
import { NgxSpinnerService } from "ngx-spinner";
import { ProceduresService } from "src/app/providers/procedures/procedures.service";
import { SubsidiaryService } from "src/app/providers/data/subsidiary.service";
import { takeUntil } from "rxjs/operators";
import { GeneralResponse } from "src/app/models/general-response.model";

@Component({
  selector: "app-modal-cart-confirm",
  templateUrl: "./modal-cart-confirm.component.html",
  styleUrls: ["./modal-cart-confirm.component.scss"],
})
export class ModalCartConfirmComponent
  extends ProceduresBaseComponent
  implements OnInit
{
  formCart: FormGroup;

  title: string;
  type: string; // cart | bom
  applyMovistar: boolean = false;
  addTvNow: boolean = false;

  paymentMethodMask = "0000 0000 0000 0000";
  paymentNumberEnabled = false;
  @Input() embebed = false;
  @Input() applyMovistarEmbebed = false;
  @Input() addTvNowEmbebed = false;
  @Output() onSubmitProcedure: EventEmitter<GeneralResponse> =
    new EventEmitter<GeneralResponse>();

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private dataService: DataService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private clientsService: ClientService,
    private cartService: CartService,
    private cartBomService: CartBomService,
    private subsidiaryService: SubsidiaryService
  ) {
    super(bsModalRef, spinner, proceduresService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initForm();
  }

  initForm() {
    this.formCart = this._formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      phone: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      docType: ["", [Validators.required]],
      docNumber: [
        "",
        [Validators.required, Validators.minLength(6), Validators.maxLength(9)],
      ],
      // creditCard: [''],
      paymentMethod: ["", Validators.required],
      paymentNumber: ["", Validators.required],
      comment: [""],

      street: ["", [Validators.required]],
      streetNumber: ["", [Validators.required]],
      streetFloor: [""],
      streetDept: [""],

      timeFrom: [
        "0900",
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
      timeUntil: [
        "1800",
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
    });

    this.formCart
      .get("paymentMethod")
      .valueChanges.pipe(takeUntil(this.componentDestroyed))
      .subscribe((val) => {
        this.formCart.get("paymentNumber").setValue("");
        if (val === "tc") {
          this.paymentMethodMask = "0000 0000 0000 0000";
          this.paymentNumberEnabled = true;
        } else if (val === "cbu") {
          this.paymentMethodMask = "0000000000000000000000";
          this.paymentNumberEnabled = true;
        } else {
          this.paymentNumberEnabled = false;
        }
      });
  }

  get documents(): DataModel[] {
    return this.dataService.documents;
  }

  validInput(input: string): boolean {
    const control = this.formCart.get(input);
    if (control.dirty) {
      return !this.formCart.get(input).valid;
    } else {
      return false;
    }
  }

  get isValidForm(): boolean {
    return this.formCart.valid && this.captchaValid;
  }

  makeProcedure(): ProcedureModel {
    const procedure = new ProcedureModel();

    procedure.initForNewProcedure();

    // si esta logeado envio el cliente q realiza la operacion
    if (this.authService.loggedIn) {
      procedure.client = this.clientsService.currentClient;
    } else {
      procedure.client = null;
    }

    procedure.subsidiary = this.subsidiaryService.currentSubsidiary;
    procedure.clientDetails = this.formCart.value.comment;
    procedure.contactTime = HelperFunctions.formatContactTime(
      this.formCart.value.timeFrom,
      this.formCart.value.timeUntil
    );
    procedure.contactPhone = this.formCart.value.phone;
    procedure.procedureKind = this.dataService.procedureKind.find(
      (pk) => pk.tag === ProcedureKind.NEW_CLIENT_CONNECTION
    );

    if (this.type === "bom" || this.embebed) {
      procedure.bom = this.cartBomService.cart.bom;
      procedure.products = this.cartBomService.cart.products;
      procedure.price = this.cartBomService.cart.price;
    } else {
      procedure.products = this.cartService.cart.products;
      procedure.price = this.cartService.cart.price;
    }

    procedure.data = [
      new ProcedureDataModel("nombre", this.formCart.value.name),
      new ProcedureDataModel("email", this.formCart.value.email),
      new ProcedureDataModel("telefono", this.formCart.value.phone),
      new ProcedureDataModel(
        "documento",
        this.formCart.value.docType + " " + this.formCart.value.docNumber
      ),
      new ProcedureDataModel("calle", this.formCart.value.street),
      new ProcedureDataModel("numero", this.formCart.value.streetNumber),
      new ProcedureDataModel("piso", this.formCart.value.streetFloor),
      new ProcedureDataModel("depto", this.formCart.value.streetDept),
      new ProcedureDataModel(
        "medio-pago",
        this.formCart.value.paymentMethod +
          " - " +
          this.formCart.value.paymentNumber
      ),
    ];
    debugger;
    // el form embebido es para boms siempre
    if (this.type === "bom" || this.embebed) {
      if (this.embebed) {
        procedure.data.push(
          new ProcedureDataModel("movistar", this.applyMovistarEmbebed ? "SI" : "NO")
        );
        if (procedure.bom.showTvNowCheck) {
          procedure.data.push(
            new ProcedureDataModel("TV + NOW", this.addTvNowEmbebed ? "SI" : "NO")
          );
        }
      } else {
        procedure.data.push(
          new ProcedureDataModel("movistar", this.applyMovistar ? "SI" : "NO")
        );
        if (procedure.bom.showTvNowCheck) {
          procedure.data.push(
            new ProcedureDataModel("TV + NOW", this.addTvNow ? "SI" : "NO")
          );
        }
      }
      
    }

    return procedure;
  }

  onConfirm(): any {
    super.onConfirm();
    this.onCloseModal.subscribe((response: GeneralResponse) => {
      this.onSubmitProcedure.emit(response);
    });
  }
}
