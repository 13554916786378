import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { Observable, Subject } from 'rxjs';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { map, catchError } from 'rxjs/operators';
import { AccountModel } from 'src/app/models/client/account.model';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { KeyValueModel } from 'src/app/models/data/key-value.model';
import { CouponModel } from '../../models/data/coupon.model';
import { AccountPaidModel } from '../../models/client/account-paid.model';

const cacheBusterForList$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  constructor(public router: Router, public http: HttpClient) {
    super(router);
  }

  // http
  /**
   * get accounts for the client
   */
  @Cacheable({
    cacheBusterObserver: cacheBusterForList$
  })
  getAccounts(clientId: number): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/me/${clientId}/accounts?filter[type]=f`;
    return this.http.get<GeneralResponse>(url).pipe(
      map(res => {
        if (!res.error) {
          const accounts = AccountModel.createArray(
            res.data,
            new AccountModel()
          );
          return new GeneralResponse('', false, false, accounts);
        } else {
          return new GeneralResponse(res.message, true, false, null);
        }
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  /**
   * get accounts for the client
   */
  getPrintAccount(
    clientId: number,
    accountId: number
  ): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/me/${clientId}/accounts/print/${accountId}`;
    return this.http.get<GeneralResponse>(url).pipe(
      map(res => {
        if (!res.error) {
          if (res.data.base_64 == null) {
            return new GeneralResponse(
              'No se pudo generar la factura solicitada. Reintentelo o comuniquese con nosotros.',
              true,
              false,
              null
            );
          } else {
            return new GeneralResponse('', false, false, res.data);
          }
        } else {
          return new GeneralResponse(res.message, true, false, null);
        }
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  getAccountPaymentData(
    clientId: number,
    accountId: number,
    selectedPayment: string,
    creditCard: number
  ): Observable<{} | GeneralResponse> {
    const url = `${this._api
      }client/me/${clientId}/accounts/payment/${accountId}/${selectedPayment}`;
    return this.http.get<GeneralResponse>(url).pipe(
      map(res => {
        if (!res.error) {
          if (res.data.error) {
            return new GeneralResponse(res.data.message, true, false, null);
          } else {
            const keyValues = KeyValueModel.createArray(
              res.data,
              new KeyValueModel()
            );
            return new GeneralResponse('', false, false, keyValues);
          }
        } else {
          return new GeneralResponse(res.message, true, false, null);
        }
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }


  @CacheBuster({
    cacheBusterNotifier: cacheBusterForList$
  })
  setAccountPaid(
    clientId: number,
    response_code: string,
    response_description: string,
    verificationCode: string
  ): Observable<{} | GeneralResponse> {
    const url = `${this._api
      }client/me/${clientId}/accounts/paid`;
    return this.http.put<GeneralResponse>(url,
      {'verification_code': verificationCode, 'response_code': response_code, 'response_description': response_description}
    ).pipe(
      map(res => {
        return new GeneralResponse(res.message, res.error, false, null);
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }


  getPaymentCouponData(clientId: number): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/me/${clientId}/accounts/coupon`;
    return this.http.get<GeneralResponse>(url).pipe(
      map(res => {
        if (!res.error) {
          if (res.data.error) {
            return new GeneralResponse(res.data.message, true, false, null);
          } else {
            const couponData = CouponModel.createOne(
              res.data,
              new CouponModel()
            );
            return new GeneralResponse('', false, false, couponData);
          }
        } else {
          return new GeneralResponse(res.message, true, false, null);
        }
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }

  getAccountPaids(clientId: number): Observable<{} | GeneralResponse> {
    const url = `${this._api
      }client/me/${clientId}/accounts/paid/10?filter[active]=1`;
    return this.http.get<GeneralResponse>(url).pipe(
      map(res => {
        return new GeneralResponse(res.message, res.error, false, AccountPaidModel.createArray(res.data, new AccountPaidModel()));
      }),
      catchError(err => {
        return this.handleError(err);
      })
    );
  }


}
