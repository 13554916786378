<div class="condiciones text-justify text-break-all" *ngIf="terms">
    <a class="btn font-weight-bold color-primary btn-terms cursor-pointer"
       aria-expanded="false"
       aria-controls="collapseTerms"
       (click)="toggleCollapse()">
        <i class="fa" [ngClass]="{'fa-caret-right': isCollapsed, 'fa-caret-up':!isCollapsed}"></i>
        CONDICIONES COMERCIALES
    </a>
    <div id="collapseTerms" [collapse]="isCollapsed">
        <p class="align-last">
        <span class="border-top-0" *ngFor="let term of terms">
            &nbsp;<strong>{{term.product}}&nbsp;</strong>
            {{term.text}}&nbsp;
        </span>
        </p>
    </div>
</div>
