import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'src/app/shared/directives/collapse';
import { HeaderComponent } from './header.component';
import { HeaderSubsidiariesComponent } from './header-subsidiaries/header-subsidiaries.component';
import { MenuProductsComponent } from './menu-products/menu-products.component';
import { MenuClientComponent } from './menu-client/menu-client.component';
import {ModalContactMovilComponent} from '../../../main/procedures/modal-contact-movil/modal-contact-movil.component';
import {ModalHomeGenericRequestComponent} from '../../../main/procedures/modal-home-generic-request/modal-home-generic-request.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderSubsidiariesComponent,
    MenuProductsComponent,
    MenuClientComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,

    BsDropdownModule.forRoot(),
    CollapseModule,
  ],
  exports: [
    HeaderComponent,
  ],
  entryComponents: [
    ModalContactMovilComponent,
    ModalHomeGenericRequestComponent
  ]
})
export class HeaderModule { }
