<div class="container">
    <div class="row w-100">
      <div class="col-sm-6 sub-menu mb-xl-0 mb-6">
        <h3>Información</h3>
        <div class="row">
          <div class="col-sm-12">
            <ul class="list-unstyled">
              <li>
                <a class="menu-item pl-0" (click)="onGoToStaticSection('centros-de-atencion')"  href="javascript:void(0)">
                  <div class="nombre">CENTROS DE ATENCIÓN</div>
                </a>
              </li>
              <li>
                <a class="menu-item pl-0" (click)="onGoToStaticSection('lugares-de-pago')"  href="javascript:void(0)">
                  <div class="nombre">LUGARES Y MEDIOS DE PAGO</div>
                </a>
              </li>
              <li>
                <a class="menu-item pl-0" (click)="onGoToStaticSection('preguntas-frecuentes')"  href="javascript:void(0)">
                  <div class="nombre">PREGUNTAS FRECUENTES</div>
                </a>
              </li>
              <li>
                <a class="menu-item pl-0" (click)="onGoToStaticSection('consejos-utiles')"  href="javascript:void(0)">
                  <div class="nombre">CONSEJOS ÚTILES</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-6 sub-menu mb-md-0 mb-xl-0 mb-6 autogestion">
        <h3>Oficina Virtual</h3>
        <div class="nombre">INGRESÁ A TU CUENTA</div>
        <a class="btn" (click)="onGoToVirtualOffice()">ENTRAR</a>
      </div>
    </div>
  </div>
