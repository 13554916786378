import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProductModel } from 'src/app/models/product/product.model';
import { DeviceModel } from 'src/app/models/product/device.model';
import { ClientService } from 'src/app/providers/auth/client.service';
import { HelperFunctions } from 'src/app/helpers/functions';
import { ProcedureKind } from 'src/app/models/data/procedure-kind.enum';
import { DataService } from 'src/app/providers/data/data.service';
import { ProceduresBaseComponent } from '../procedures-base-component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';

@Component({
    selector: 'app-modal-adquire-product-confirm',
    templateUrl: './modal-adquire-product-confirm.component.html',
    styleUrls: ['./modal-adquire-product-confirm.component.scss']
})
export class ModalAdquireProductConfirmComponent extends ProceduresBaseComponent implements OnInit {

    plan: ProductModel;
    product: ProductModel;

    devicesSelected: DeviceModel[] = [];

    timeFrom = '0900';
    timeUntil = '1800';
    comment = '';
    phone = '';

    constructor(
        bsModalRef: BsModalRef,
        spinner: NgxSpinnerService,
        proceduresService: ProceduresService,
        private clientService: ClientService,
        private dataService: DataService,
    ) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.plan) {
          if (this.product.deviceType === 'TODOS') {
            this.devicesSelected = this.clientService.currentConection.devices
            .filter(d => d.product.family.id === this.plan.family.id);
          }
        }
    }

    /** devuelve los dispositivos de la conexion segun el producto a adquirir */
    get devices(): DeviceModel[] {
        return this.clientService.currentConection.devices.filter(
            d => d.type === this.product.deviceType
        );
    }

    changeSelection($event, device: DeviceModel): void {
        if ($event.srcElement.checked) {
            this.devicesSelected.push(device);
        } else {
            this.devicesSelected.splice(this.devicesSelected.indexOf(device), 1);
        }
    }

    get optionalsText(): string {
        if (this.product.optionals.length > 0) {
            return Array.prototype.map.call(this.product.optionals, o => o.name).join(' | ');
        } else {
            return '';
        }
    }
    // abstract clases
    get isValidForm(): boolean {
        if (this.product.needDevice) {
            return (this.devicesSelected.length > 0);
        } else {
            return true;
        }
    }

    makeProcedure(): ProcedureModel {
        const procedure = new ProcedureModel();

        procedure.initForNewProcedure();

        procedure.client = this.clientService.currentClient;
        procedure.connection = this.clientService.currentConection;
        procedure.subsidiary = this.clientService.currentClient.subsidiary;

        procedure.clientDetails = this.comment;
        procedure.contactTime = HelperFunctions.formatContactTime(this.timeFrom, this.timeUntil);
        procedure.contactPhone = this.phone;

        // si tiene plan estoy agregando un producto al mismo
        if (this.plan) {
            procedure.procedureKind = this.dataService.procedureKind
                .find(pk => pk.tag === ProcedureKind.ADD_PRODUCT_TO_PLAN);
            procedure.planId = this.plan.id;
        } else {
            procedure.procedureKind = this.dataService.procedureKind
                .find(pk => pk.tag === ProcedureKind.BUY_NEW_PLAN);
        }
        procedure.products.push(this.product);
        procedure.price = this.product.price;
        if (this.product.needDevice) {
            procedure.devices = this.devicesSelected;
        }
        return procedure;
    }
}
