<div class="modal-xl">
  <div>
    <div class="modal-header modal-header-blue">
      <button
              type="button"
              class="close pull-right text-white"
              aria-label="Close"
              (click)="onClose()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div style="max-height: 80%">
      <img [src]="src" class="img-fluid" style="cursor: pointer" (click)="actionClick()">
    </div>
  </div>
</div>
