<img [ngStyle]="{'background-image': 'url(' + bom.backgroundImageUrl +')'}" [src]="bom.principalImageUrl" class="img-fluid mb-1"/>
        <div *ngIf="isPromo" class="row" style="color: red;">
            <div class="col-md-12">
              <i class="fa fa-circle"></i>
              &nbsp;
              <strong>ESTA PROMO ES SIMILAR A LO QUE ESTAS BUSCANDO</strong>
            </div>
        </div>
            <div class="row">
                <div class="col-md-7">
                    <div class="color-primary font-weight-bold">{{bom.name}}</div>
                    <div>{{bom.productsConcat}}</div>
                    <div class="precio color-primary font-weight-bold">{{bom.price | currency:'es-ar':'$' }}</div>
                </div>
                <div class="col-md-5">
                    <a href="javascript:void(0);" (click)="onViewBom()" class="btn btn-carrito d-block">VER</a>
                </div>
            </div>

            <div class="row mb-5">
                <div class="col">
                    <div class="info">
                        {{bom.description}}
                    </div>
                </div>
            </div>
