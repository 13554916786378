import { Component, OnInit, Input } from '@angular/core';
import { BomModel } from 'src/app/models/product/bom.model';
import { Router, ActivatedRoute } from '@angular/router';
import { BomsService } from 'src/app/providers/products/boms.service';
import { CartBomService } from 'src/app/providers/cart/cart-bom.service';
import { SubsidiaryService } from 'src/app/providers/data/subsidiary.service';

@Component({
    selector: 'app-bom-home',
    templateUrl: './bom-home.component.html',
    styleUrls: ['./bom-home.component.scss']
})
export class BomHomeComponent implements OnInit {

    constructor(
        private bomsService: BomsService,
        private cartBomService: CartBomService,
        private subsidiaryService: SubsidiaryService,
        private _router: Router,
        private _activeRoute: ActivatedRoute) {}

    ngOnInit() {
        // si hay 1 bom seleccionado lo mando a verlo
        if (this.cartBomService.hasBom) {
            this.goToBomProductPage(this.cartBomService.cart.bom);
        }
    }

    /**
     * retorna los bom de promo
     */
    get bomsPromo(): BomModel[] {
        return this.bomsService.bomsByCartSelection;
    }

    /**
     * retorna los boms - los boms de promo
     */
    get boms(): BomModel[] {
        return this.bomsService.bomsForList.filter(b => this.bomsPromo.indexOf(b) < 0);
    }

    /**
     * ruta al detalle de un bom
     * @param bom bom a ver
     */
    goToBomProductPage(bom: BomModel): void {
        this._router.navigate(['../detalle/' + `${bom.id}`], {relativeTo: this._activeRoute});

    }

}
