import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { HomeComponent } from './main/home/home.component';
import {FaqComponent} from './main/static-sections/faq/faq.component';
import {PaymentPlacesComponent} from './main/static-sections/payment-places/payment-places.component';
import {HelpfulTipsComponent} from './main/static-sections/helpful-tips/helpful-tips.component';
import {ContactCentersComponent} from './main/static-sections/contact-centers/contact-centers.component';
import {NowExpressComponent} from './main/static-sections/now-express/now-express.component';
import {FinderComponent} from './main/static-sections/finder/finder.component';
import {MovistarComponent} from './main/static-sections/movistar/movistar.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'preguntas-frecuentes',
    component: FaqComponent,
  },
  {
    path: 'lugares-de-pago',
    component: PaymentPlacesComponent,
  },
  {
    path: 'consejos-utiles',
    component: HelpfulTipsComponent,
  },
  {
    path: 'centros-de-atencion',
    component: ContactCentersComponent,
  },
  {
    path: 'now-express',
    component: NowExpressComponent,
  },
  {
    path: 'buscador-de-programacion',
    component: FinderComponent,
  },
  {
    path: 'movistar',
    component: MovistarComponent,
  },
  {
    path: 'oficina-virtual',
    loadChildren: () => import('./main/virtual-office/virtual-office.module').then( m=> m.VirtualOfficeModule)
  },
  {
    path: 'pago-directo',
    loadChildren: () => import('./main/app-mobile/app-mobile.module').then( m=> m.AppMobileModule)
  },
  {
    path: 'recomendador',
    loadChildren: () => import('./main/recommend/recommend.module').then( m=> m.RecommendModule)
  },
 {
    path: 'cliente',
    loadChildren: () => import('./main/auth/auth.module').then( m=> m.AuthModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
