import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DirectPaymentService } from "src/app/providers/direct-payment/direct-payment.service";
import { BaseComponent } from "src/app/core/components/base-component";
import { takeUntil, take } from "rxjs/operators";
import { GeneralResponse } from "src/app/models/general-response.model";
import { KeyValueModel } from "src/app/models/data/key-value.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-direct-payment",
  templateUrl: "./direct-payment.component.html",
  styleUrls: ["./direct-payment.component.scss"],
})
export class DirectPaymentComponent extends BaseComponent implements OnInit {
  clientId;
  clientCode;
  accountId;
  selectedPayment;
  creditCard;
  email;
  ammount = 0;

  keyValues: KeyValueModel[];

  loading = false;
  error = false;
  errorMessage = "";

  @ViewChild("wondersoftForm") wondersoftForm: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private directPaymenService: DirectPaymentService
  ) {
    super();
  }

  ngOnInit() {
    // parametros requeridos
    /*
      // dni= numero doc
      // suc= id sucursal
      clientId = id del cliente
      clientCode = codigo del cliente
      accountId = id del account a pagar
      selectedPayment = [min, last, total]
      creditCard = id de la tarjeta de credito
      email = email del usuario
      ammount = total a pagar
      
      URL Q VIENE DE LA APP
      //pago-directo/pagar?clientId=12&accountId=1164630672&selectedPayment=min&creditCard=2&email=sergio.gregori@mapplics.com&ammount=3512.20&clientCode=121212
    */
    if (
      this.route.snapshot.queryParamMap.has("clientId") &&
      this.route.snapshot.queryParamMap.has("clientCode") &&
      this.route.snapshot.queryParamMap.has("accountId") &&
      this.route.snapshot.queryParamMap.has("selectedPayment") &&
      this.route.snapshot.queryParamMap.has("creditCard") &&
      this.route.snapshot.queryParamMap.has("ammount") &&
      this.route.snapshot.queryParamMap.has("email")
    ) {
      this.clientId = this.route.snapshot.queryParamMap.get("clientId");
      this.clientCode = this.route.snapshot.queryParamMap.get("clientCode");
      this.accountId = this.route.snapshot.queryParamMap.get("accountId");
      this.selectedPayment =
        this.route.snapshot.queryParamMap.get("selectedPayment");
      this.creditCard = this.route.snapshot.queryParamMap.get("creditCard");
      this.email = this.route.snapshot.queryParamMap.get("email");
      this.ammount = +this.route.snapshot.queryParamMap.get("ammount");

      //guardo los valores de cliente para luego el comprobante
      localStorage.setItem("direct-payment-clientId", this.clientId);
      localStorage.setItem("direct-payment-clientCode", this.clientCode);
      localStorage.setItem("direct-payment-ammount", this.ammount.toString());

      // pido el form
      this.directPaymenService
        .getAccountPaymentData(
          this.clientId,
          this.accountId,
          this.selectedPayment,
          this.creditCard,
          this.email
        )
        .pipe(take(1))
        .subscribe(
          (response: GeneralResponse) => {
            if (!response.error) {
              this.keyValues = response.data;
            } else {
              this.error = true;
              this.errorMessage = response.message;
            }
            this.loading = false;
            // this.spinner.hide();
          },
          (response: GeneralResponse) => {
            this.error = true;
            this.errorMessage = response.getFirstError();
            this.loading = false;
          }
        );
    } else {
      // sacar error de parametros
      this.loading = false;
      this.error = true;
      this.errorMessage = "parametros incorrectos";
    }
  }

  onSubmitRccpagosForm() {
    this.wondersoftForm.nativeElement.submit();
  }

  get wsUri(): string {
    return environment.externalUris.wsPayment;
  }
}
