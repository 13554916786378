<section id="content" class="carrito">

    <div class="nav-carrito">
      <ul class="nav nav-tabs justify-content-center" role="tablist">
        <li class="nav-item">
          <a class="nav-item nav-link"
            routerLinkActive="active"
            [routerLink]="['productos']"
            >
            ARMÁ TU COMBO</a>
        </li>
        <li class="nav-item" id="nav-promo-tab">
            <a class="nav-item nav-link"
            [ngClass]="{ 'has-promo': showPromo }"
            routerLinkActive="active"
            [routerLink]="['promos']"
            >PROMOS
            <!--<i *ngIf="showPromo" class="fa fa-circle"></i>-->
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
        <!-- <div class="tab-pane fade show active content-carrito seleccion-producto text-center py-5"
        role="tabpanel" > -->
          <router-outlet></router-outlet>
        <!-- </div> -->
    </div>
</section>
