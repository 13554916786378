<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>


<div class="modal-body" *ngIf="hasService">
  <alert type="info">
    <h4 class="alert-heading">
      <i class="fa fa-info-circle"></i>&nbsp;{{titleMessage}}</h4>
    <p>{{message}}</p>
  </alert>
</div>

<div class="oficina-virtual formulario">
  <div name="form" [formGroup]="form">
    <div class="container py-4 mb-5">
      <div class="row">
        <div class="col-lg">
          <div class="titulo color-primary">{{textFormMessage}}</div>

          <div class="form-group row">
            <div class="col">
              <input type="email" class="form-control form-control-sm" placeholder="Ingrese Email..." formControlName="email"
                [ngClass]="{ 'is-invalid': validInput('email') }" />
            </div>
          </div>

          <app-modal-recaptcha
            (confirmCaptcha)="toggleCaptcha($event)"
          ></app-modal-recaptcha>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal-footer">
  <button [disabled]="!isValidForm" href="javascript:void(0)" class="btn btn-block text-white bg-color-primary d-block"
    (click)="onConfirm()">
    {{buttonText}}
  </button>
</div>
