import { Serializable } from 'src/app/core/models/serializable.model';
import {environment} from '../../../environments/environment';
import { ProductFamilyModel } from './product-family.model';
import { ProductModel } from './product.model';
import { DevicePackageModel } from './device-package.model';

export class DeviceModel extends Serializable {

  public static INTERNET_DEVICE = 'CM/ONU';
  public static CABLE_DEVICE = 'SMART_CARD';

  public id: number;
  public type: string;
  public number: string;
  public product: ProductModel;
  public packages: DevicePackageModel[];

  newModel(data: any): DeviceModel {
      const dataParsed = new DeviceModel(data);
      if (data.product) {
        dataParsed.product = ProductModel.createOne(data.product, new ProductModel());
      }

      if (data.packages) {
        dataParsed.packages = DevicePackageModel.createArray(data.packages, new DevicePackageModel());
      }

      return dataParsed;
  }
}
