import { Component, OnInit, Input } from '@angular/core';
import { ProductChannelModel } from 'src/app/models/product/product-channel.model';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {

  @Input() channels: ProductChannelModel[];

  constructor() { }

  ngOnInit() {
  }

}
