import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ProductFamilyModel } from 'src/app/models/product/product-family.model';
import { CartService } from 'src/app/providers/cart/cart.service';
import { ProductModel } from 'src/app/models/product/product.model';
import { FamiliesService } from 'src/app/providers/products/families.service';
import { BomsService } from 'src/app/providers/products/boms.service';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { DataService } from 'src/app/providers/data/data.service';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';
import { BaseComponent } from 'src/app/core/components/base-component';
import {take, takeUntil} from 'rxjs/operators';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ClientService } from 'src/app/providers/auth/client.service';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalCartConfirmComponent } from 'src/app/main/procedures/modal-cart-confirm/modal-cart-confirm.component';
import { ModalProcedureResultComponent } from 'src/app/main/procedures/modal-procedure-result/modal-procedure-result.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalHelperFunctions } from 'src/app/helpers/modal-functions';
import { environment } from 'src/environments/environment';
import { TermCondition } from 'src/app/models/product/term-condition.model';
import {PdfService} from '../../../../shared/services/pdf.service';
import {BomModel} from '../../../../models/product/bom.model';

@Component({
    selector: 'app-cart-home',
    templateUrl: './cart-home.component.html',
    styleUrls: ['./cart-home.component.scss']
})
export class CartHomeComponent extends BaseComponent implements OnInit {
    bsModalRef: BsModalRef;
    bsModalResultRef: BsModalRef;

    showPromo = false;
    promoPrice = 0;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cartService: CartService,
        private bomsService: BomsService,
        private familyService: FamiliesService,
        private modalService: BsModalService,
        private pdfService: PdfService
    ) {
        super();
    }

    ngOnInit() {        
        super.ngOnInit();
        // busca los boms si recargó la página y tiene prods en storage
        this.bomsService.bomsChange
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe( () => {
              this.bomsService.findBestBoms(this.cartService.cart.products);
            });
        this.setPromo(this.bomsService.bomBestPromo);

        this.bomsService.emitHasPromo
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (bom: BomModel | null) => {
                    this.setPromo(bom);
                }
            );
    }

    print(): void {
        this.pdfService.printProductsResume(this.cartProducts(), this.termsAndConditions, this.totalPrice);
    }

    get hasProducts(): boolean {
        return this.cartService.hasProducts;
    }

    cartProducts(): ProductModel[] {
        return this.cartService.cart.products;
    }

    get families(): ProductFamilyModel[] {
        return this.familyService.families;
    }

    get totalPrice(): number {
        return this.cartService.cart.price;
    }

    get termsAndConditions(): TermCondition[] | null {
        return this.cartService.cartTermsAndConditions;
    }

    onQuitProduct(product: ProductModel) {
        this.cartService.quitProduct(product);
        this.bomsService.findBestBoms(this.cartService.cart.products);
    }

    onEditProduct(product: ProductModel) {
        this.router.navigate([`../detalle/${product.family.id}/${product.id}`], {
            relativeTo: this.route
        });
    }

    onApply() {
        const initialState = {
            title: 'Confirmar Solicitud de compra',
            type: 'carrito'
        };
        this.bsModalRef = this.modalService.show(ModalCartConfirmComponent, {
            ...environment.modalConfig,
            initialState
        });
        this.bsModalRef.setClass('modal-xl');

        // m subscribo a la respuesta del modal
        this.bsModalRef.content.onCloseModal
            .pipe(take(1))
            .subscribe((response: GeneralResponse) => {
                if (response) {
                    const result = ModalHelperFunctions.showModalResult(
                        response.error
                            ? 'Solicitud de compra no generada'
                            : 'Tu solicitud fue generada',
                        response.message,
                        response.error,
                        this.modalService
                    );
                    // m subscribo a la respuesta del modal result
                    result.content.onCloseModal.pipe(take(1)).subscribe(() => {
                        if (!response.error) {
                            this.cartService.cleanCart();
                            this.bomsService.cleanBestBom();
                        }
                    });
                }
            });
    }

    setPromo(bom: BomModel | null) {
        if (bom) {
            this.showPromo = true;
            this.promoPrice = bom.price;
        } else {
            this.showPromo = false;
        }
    }
}
