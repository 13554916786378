import { Component, OnInit } from '@angular/core';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {ConnectionModel} from '../../../models/client/connection.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {DataService} from '../../../providers/data/data.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ClientService} from '../../../providers/auth/client.service';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {HelperFunctions} from '../../../helpers/functions';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';
import {ClientModel} from '../../../models/client/client.model';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';
import {Base64Service} from '../../../shared/services/base64.service';
import {ImageModel} from '../../../models/image.model';

@Component({
    selector: 'app-modal-change-ownership',
    templateUrl: './modal-change-ownership.component.html',
    styleUrls: ['./modal-change-ownership.component.scss']
})
export class ModalChangeOwnershipComponent extends ProceduresBaseComponent implements OnInit {

    form: FormGroup;
    connections: ConnectionModel[];
    currentOwner: ClientModel;

    constructor(  bsModalRef: BsModalRef,
                  spinner: NgxSpinnerService,
                  proceduresService: ProceduresService,
                  private clientsService: ClientService,
                  private dataService: DataService,
                  private _formBuilder: FormBuilder,
                  private base64Service: Base64Service) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initForm();
    }


    initForm() {
        // todo quien es el dueño actual?
        this.currentOwner = this.clientsService.currentClient;
        this.connections = this.clientsService.currentClient.connections;
        this.form = this._formBuilder.group({
            connection: [this.clientsService.currentConection, [Validators.required]],
            name: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            document: ['', [Validators.required, Validators.minLength(7)]],
            cuit: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
            comment: [''],
            timeFrom: ['0900', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            timeUntil: ['1800', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            docImage: ['', Validators.required],
            profileImage: ['', Validators.required]
        });
    }

    get isValidForm(): boolean {
        return this.form.valid && this.captchaValid;
    }

    validInput(input: string): boolean {
        const control = this.form.get(input);
        if (control.dirty) {
            return !this.form.get(input).valid;
        } else {
            return false;
        }
    }
    makeProcedure(): ProcedureModel {
        const procedure = new ProcedureModel();

        procedure.initForNewProcedure();
        procedure.client = this.clientsService.currentClient;
        procedure.connection = this.form.value.connection;
        procedure.subsidiary = this.clientsService.currentClient.subsidiary;
        procedure.contactPhone = null;
        procedure.contactTime = HelperFunctions.formatContactTime(this.form.value.timeFrom, this.form.value.timeUntil);
        procedure.procedureKind = this.dataService.procedureKind.find(
            pk => pk.tag === ProcedureKind.CHANGE_OF_OWNERSHIP
        );
        procedure.data = [
            new ProcedureDataModel('Nombre nuevo titular', this.form.value.name),
            new ProcedureDataModel('Apellido nuevo titular', this.form.value.lastname),
            new ProcedureDataModel('Email nuevo titular', this.form.value.email),
            new ProcedureDataModel('Telefono nuevo titular', this.form.value.phone.toString()),
            new ProcedureDataModel('Document nuevo titular', this.form.value.document.toString()),
            new ProcedureDataModel('CUIT/CUIL nuevo titular', this.form.value.cuit.toString()),
        ];

        const docImg = new ImageModel();
        docImg.file = this.form.value.docImage;
        docImg.tag = 'DOCUMENT';
        docImg.name = 'Foto dni titular';

        const profileImg = new ImageModel();
        profileImg.file = this.form.value.profileImage;
        profileImg.tag = 'PROFILE';
        profileImg.name = 'Foto nuevo titular';

        procedure.files = [docImg, profileImg];
        procedure.clientDetails = this.form.value.comment;
        return procedure;
    }

    uploadDNI(event) {
        this.base64Service.fileToBase64(event.target.files[0]).then(
            (data) => {
                this.form.get('docImage').setValue( data );
                this.form.updateValueAndValidity();
            }
        );
    }

    uploadPicture(event): void {
        this.base64Service.fileToBase64(event.target.files[0]).then(
            (data) => {
                this.form.get('profileImage').setValue( data );
                this.form.updateValueAndValidity();
            }
        );
    }
}
