import { Serializable } from 'src/app/core/models/serializable.model';
import { ProductModel } from './product.model';

export class DevicePackageModel extends Serializable {

  public id: number;
  public extraData: string;
  public product: ProductModel;

  newModel(data: any): DevicePackageModel {
      const dataParsed = new DevicePackageModel(data);
      if (data.product) {
        dataParsed.product = ProductModel.createOne(data.product, new ProductModel());
      }

      return dataParsed;
  }
}
