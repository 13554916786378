<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container py-4 mb-5" name="form" [formGroup]="form">
    <div class="row">
      <div class="col-lg-12 margin-top-mobile-1">
        <div class="titulo color-primary">Débito automático</div>

          <alert type="info" class="">
              <small class="form-text text-muted">
                  <i class="fa fa-info-circle"></i>&nbsp;Cliente: {{client.code}} - <b>{{client.lastName}}, {{client.firstName}}</b>
              </small>
          </alert>
        <div class="form-group row">
          <label  class="col-sm-3 col-form-label col-form-label-sm color-primary">Conexión
            <span class="required">*</span></label>
          <div class="col-sm-9" >
            <select  class="form-control form-control-sm"  formControlName="connection">
              <option *ngFor="let c of connections" [value]="c"> {{ c.code }} - Domicilio:
                {{ c.fullAddress }}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label  class="col-sm-4 col-form-label col-form-label-sm color-primary">Clase de Trámite
            <span class="required">*</span></label>
          <div class="col-sm-8" >
            <select  class="form-control form-control-sm"  formControlName="selectedProcedure">
              <option selected [value]="''">Seleccionar</option>
              <option *ngFor="let p of procedureTypes" [value]="p.tag">{{ p.text}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm color-primary">Entidad
            <span class="required">*</span></label>
          <div class="col-sm-9" >
            <select  class="form-control form-control-sm"
                     formControlName="entity"
                     [ngClass]="{ 'is-invalid': validInput('entity') }">
              <option selected [value]="''">Seleccionar</option>
              <option *ngFor="let e of entities" [value]="e.name">{{e.name}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm color-primary">Número de tarjeta/CBU
            <span class="required">*</span></label>
          <div class="col-sm-5">
            <input type="tel" class="form-control form-control-sm" placeholder="Número de tarjeta"
                   formControlName="paymentNumber"
                   [ngClass]="{ 'is-invalid': validInput('paymentNumber') }"/>
          </div>

          <label class="col-sm-1 col-form-label col-form-label-sm color-primary">Vto<span class="required">*</span></label>
          <div class="col-sm-3">
            <input type="text" class="form-control form-control-sm"
                   formControlName="paymentMethod" mask="99/9999" [ngClass]="{ 'is-invalid': validInput('paymentMethod') }"/>
          </div>

        </div>

        <div class="form-group row">
          <label class="col-sm-5 col-form-label col-form-label-sm color-primary">
            Nombre y Apellido del Titular(Tarjeta o Cuenta)<span class="required">*</span>
          </label>
          <div class="col-sm-7">
            <input type="text" class="form-control form-control-sm"
                   formControlName="name"
                   [ngClass]="{ 'is-invalid': validInput('name') }"/>
          </div>
        </div>


        <div class="form-group row">
          <label  class="col-sm-3 col-form-label col-form-label-sm color-primary"
          >Documento<span class="required">*</span> Tipo</label>
          <div class="col-sm-3">
            <select class="form-control form-control-sm "
                    formControlName="docType"
                    [ngClass]="{ 'is-invalid': validInput('docType') }">
              <option selected [value]="''">Seleccionar</option>
              <option *ngFor="let doc of documents">{{ doc.name }}</option>
            </select>
          </div>
          <label  class="col-sm-2 col-form-label col-form-label-sm color-primary"
          >Número<span class="required">*</span></label>
          <div class="col-sm-4 input-in-mobile">
            <input
                    type="tel"
                    class="form-control form-control-sm"
                    placeholder="Número..."
                    formControlName="docNumber"
                    [ngClass]="{ 'is-invalid': validInput('docNumber') }"
            />
          </div>
        </div>


        <div class="form-group row">
          <label  class="col-sm-4 col-form-label col-form-label-sm color-primary align-self-center">Horario
            para Contactar</label>
          <div class="col-sm-3 align-self-center">
            <input type="tel" placeholder="hh:mm" class="form-control" mask="Hh:m0" formControlName="timeFrom" />
          </div>
          <div class="col-sm-1 align-self-center"></div>
          <div class="col-sm-4 input-in-mobile align-self-center">
            <input  type="tel" formControlName="timeUntil" placeholder="hh:mm" class="form-control"
                    mask="Hh:m0" />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm color-primary">Observaciones</label>
          <div class="col-sm-9">
    <textarea type="text" rows="4" class="form-control form-control-sm"
              formControlName="comment"></textarea>
          </div>
        </div>

      </div>
    </div>

    <app-modal-recaptcha
            (confirmCaptcha)="toggleCaptcha($event)"
    ></app-modal-recaptcha>
  </div>
</div>

<div class="modal-footer">

  <button
          [disabled]="!isValidForm"
          href="javascript:void(0)"
          class="btn btn-block text-white bg-color-primary d-block"
          (click)="onConfirm()"
  >
    ENVIAR
  </button>

</div>

