<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="modal-body">
    <alert [type]="alertType">
        <h4 class="alert-heading">
          <i class="fa " [ngClass]="{'fa-thumbs-up': !error, 'fa-exclamation-triangle': error}"></i>&nbsp;{{title_message}}</h4>
        <p>{{message}}</p>
        <p class="mb-0">{{foot_message}}</p>
      </alert>
</div>

<div class="modal-footer">
    <button
    href="javascript:void(0)"
    class="btn btn-block text-white bg-color-primary d-block"
    (click)="onClose()"
  >
  CERRAR
  </button>
</div>
