import { Component, OnInit } from '@angular/core';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { ProcedureKind } from 'src/app/models/data/procedure-kind.enum';
import { ProductModel } from 'src/app/models/product/product.model';

@Component({
  selector: 'app-modal-view-details',
  templateUrl: './modal-view-details.component.html',
  styleUrls: ['./modal-view-details.component.scss']
})
export class ModalViewDetailsComponent implements OnInit {

  procedureId: number;
  procedure: ProcedureModel = null;
  constructor(
    private proceduresService: ProceduresService,
    public bsModalRef: BsModalRef,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.spinner.show();
    this.proceduresService.getProcedure(this.procedureId)
    .pipe(take(1))
    .subscribe(
      (response: GeneralResponse) => {
        this.procedure = response.data;
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );

  }

  get isBuyProductProcedure(): boolean {
    return (this.procedure.procedureKind.tag === ProcedureKind.ADD_PRODUCT_TO_PLAN ||
      this.procedure.procedureKind.tag === ProcedureKind.BUY_NEW_PLAN
      );
  }

  get isDataProcedure(): boolean {
    return (this.procedure.procedureKind.tag === ProcedureKind.EDIT_PERSONAL_DATA ||
      this.procedure.procedureKind.tag === ProcedureKind.ELECTRONIC_INVOICE_ADD ||
      this.procedure.procedureKind.tag === ProcedureKind.ELECTRONIC_INVOICE_CHANGE ||
      this.procedure.procedureKind.tag === ProcedureKind.WIFI_CHANGE_CHANNEL ||
      this.procedure.procedureKind.tag === ProcedureKind.WIFI_CHANGE_NAME ||
      this.procedure.procedureKind.tag === ProcedureKind.WIFI_CHANGE_PASSWORD
      );
  }

  onClose() {
    this.bsModalRef.hide();
  }

  getOptionalsText(product: ProductModel): string {
    if (product.optionals.length > 0) {
      return Array.prototype.map.call(product.optionals, o => o.name).join(' | ');
    } else {
      return '';
    }
  }

}
