import { Serializable } from 'src/app/core/models/serializable.model';
import { ProductModel } from './product.model';
import { environment } from '../../../environments/environment';
import { ImageModel } from '../image.model';
import { DataModel } from '../data/data.model';
import { TermCondition } from './term-condition.model';
import { HelperFunctions } from 'src/app/helpers/functions';

export class BomModel extends Serializable {
  public id: number;
  public name: string;
  public description: string;
  public termsConditions: string;
  public price: number;
  public user: string;
  public images: ImageModel[];
  public products: ProductModel[];
  public date: string;
  public corporative: boolean;
  public visibility: boolean;
  public home: boolean;
  public showTvNowCheck: boolean;

  private _productsConcat = '';

  newModel(data: any): BomModel {
    const dataParsed = new BomModel(data);
    dataParsed.price = +dataParsed.price;

    if (data.products) {
      dataParsed.products = [];
      for (const prod of data.products) {
        const product = ProductModel.createOne(prod.product ,new ProductModel());
        product.optionals = ProductModel.createArray(prod.optionals, new ProductModel());
        dataParsed.products.push(product);
      }
      // order by family
      dataParsed.products.sort((a, b) => (a.family.tag > b.family.tag) ? 1 : -1)
    }

    if (data.images) {
      dataParsed.images = ImageModel.createArray(data.images, new ImageModel());
    }
    return dataParsed;
  }

  get iconUrl(): string {
    return environment.iconsUrl + 'icon';
  }

  get productsConcat(): string {
    if (this._productsConcat === '') {
      this._productsConcat = this.products.map(p => p.name).join(' + ');
    }
    return this._productsConcat;
  }

  get principalImageUrl(): string {
    const img = this.images.find(i => i.tag === 'Principal');
    if (img) {
      return img.file;
    } else {
      return '';
    }
  }


  get backgroundImageUrl(): string {
    const img = this.images.find(i => i.tag === 'background');
    if (img) {
      return img.file;
    } else {
      return '';
    }
  }

  /**
   * devuelve terminos y condiciones del bom
   */
  get termsAndConditions(): TermCondition[] | null {
    let terms: TermCondition[] = [];
    if (this.termsConditions != null) {
      terms.push(new TermCondition(this.name, this.termsConditions));
    }
    // for (const plan of this.products) {
    //   if (plan.planTermsAndConditions != null) {
    //     terms = [
    //       ...terms,
    //       ...plan.planTermsAndConditions
    //     ];
    //   }
    // }
    return (terms.length > 0) ? terms : null;
  }
}
