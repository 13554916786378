<div class="carrito" *ngIf="bom">
  <div
    class="nav-carrito nav-carrito-in-tab cursor-pointer bom"
    (click)="goBack()"
  >
    <div class="container titulo">
      <a class="cursor-pointer"><i class="fa fa-arrow-left"></i></a>
      <span class="text-uppercase" style="font-size: 1rem">{{ bom.name }}</span>
      <span class="text-uppercase float-right" style="font-size: 1rem">{{
        bom.price | currency: "es-AR":"$"
      }}</span>
    </div>
  </div>

  <div class="content-carrito seleccion-producto py-4">
    <app-cart-selected-products
      [isBom]="true"
      [products]="bomProducts"
      (editProduct)="onEditProduct($event)"
    >
    </app-cart-selected-products>

    <div class="lista-productos tvnow-container" *ngIf="bom.showTvNowCheck">
      <input
        type="checkbox"
        id="tvnow"
        name="tvnow"
        [(ngModel)]="tvNowSelected"
      />
      <label for="tvnow" class="font-size-1rem">
        <span class="tvnow"
          >Sumá TV HD + Now Express por ${{ priceCombo }} adicionales.
        </span>
      </label>
    </div>

    <div class="lista-productos movistar-container" *ngIf="showMovistar">
      <input
        type="checkbox"
        id="movistar"
        name="movistar"
        [(ngModel)]="movistarPlan"
      />
      <label for="movistar" class="font-size-1rem">
        <span class="movistar"
          >Sumá tu plan
          <img id="movistar-logo" src="assets/img/movistar-cyan-small.png" />
          Movistar</span
        >
      </label>
      <p *ngIf="movistarPlan">
        <small
          >*Nos pondremos en contacto para informarte sobre las promociones
          preferenciales</small
        >
      </p>
    </div>

    <div class="printButton">
      <button (click)="print()" class="btn bg-color-secondary text-white">
        <i class="fa fa-print" aria-hidden="true"></i>
      </button>
    </div>

    <div *ngIf="showBuySection" class="">
      <app-modal-cart-confirm
        [embebed]="true"
        [applyMovistarEmbebed]="movistarPlan"
        [addTvNowEmbebed]="tvNowSelected"
        (onSubmitProcedure)="submitProcedure($event)"
      ></app-modal-cart-confirm>
    </div>

    <app-terms-conditions
      *ngIf="cartBom.termsAndConditions"
      [terms]="cartBom.termsAndConditions"
    >
    </app-terms-conditions>
  </div>
</div>

<app-cart-select-price-bar
  *ngIf="!showBuySection"
  [buttonText]="'SOLICITAR PROMO'"
  [price]="bomPrice"
  [promoSubscribe]="false"
  (apply)="onApplyBom()"
>
</app-cart-select-price-bar>
