import { Injectable } from '@angular/core';
import {BaseService} from '../../core/services/base.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {GeneralResponse} from '../../models/general-response.model';
import {catchError, map} from 'rxjs/internal/operators';
import {ProcedureProductDailyModel} from '../../models/procedure/procedure-product-daily.model';
import {HelperFunctions} from '../../helpers/functions';
import {Cacheable, CacheBuster} from 'ngx-cacheable';
const cacheBusterForList$ = new Subject<void>();
@Injectable({
  providedIn: 'root'
})
export class ProductsPerDayService extends BaseService {

  constructor(public router: Router, public http: HttpClient) {
    super(router);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBusterForList$
  })
  buyProductsPerDay(procedure: ProcedureProductDailyModel): Observable<{} | GeneralResponse> {
    let url = `${this._api}client/me/buy-daily-products`;
    return this.http.post<GeneralResponse>(url, procedure.prepareForStore())
      .pipe(
        map(res => {
          if (res.data) {
            return new GeneralResponse(res.data.message, res.data.error, false,  res.data.data);
          } else {
            return new GeneralResponse(res.message, res.error, false, null);
          }
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }

  @Cacheable({
    cacheBusterObserver: cacheBusterForList$
  })
  getProcedures(): Observable<{} | GeneralResponse> {
    const url = `${this._api}client/me/buy-daily-products`;
    return this.http.get<GeneralResponse>(url)
      .pipe(
        map(res => {
          let procedures = null;
          if (!res.error) {
            procedures = ProcedureProductDailyModel.createArray(res.data, new ProcedureProductDailyModel());
            // ordeno por id descendente
            procedures.sort(HelperFunctions.compareValues('id', 'desc'));
          }
          return new GeneralResponse(res.message, res.error, false, procedures);
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }


  @CacheBuster({
    cacheBusterNotifier: cacheBusterForList$
  })
  deleteServicePerDay(id: number): Observable<{} | GeneralResponse> {
    let url = `${this._api}client/me/buy-daily-products/${id}`;
    return this.http.delete<GeneralResponse>(url)
      .pipe(
        map(res => {
          debugger;
          if (res.data) {
            return new GeneralResponse(res.data.message, res.data.error, false,  null);
          } else {
            return new GeneralResponse(res.message, res.error, false, null);
          }
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }

}
