<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container py-4" name="form" [formGroup]="form">
    <div class="row">
      <div class="col-lg-12 margin-top-mobile-1">
        <div class="titulo color-primary">Solicita el cambio de domicilio de {{ connectionType }} para la conexión
          {{ currentConnection.code }} - Domicilio:
          {{ currentConnection.fullAddress }}</div>


        <div class="form-group row">
          <label
                  for="colFormLabelSm"
                  class="col-sm-3 col-form-label col-form-label-sm color-primary"
          >Calle<span class="required">*</span></label
          >
          <div class="col-sm-9">
            <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Calle..."
                    formControlName="street"
                    [ngClass]="{ 'is-invalid': validInput('street') }"
            />
          </div>
        </div>

        <div class="form-group row">
          <label
                  class="col-sm-3 col-form-label col-form-label-sm color-primary"
          >Altura <span class="required">*</span> | Piso | Depto
          </label>
          <div class="col-sm-3 input-in-mobile">
            <input
                    type="tel"
                    class="form-control form-control-sm"
                    placeholder="Altura..."
                    formControlName="streetNumber"
                    [ngClass]="{ 'is-invalid': validInput('streetNumber') }"
            />
          </div>
          <div class="col-sm-3 input-in-mobile">
            <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Piso..."
                    formControlName="streetFloor"
                    [ngClass]="{ 'is-invalid': validInput('streetFloor') }"
            />
          </div>
          <div class="col-sm-3 input-in-mobile">
            <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Depto..."
                    formControlName="streetDept"
                    [ngClass]="{ 'is-invalid': validInput('streetDept') }"
            />
          </div>
        </div>

        <div class="form-group row">
          <label  class="col-sm-3 col-form-label col-form-label-sm color-primary"
          >Código postal<span class="required">*</span></label>
          <div class="col-sm-9">
            <input
                    type="number"
                    class="form-control form-control-sm"
                    placeholder="Código postal..."
                    formControlName="postalCode"
                    [ngClass]="{ 'is-invalid': validInput('postalCode') }"
            />
          </div>
        </div>

        <div class="form-group row">
          <label
                  class="col-sm-3 col-form-label col-form-label-sm color-primary align-self-center"
          >Horario contacto<span class="required">*</span></label
          >
          <div class="col-sm-4 align-self-center">
            <input
                    type="tel"
                    formControlName="timeFrom"
                    placeholder="hh:mm"
                    class="form-control"
                    mask="Hh:m0"
                    formControlName="timeFrom"
                    [ngClass]="{ 'is-invalid': validInput('timeFrom') }"
            />
          </div>
          <div class="col-sm-1 align-self-center"></div>
          <div class="col-sm-4 input-in-mobile align-self-center">
            <input
                    type="tel"
                    formControlName="timeUntil"
                    placeholder="hh:mm"
                    class="form-control"
                    mask="Hh:m0"
                    formControlName="timeUntil"
                    [ngClass]="{ 'is-invalid': validInput('timeUntil') }"
            />
          </div>
        </div>

      </div>
    </div>

    <app-modal-recaptcha
            (confirmCaptcha)="toggleCaptcha($event)"
    ></app-modal-recaptcha>
  </div>
</div>

<div class="modal-footer">

  <button
          [disabled]="!isValidForm"
          href="javascript:void(0)"
          class="btn btn-block text-white bg-color-primary d-block"
          (click)="onConfirm()"
  >
    ENVIAR
  </button>

</div>

