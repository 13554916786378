import { ProductModel } from '../product/product.model';

export class CartModel {

  public products: ProductModel[];
  public price: number;
  public bomId: number;

  constructor() {
    this.products = [];
    this.price = 0;
  }

  static createFromString(data: any): CartModel {
    const cart = new CartModel();

    if (data.products) {
      cart.products = ProductModel.createArray(data.products, new ProductModel);
    }
    cart.price = (data.price) ? +data.price : 0;

    return cart;
  }


}
