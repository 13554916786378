export class GeneralResponse {

    constructor(public message: string = '',
                public error: boolean = false,
                public logout: boolean = false,
                public data: any = null) {
    }

    public getFirstError(): string {
        let error = '';
        if (!this.data.length) {
            error = this.message;
        } else {
            if ((typeof this.data[0]) === 'string') {
                error = this.data[0];
            } else {
                error = this.data[0][0];
            }
        }
        return error;
    }

    public getErrorsBag(): Array<string> {
        const errorsBag = [];
        if (this.data) {
            for (const err of this.data) {
                let strError = '';
                if (!err[0].length) {
                    strError = err[0];
                } else {
                    strError = err[0][0];
                }
                errorsBag.push(strError);
            }
        } else {
            errorsBag[0] = this.message;
        }
        return errorsBag;
    }
}
