import { Serializable } from '../../core/models/serializable.model';
import { ProductModel } from '../product/product.model';

export class ProcedureProductModel extends Serializable {

  /* parametros q se usan para mostrar de server */
  public product: ProductModel;
  public plan: ProductModel;
  public price: number;
  public extra_data: string;


  newModel(data: any): ProcedureProductModel {
    const dataParsed = new ProcedureProductModel(data);

    if (data.plan) {
      dataParsed.plan = ProductModel.createOne(data.plan, new ProductModel());
    }

    if (data.product) {
      dataParsed.product = ProductModel.createOne(data.product, new ProductModel());
    }
    return dataParsed;
  }
}
