import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ProceduresBaseComponent } from '../procedures-base-component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';
import { ProcedureDataModel } from 'src/app/models/procedure/procedure-data.model';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { ClientService } from 'src/app/providers/auth/client.service';
import { DataService } from 'src/app/providers/data/data.service';
import { ProcedureKind } from 'src/app/models/data/procedure-kind.enum';

@Component({
  selector: 'app-modal-personal-data',
  templateUrl: './modal-personal-data.component.html',
  styleUrls: ['./modal-personal-data.component.scss']
})
export class ModalPersonalDataComponent extends ProceduresBaseComponent implements OnInit {

  form: FormGroup;

  title: string;

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private _formBuilder: FormBuilder,
    private clientsService: ClientService,
    private dataService: DataService
    ) {
      super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
      super.ngOnInit();
      this.initForm();
    }

    initForm() {
      this.form = this._formBuilder.group({
        // name: ['', []],
        email: ['', [Validators.email]],
        phone: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      });
    }

    validInput(input: string): boolean {
      const control = this.form.get(input);
      if (control.dirty) {
        return !this.form.get(input).valid;
      } else {
        return false;
      }
    }

    get isValidForm(): boolean {
      return (this.form.valid && (
          // this.form.value.name !== '' ||
          this.form.value.email !== '' &&
          this.form.value.phone !== ''
          ) && this.captchaValid
        );
    }

    makeProcedure(): ProcedureModel {
      const procedure = new ProcedureModel();

      procedure.initForNewProcedure();

      procedure.client = this.clientsService.currentClient;

      procedure.subsidiary = this.clientsService.currentClient.subsidiary;
      procedure.clientDetails = null;
      procedure.contactTime = null;
      procedure.contactPhone = null;
      procedure.procedureKind = this.dataService.procedureKind
        .find(pk => pk.tag === ProcedureKind.EDIT_PERSONAL_DATA);

       procedure.data = [];

       // if (this.form.value.name !== '') {
       //   procedure.data.push(new ProcedureDataModel('nombre', this.form.value.name));
       // }

       if (this.form.value.email !== '') {
         procedure.data.push(new ProcedureDataModel('email', this.form.value.email));
       }

       if (this.form.value.phone !== '') {
        procedure.data.push(new ProcedureDataModel('telefono', this.form.value.phone));
      }

      return procedure;
    }

}
