import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from 'src/app/core/components/base-component';
import { ProductModel } from 'src/app/models/product/product.model';
import { ProductFamilyModel } from 'src/app/models/product/product-family.model';
import { FamiliesService } from 'src/app/providers/products/families.service';
import { CartBomService } from 'src/app/providers/cart/cart-bom.service';
import { PlansService } from 'src/app/providers/products/plans.service';

@Component({
  selector: 'app-bom-plan-details',
  templateUrl: './bom-plan-details.component.html',
  styleUrls: ['./bom-plan-details.component.scss']
})
export class BomPlanDetailsComponent extends BaseComponent implements OnInit {

  product: ProductModel;
  productOriginal: ProductModel;
  optionalsOriginal: ProductModel[];
  idPlan: number;
  idBom: number;
  totalPrice: number;
  total = 0;
  loading: boolean;

  constructor(
    private _activeRoute: ActivatedRoute,
    private router: Router,
    private cartBomService: CartBomService,
    private plansService: PlansService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.loading = true;
    this._activeRoute.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params: Params) => {
        if (params['planId'] && params['bomId']) {
          this.idPlan = +params['planId'];
          this.idBom = +params['bomId'];
          this.getProduct();
          this.loading = false;
        } else {
          this.goBack();
        }
      });
  }

  getProduct(): void {
    this.product = this.cartBomService.findProductInCart(this.idPlan);
    if (this.product) {
      // busco los originales para los opcionales
      const productInBom = this.cartBomService.findProductInBom(this.product.id);
      if (productInBom) {
        // busco los opcionales originales en los productos
        const prodFromPlan = this.plansService.getPlan(productInBom.family.id, productInBom.id);
        if (prodFromPlan) {
            this.productOriginal = prodFromPlan;
          // armo un array con los opcionales q trae el bom  + los opcionales originales
          this.optionalsOriginal = [
            ...this.product.optionals,
            ...prodFromPlan.optionals.filter(opt_plan => false === this.product.optionals.some(opt_bom => opt_plan.id === opt_bom.id))
          ];
          this.recalculatePrice();
          return;
        }
      }
    }
    // si el producto no esta en el bom salgo ya q entro por error
    this.goBack();
  }

  goBack(): void {
    this.router.navigate(['..'], { relativeTo: this._activeRoute });
  }

  recalculatePrice() {
    this.totalPrice = this.product.price;
    for (const optional of this.product.optionals) {
      this.totalPrice += +optional.price;
    }
  }

  onChangeSelectedOptional($event): void {
    if ($event.checked) {
      this.product.optionals.push($event.optional);
    } else {
      this.product.optionals.splice(
        this.product.optionals.indexOf(
          this.product.optionals.find(os => os.id === $event.optional.id)
          ),
        1
      );
    }
    this.recalculatePrice();
  }

  /**
   * agrega al carro un producto seleccionado
   */
  addToCart() {
    // agrego los opcionales al prod seleccionado
    this.cartBomService.replaceProduct(this.product);
    this.goBack();
  }
}
