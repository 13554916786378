<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="oficina-virtual formulario">
    <div class="container py-4 mb-5">
      <div class="row">
        <div class="col-12">
          <div class="titulo color-primary">WIFI</div>

          <!-- <alert type="info" class="">
              <small class="form-text text-muted">
                <i class="fa fa-info-circle"></i>&nbsp;Solox completa la info que quieras modificar
              </small>
          </alert> -->


          <div class=" carrito mt-3" >
              <div class="lista-productos" >
                <div class="row no-gutters mt-1">
                  <div class="adicionales vo-adicionales">
                    <a
                      class="btn text-left border-0 text-white btn-adicionales bg-color-secondary d-block font-weight-bold"
                      aria-expanded="false"
                      aria-controls="collapseAdicionales"
                    >
                      <i class="fa fa-list"></i> Seleccioná el dispositivo
                    </a>
                    <div id="collapseAdicionales">
                      <ul class="px-0">
                        <li class="border-top-0" *ngFor="let device of devices">
                          <div class="row font-size-1rem">
                            <div class="col-auto col-check text-right">
                              <input
                                type="checkbox"
                                [id]="device.id"
                                (change)="selectDevice($event, device)"
                              />
                              <!--  -->
                            </div>
                            <div class="col font-size-1rem">
                              <label [for]="device.id"
                                >{{ device.product.name }}
                                <span class="precio color-primary"
                                  ><strong>Serie:</strong> {{ device.number }}</span
                                >
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <alert type="danger" *ngIf="!sectedAtLeastOneDevice">
                        <small class="form-text">
                        <i class="fa fa-exclamation-triangle"></i> Debés marcar al menos un dispositivo
                        </small>
                      </alert>
                  </div>
                </div>
              </div>
            </div>

          <!-- nombre y apellido -->
          <div class="form-group row ">
            <label
              for="colFormLabelSm"
              class="col-sm-3 col-form-label col-form-label-sm color-primary "
              >¿Que querés modificar?
              <span class="required"></span></label
            >
            <div class="col-sm-9 align-self-center">
              <select class="form-control form-control-sm " (change)="validInput()" [(ngModel)]="settingToChange">
                <option value="NO_SELECTION">Seleccioná una opción</option>
                <option *ngFor="let change of wifiChanges" [value]="change.tag">{{change.text}}</option>
              </select>
            </div>
          </div>
          <hr/>

          <div *ngIf="checkSettingToChange('WIFI_CHANGE_CHANNEL')">
              <div class="form-group row">
                <label
                  for="colFormLabelSm"
                  class="col-sm-3 col-form-label col-form-label-sm color-primary"
                  >Seleccioná canal</label
                >
                <div class="col-sm-9">
                    <select class="form-control form-control-sm "
                    [(ngModel)]="wifiChannel">
                        <option value="">Seleccioná un canal</option>
                        <option value="1">Canal 1</option>
                        <option value="2">Canal 2</option>
                        <option value="3">Canal 3</option>
                        <option value="4">Canal 4</option>
                        <option value="5">Canal 5</option>
                        <option value="6">Canal 6</option>
                        <option value="7">Canal 7</option>
                        <option value="8">Canal 8</option>
                        <option value="9">Canal 9</option>
                        <option value="10">Canal 10</option>
                        <option value="11">Canal 11</option>
                        <option value="11">Canal 11</option>
                        <option value="12">Canal 12</option>
                      </select>
              </div>
            </div>
          </div>

          <div *ngIf="checkSettingToChange('WIFI_CHANGE_NAME')">
            <div class="form-group row">
              <label
                for="colFormLabelSm"
                class="col-sm-3 col-form-label col-form-label-sm color-primary"
                >Nuevo Nombre</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Nombre..."
                  [(ngModel)]="wifiName"

                />
              </div>
            </div>
          </div>

          <div *ngIf="checkSettingToChange('WIFI_CHANGE_PASSWORD')">
              <div class="form-group row">
                <label
                  for="colFormLabelSm"
                  class="col-sm-3 col-form-label col-form-label-sm color-primary"
                  >Nueva Contraseña</label
                >
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Contraseña..."
                    [(ngModel)]="wifiPass"

                  />
                </div>
              </div>

              <div class="form-group row">
                  <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary"
                    >Confirme Contraseña</label
                  >
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Contraseña..."
                      [(ngModel)]="wifiPassConfirm"

                    />
                  </div>
                </div>

                <div class="row" *ngIf="!passswordMatch">
                    <div class="col-12">
                        <div class="alert alert-danger font-size-075rem" role="alert">
                          <i class="fa fa-exclamation-triangle"></i> Las contraseñas deben ser iguales.
                        </div>
                      </div>
                </div>
            </div>


        </div>
      </div>

        <app-modal-recaptcha
                (confirmCaptcha)="toggleCaptcha($event)"
        ></app-modal-recaptcha>
    </div>
</div>

<div class="modal-footer">

    <button
      [disabled]="!isValidForm"
      href="javascript:void(0)"
      class="btn btn-block text-white bg-color-primary d-block"
      (click)="onConfirm()"
    >
      CONFIRMAR
    </button>

</div>
