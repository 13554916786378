<section id="content" class="now">
  <div class="inner-content">
    <div class="header py-2 py-lg-4">
      <div class="container">NOW Express</div>
    </div>
    <div class="container pb-5">
      <div class="row">
        <div class="col-lg-4">
          <div class="px-1 pt-5 pb-4 text-center">
            <img src="assets/img/logo-now.png" class="img-fluid" />
          </div>

          <div class="titulo mb-3">La tele que sale de la tele</div>
          <div class="subtitulo mb-3">Mirá tus series, películas y eventos deportivos preferidos dónde y cuándo quieras.</div>
          <div class="texto">
            <strong>NOW</strong> cuenta con diferentes funcionalidades para que ver la tele sea una nueva experiencia. <br>
            Ahora vas a poder: <br><br>
            - Disfrutar la grilla más completa de canales de televisión en vivo. <br>
            - Ver películas y series OnDemand de HBO, FOX Premium, TNT, Space y Cartoon Network, sin salir de la app.<br>
            - Acceder a la guía de programación.<br>
            - Iniciar sesión hasta en 5 dispositivos al mismo tiempo.<br>
            - Buscar tus contenidos y canales preferidos.<br>
            - Retroceder la guía hasta 24hs y ver programas ya emitidos.<br>
            - Pausar para continuar viendo en otro momento.<br>
            - Ver desde el inicio un programa que ya comenzó.<br>
            - Hacer streaming desde los dispositivos móviles hacia Chromecast.
          </div>
        </div>
        <div class="col-lg-8">
          <img src="assets/img/now-dispositivos.png" class="img-fluid" />
          <div class="canales px-4 py-3 py-lg-0">
            Disfrutá TV en vivo y el mejor contenido OnDemand
          </div>
        </div>
      </div>
    </div>

    <div class="descargar py-2 py-md-5">
      <div class="container text-center">
        Descargala ahora
        <a href="https://play.google.com/store/apps/details?id=com.express.android.minerva.devices" target="_blank">
          <img src="assets/img/ic_store_android_negro.png" class="ml-4"/>
        </a>
        <a href="https://apps.apple.com/ar/app/now-express-for-iphone/id1410189558" target="_blank">
          <img src="assets/img/ic_store_ios_negro.png" />
        </a>
      </div>
    </div>
    <div class="pc">
      <a href="http://now.express.com.ar" class="py-3" target="_blank">CLICK AQUÍ PARA VER DESDE <u>LA PC</u></a>
    </div>

    <div class="faqs">
      <div class="container py-4">
        <div class="titulo pb-4-2 mb-2 text-left">Preguntas Frecuentes</div>
        <div class="row">
          <div class="col-md-4">
            <div class="pregunta-container mb-3">
              <div class="font-weight-bold ">¿Qué es NOW Express?</div>
              <div class="respuesta">
                NOW Express es la app oficial de Express que te permite ver TV en vivo y contenido On Demand desde cualquier dispositivo móvil, cuando y donde quieras.
              </div>
            </div>
            <div class="pregunta-container mb-3">
              <div class="font-weight-bold">¿Cómo me suscribo a NOW Express? </div>
              <div class="respuesta">
                - Si sos cliente y tenés TV HD, podés acceder a la app gratis.<br>
                - Si contás con TV Básico o TV Digital, podés suscribirte abonando un costo mensual.
              </div>
            </div>
            <div class="pregunta-container mb-3">
              <div class="font-weight-bold">¿Cómo descargo NOW Express?</div>
              <div class="respuesta">
                Tenés que acceder al store de tu dispositivo, buscar NOW Express y descargar la app.
              </div>
            </div>
            <div class="pregunta-container mb-3">
              <div class="font-weight-bold">¿Cómo activo NOW Express? </div>
              <div class="respuesta">
                Debés ingresar en Oficina Virtual y dirigirte a la sección de NOW. Allí, podrás crear tu usuario y contraseña.
              </div>
            </div>

          </div>
          <div class="col-md-4">
            <div class="pregunta-container mb-3">
              <div class="font-weight-bold">¿En cuántos dispositivos puedo usar NOW Express? </div>
              <div class="respuesta">
                Podés vincular la app en hasta 5 dispositivos en simultáneo. Para agregar uno nuevo, deberás desvincular uno en Oficina Virtual.
              </div>
            </div>
            <div class="pregunta-container">
              <div class="font-weight-bold">¿En qué dispositivos puedo ver NOW Express?</div>
              <div class="respuesta">
                Para acceder a NOW Express deberás contar con alguno de estos dispositivos: <br>
                - PC / MAC utilizando Google Chrome, Firefox, Edge o Safari.<br>
                - Tablets / Smartphone Android. Versión 7 o superiores, debe ser oficial no modificada.<br>
                - Tablets / Smartphone iOS. Versión 10 o superiores.<br>
                - Google Chromecast
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="pregunta-container mb-3">
              <div class="font-weight-bold">¿Qué hago si la app no me reconoce el usuario? </div>
              <div class="respuesta">
                Podés modificar tus credenciales y volver a probar. Recordá que el usuario debe contener entre 6 y 20 caracteres, números o letras y en minúsculas y la contraseña, debe tener entre 6 y 20 caracteres numéricos.<br>
                <i>Importante: el usuario no es el mail de Oficina Virtual ni el mail personal.</i><br>
                Si aun así no funciona, envíanos una consulta por Oficina Virtual o contactate al 08105553977.
              </div>
            </div>
            <div class="pregunta-container">
              <div class="font-weight-bold">Recomendaciones</div>
              <div class="respuesta">
                Ante cualquier inconveniente con el funcionamiento, desinstalá y volvé a instalar. Luego, chequeá que en tu tienda estén actualizadas todas las apps y que el sistema operativo sea el último disponible.<br>
                Si aun así no funciona, envíanos una consulta por Oficina Virtual o contactate al 08105553977.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <img src="assets/img/logos-faqs.png" class="img-fluid"/>
      </div>

      <div class="container py-4 pb-5">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/8N72kishUOs" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</section>