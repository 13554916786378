import { Serializable } from '../../core/models/serializable.model';
import { ProductModel } from '../product/product.model';
import { ConnectionModel } from '../client/connection.model';
import {ClientModel} from '../client/client.model';

export class ProcedureProductDailyModel extends Serializable {

    public id: number;
    public connection: ConnectionModel;
    public client: ClientModel;
    public product: ProductModel;
    public dateFrom: string;
    public dateUntil: string;
    public days: number;
    public date: string;
    public resultDetails: string;
    // public aleatorResult: number;
    // public aleator_result_details: number;


    newModel(data: any): ProcedureProductDailyModel {
        const dataParsed = new ProcedureProductDailyModel(data);
        return dataParsed;
    }

    prepareForStore(): any {
        const response: any = {};

        response.client_id = this.client.id;
        response.client_connection_id = this.connection.id;
        response.product_id = this.product.id;
        response.date_from = this.dateFrom;
        response.date_until = this.dateUntil;

        return response;
    }

}
