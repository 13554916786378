import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-contact-date',
  templateUrl: './modal-contact-date.component.html',
  styleUrls: ['./modal-contact-date.component.scss']
})
export class ModalContactDateComponent implements OnInit {

  @Input() timeFrom: string;
  @Output() timeFromChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() timeUntil: string;
  @Output() timeUntilChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() comment: string;
  @Output() commentChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() phone: string;
  @Output() phoneChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }


  ngOnInit() {}

  onChangePhone() {
    this.phoneChange.emit(this.phone);
  }

  onChangeTimeFrom() {
    this.timeFromChange.emit(this.timeFrom);
  }

  onChangeTimeUntil() {
    this.timeUntilChange.emit(this.timeUntil);
  }

  onChangeComment() {
    this.commentChange.emit(this.comment);
  }



}
