import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';
import {AuthService} from '../../../providers/auth/auth.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor(private router: Router,
                private subsidiaryService: SubsidiaryService,
                private authService: AuthService) { }

    ngOnInit() {
    }

    onDownServices() {
        this.router.navigate(['/oficina-virtual', 'panel', 'baja', 'servicio']);
    }

    faq() {
        this.router.navigate(['/preguntas-frecuentes']);
    }

    recomendador() {
        this.router.navigate(['/recomendador/home']);
    }

    lugaresPago() {
        this.router.navigate(['/lugares-de-pago']);
    }

    consejosUtiles() {
        this.router.navigate(['/consejos-utiles']);
    }

    virtualOffice() {
        this.router.navigate(['/oficina-virtual/panel/principal']);
    }

    company() {
        this.router.navigate(['/now-express']);
    }

    armaTuCombo() {
        this.router.navigate(['carro-compras/productos/inicio']);
    }

    goToCart() {
        this.router.navigate(['/carro-compras/productos']);
    }

    goToBoms() {
        this.router.navigate(['/carro-compras/promos']);
    }

    centrosAtencion() {
        this.router.navigate(['/centros-de-atencion']);
    }

    finder() {
        this.router.navigate(['/buscador-de-programacion']);
    }

    get isLogged(): boolean {
        return this.authService.loggedIn();
    }

    phone_consumes() {
        this.router.navigate(['/oficina-virtual/consumos-telefonia']);
    }

    internet_consumes() {
        this.router.navigate(['/oficina-virtual/consumo-internet']);
    }

    get tel(): string {
        if (this.subsidiaryService.corporative) {
            return '0810 555 2677';
        } else {
            return '0810 555 3977';
       }
    }

    get isCorporative(): boolean {
        return (this.subsidiaryService.corporative === 1);
    }

}
