import { Component, OnInit } from '@angular/core';
import { ProceduresBaseComponent } from '../procedures-base-component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProceduresService } from 'src/app/providers/procedures/procedures.service';
import { ProcedureModel } from 'src/app/models/procedure/procedure.model';
import { ClientService } from 'src/app/providers/auth/client.service';
import { DataService } from 'src/app/providers/data/data.service';
import { ProcedureKind } from 'src/app/models/data/procedure-kind.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/providers/auth/auth.service';
import { ProcedureDataModel } from 'src/app/models/procedure/procedure-data.model';

@Component({
  selector: 'app-modal-electronic-invoice',
  templateUrl: './modal-electronic-invoice.component.html',
  styleUrls: ['./modal-electronic-invoice.component.scss']
})
export class ModalElectronicInvoiceComponent extends ProceduresBaseComponent
  implements OnInit {

  form: FormGroup;

  title: string;
  message: string;
  titleMessage: string;
  textFormMessage: string;
  buttonText: string;

  constructor(
    bsModalRef: BsModalRef,
    spinner: NgxSpinnerService,
    proceduresService: ProceduresService,
    private clientService: ClientService,
    private _formBuilder: FormBuilder,
    private dataService: DataService,
    private authService: AuthService,
  ) {
    super(bsModalRef, spinner, proceduresService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.hasService) {
      this.titleMessage = 'Ya cuentas con este servicio!.';
      this.message =  'Puedes modificar la casilla de correo donde recibes la factura.';
      this.buttonText = 'CONFIRMAR CAMBIO DE CORREO';
      this.textFormMessage = 'Nuevo Correo para recibir la factura';
    } else {
      this.titleMessage =
        'Gracias por adherirte a nuestro servicio de factura electrónica!';
      this.buttonText = 'CONFIRMAR';
      this.textFormMessage = 'Email';
    }
    this.initForm();
  }

  get hasService(): boolean {
    return (this.clientService.currentClient.invoiceElectronic === 1);
  }

  initForm() {
    this.form = this._formBuilder.group({
      email: [this.authService.getItemFromStorage('email'), [Validators.required, Validators.email]]
    });
  }

  validInput(input: string): boolean {
    const control = this.form.get(input);
    if (control.dirty) {
      return !this.form.get(input).valid;
    } else {
      return false;
    }
  }

  get isValidForm(): boolean {
    return this.form.valid && this.captchaValid;
  }

  makeProcedure(): ProcedureModel {
    const procedure = new ProcedureModel();

    procedure.initForNewProcedure();

    procedure.client = this.clientService.currentClient;

    procedure.subsidiary = this.clientService.currentClient.subsidiary;
    procedure.clientDetails = null;
    procedure.contactTime = null;
    procedure.contactPhone = null;
    if (this.hasService) {
      procedure.procedureKind = this.dataService.procedureKind.find(
        pk => pk.tag === ProcedureKind.ELECTRONIC_INVOICE_CHANGE
      );
    } else {
      procedure.procedureKind = this.dataService.procedureKind.find(
        pk => pk.tag === ProcedureKind.ELECTRONIC_INVOICE_ADD
      );
    }
    procedure.data = [
      new ProcedureDataModel('email', this.form.value.email)
    ];

    return procedure;
  }
}
