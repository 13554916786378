import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../providers/auth/auth.service';
import {DataService} from '../../../providers/data/data.service';
import {SubsidiaryService} from '../../../providers/data/subsidiary.service';
import {CartBomService} from '../../../providers/cart/cart-bom.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CartService} from '../../../providers/cart/cart.service';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientService} from '../../../providers/auth/client.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';

@Component({
    selector: 'app-modal-contact',
    templateUrl: './modal-contact.component.html',
    styleUrls: ['./modal-contact.component.scss']
})
export class ModalContactComponent extends ProceduresBaseComponent implements OnInit {


    form: FormGroup;

    title: string;

    constructor(
        bsModalRef: BsModalRef,
        spinner: NgxSpinnerService,
        proceduresService: ProceduresService,
        private dataService: DataService,
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private clientsService: ClientService,
        private cartService: CartService,
        private cartBomService: CartBomService,
        private subsidiaryService: SubsidiaryService) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initForm();
    }

    initForm() {
        // TODO set on open modal -> this.title = 'Formulario de contacto';
        this.form = this._formBuilder.group({
            companyName: ['', Validators.required],
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            street: ['', [Validators.required]],
            streetNumber: ['', [Validators.required]],
            streetFloor: [''],
            streetDept: [''],
            comment: ['']
        });
    }


    validInput(input: string): boolean {
        const control = this.form.get(input);
        if (control.dirty) {
            return !this.form.get(input).valid;
        } else {
            return false;
        }
    }

    get isValidForm(): boolean {
        return this.form.valid && this.captchaValid;
    }

    makeProcedure(): ProcedureModel {
        const procedure = new ProcedureModel();

        procedure.initForNewProcedure();
        procedure.subsidiary = this.subsidiaryService.currentSubsidiary;
        procedure.client = null;
        procedure.clientDetails = null;
        procedure.contactTime = null;
        procedure.contactPhone = null;
        if (this.isCorporative) {
            procedure.procedureKind = this.dataService.procedureKind
                .find(pk => pk.tag === ProcedureKind.BUSINESS_REQUEST);
        } else {
            procedure.procedureKind = this.dataService.procedureKind
                .find(pk => pk.tag === ProcedureKind.CONTACT);
        }

        procedure.data = [];

        procedure.data.push(new ProcedureDataModel('nombre', this.form.value.name));
        procedure.data.push(new ProcedureDataModel('email', this.form.value.email));
        procedure.data.push(new ProcedureDataModel('telefono', this.form.value.phone));
        procedure.data.push(new ProcedureDataModel('razón social', this.form.value.companyName));
        procedure.data.push(new ProcedureDataModel('calle', this.form.value.street));
        procedure.data.push(new ProcedureDataModel('número', this.form.value.streetNumber));
        if (this.form.value.streetFloor !== '') {
            procedure.data.push(new ProcedureDataModel('piso', this.form.value.streetFloor));
        }
        if (this.form.value.streetDept !== '') {
            procedure.data.push(new ProcedureDataModel('depto', this.form.value.streetDept));
        }
        if (this.form.value.comment !== '') {
            procedure.data.push(new ProcedureDataModel('comentario', this.form.value.comment));
        }

        return procedure;
    }

    get isCorporative(): boolean {
        return (this.subsidiaryService.corporative === 1);
    }
}
