<section id="content" class="recomendador py-5">
  <div class="inner-content text-center" *ngIf="loading">

    <i class="fa fa-2x fa-spinner fa-spin"></i>

    <div class="titulo my-3">Aguarda un instante mientras validamos los datos</div>

  </div>

  <div class="inner-content text-center" *ngIf="!loading">

    <div *ngIf="error">
      <alert type="danger">
        <h4 class="alert-heading">
          <i class="fa fa-warning"></i>&nbsp;
          <span>{{errorMessage}}</span>
        </h4>
      </alert>
    </div>

    <div *ngIf="!error">
      <alert type="info">
        <h4 class="alert-heading">
          <i class="fa fa-question-circle"></i>&nbsp;
          <span>Confirma el pago, por un total de '{{ammount | currency:'es-AR':'$'}}'</span>
        </h4>
      </alert>

      <form #wondersoftForm name="wondersoft" id="wondersoft" method="post" action="{{wsUri}}">

        <input *ngFor="let data of keyValues" type="hidden" name="{{data.key}}" value="{{data.value}}" />

        <button class="btn btn-gotopay" type="submit" name="enviar" (click)="onSubmitRccpagosForm()">IR A
          PLATAFORMA DE PAGOS</button>

      </form>
    </div>
  </div>

</section>