export enum ProcedureKind {
  NEW_CLIENT_CONNECTION = 'NEW_CLIENT_CONNECTION',
  ADD_PRODUCT_TO_PLAN = 'ADD_PRODUCT_TO_PLAN',
  BUY_NEW_PLAN = 'BUY_NEW_PLAN',
  EDIT_PERSONAL_DATA = 'EDIT_PERSONAL_DATA',
  ELECTRONIC_INVOICE_ADD = 'ELECTRONIC_INVOICE_ADD',
  ELECTRONIC_INVOICE_CHANGE = 'ELECTRONIC_INVOICE_CHANGE',
  WIFI_CHANGE = 'WIFI_CHANGE',
  WIFI_CHANGE_CHANNEL = 'WIFI_CHANGE_CHANNEL',
  WIFI_CHANGE_NAME = 'WIFI_CHANGE_NAME',
  WIFI_CHANGE_PASSWORD = 'WIFI_CHANGE_PASSWORD',
  DOWN_SERVICE = 'DOWN_SERVICE',
  DOWN_PRODUCT = 'DOWN_PRODUCT',
  AUTOMATIC_DEBIT = 'AUTOMATIC_DEBIT',
  AUTOMATIC_DEBIT_UP = 'AUTOMATIC_DEBIT_UP',
  AUTOMATIC_DEBIT_DOWN = 'AUTOMATIC_DEBIT_DOWN',
  AUTOMATIC_DEBIT_UPDATE = 'AUTOMATIC_DEBIT_UPDATE',
  TECHNICAL_CLAIM = 'TECHNICAL_CLAIM',
  CHANGE_BILL_ADDRESS = 'CHANGE_BILL_ADDRESS',
  CHANGE_SERVICE_ADDRESS = 'CHANGE_SERVICE_ADDRESS',
  CONTACT = 'CONTACT',
  ADDITIONAL_CONNECTIONS = 'ADDITIONAL_CONNECTIONS',
  CHANGE_OF_OWNERSHIP = 'CHANGE_OF_OWNERSHIP',
  CHANGE_CONNECTION_PATH = 'CHANGE_CONNECTION_PATH',
  BUSINESS_REQUEST= 'BUSINESS_REQUEST',
  GENERIC_REQUEST = 'GENERIC_REQUEST',
  MOBILE_PHONE_REQUEST= 'MOBILE_PHONE_REQUEST',
  HOME_GENERIC_REQUEST= 'HOME_GENERIC_REQUEST',
  MOVISTAR_DEVICE_REQUEST= 'MOVISTAR_DEVICE_REQUEST',
  NUEVO_CLIENTE_OTT = 'NUEVO_CLIENTE_OTT',
  ALTA_PAQUETE_OTT = 'ALTA_PAQUETE_OTT'
}
