import { ProductModel } from '../product/product.model';
import { BomModel } from '../product/bom.model';
import {TermCondition} from '../product/term-condition.model';

export class CartBomModel {

    public products: ProductModel[];
    public price: number;
    public bom: BomModel | null;

    constructor() {
        this.products = [];
        this.price = 0;
    }

    static createFromString(data: any): CartBomModel {
        const cart = new CartBomModel();

        if (data.products) {
            cart.products = ProductModel.createArray(data.products, new ProductModel);
        }

        cart.price = (data.price) ? +data.price : 0;

        return cart;
    }
    /**
     * devuelve terminos y condiciones del bom y de los productos adicionales seleccionados
     */
    get termsAndConditions(): TermCondition[] | null {
        let terms: TermCondition[] = [];
        if (this.bom.termsConditions != null) {
            terms.push(new TermCondition(this.bom.name, this.bom.termsConditions));
        }
        for (const plan of this.products) {
            if (plan.optionals.length > 0) {
                for (let opt of plan.optionals) {
                    if (opt.productTermAndCondition != null && opt.price !== 0) {
                        terms = [
                            ...terms,
                            opt.productTermAndCondition
                        ];
                    }
                }
            }
        }
        return (terms.length > 0) ? terms : null;
    }


}
