import { SubsidiaryService } from "./../../providers/data/subsidiary.service";
import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "../../../environments/environment.prod";

@Component({
  selector: "app-initial-banner",
  templateUrl: "./initial-banner.component.html",
  styleUrls: ["./initial-banner.component.scss"],
})
export class InitialBannerComponent implements OnInit {
  src: string;
  constructor(
    private bsModalRef: BsModalRef,
    private subService: SubsidiaryService
  ) {}

  ngOnInit() {
    if (this.subService.currentSubsidiary.tag == "cbh") {
      this.src = "./assets/img/popupRos2.png";
    } else {
      this.src = "./assets/img/POPUP.png";
    }
  }

  onClose(): void {
    this.bsModalRef.hide();
  }

  actionClick(): void {
    if (this.subService.currentSubsidiary.tag == "cbh") {
      window.open(environment.externalUris.promos, "_blank");
    } else {
      window.open(environment.externalUris.principalPanel, "_blank");
    }
  }
}
