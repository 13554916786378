import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { ClientModel } from 'src/app/models/client/client.model';
import { Subject, Observable, of } from 'rxjs';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { ConnectionModel } from 'src/app/models/client/connection.model';
import { map, catchError, takeUntil, take } from 'rxjs/operators';
import { AssociateClientModel } from '../../models/auth/associate-client.model';
import { ConsumedInternetModel } from '../../models/client/consumed-internet.model';


@Injectable({
    providedIn: 'root'
})
export class ClientService extends BaseService {

    private _clients: ClientModel[] = null;
    private _currentClient: ClientModel = null;
    private _currentConection: ConnectionModel = null;

    public currentClientSubject = new Subject<ClientModel | null>();

    // private _currentConnection: ConnectionModel = null;
    public currentConectionSubject = new Subject<ConnectionModel | null>();

    constructor(public router: Router,
        public http: HttpClient,
        private cfr: ComponentFactoryResolver
    ) {
        super(router);

        this.initClients();
    }

    // private methods

    /**
     * set the first client as current
     */
    private initClient(): void {
        if (this.clients.length > 0) {
            this.currentClient = this.clients[0];
        } else {
            this.currentClient = null;
        }
    }

    private setFirstConnection(): void {
        if (this.currentClient.connections.length > 0) {
            this._currentConection = this.currentClient.connections[0];
            this.currentConectionSubject.next(this._currentConection);
        }
    }

    // public methods

    /**
     * init clients whit clients of logged user. For Refresh problems
     */
    initClients(): void {
        const clientsString = this.getItemFromStorage('clients');
        if (clientsString) {
            this._clients = ClientModel.createArray(JSON.parse(clientsString), new ClientModel());
            this.initClient();
        } else {
            this._clients = [];
        }
    }

    /**
     * Reset clients on service
     */
    resetClients(): void {
        this._clients = null;
        this.currentClient = null;
    }

    // getters and setters

    /**
     * returns clients from logged user
     */
    get clients(): ClientModel[] {
        if (this._clients == null) {
            this.initClients();
        }
        return this._clients;
    }

    get currentClient(): ClientModel | null {
        return this._currentClient;
    }

    set currentClient(client: ClientModel) {
        this._currentClient = client;
        this.currentClientSubject.next(client);
    }

    get currentConection(): ConnectionModel {
        return this._currentConection;
    }

    set currentConnection(connection: ConnectionModel) {
        this._currentConection = connection;
        this.currentConectionSubject.next(connection);
    }


    // http
    /**
     * get connections for current client and put in the object
     */
    getClientConnections(): Observable<{} | GeneralResponse> {
        // si ya lo tengo lo devuelvo nomas
        if (this.currentClient.connections) {
            this.setFirstConnection();
            return of(new GeneralResponse('', false, false, null));
        }
        const url = `${this._api}client/me/${this.currentClient.id}/connections` +
            `?include=products.family,products.optionals,devices.product.family,devices.packages.product,products.dailies`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {
                    if (!res.error) {
                        this.currentClient.connections = ConnectionModel.createArray(res.data, new ConnectionModel());
                        this.setFirstConnection();
                        return new GeneralResponse('', false, false, null);
                    } else {
                        return new GeneralResponse(res.message, true, false, null);
                    }
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    associateClient(client: AssociateClientModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}associate-client`;
        // this.resetClients();
        return this.http.post<GeneralResponse>(url, client.prepareForStore())
            .pipe(
                map((res: any) => {
                    // localStorage.setItem('user', res.data.user);
                    // localStorage.setItem('email', res.data.email);
                    // localStorage.setItem('token', res.data.token);
                    // localStorage.setItem('tokenType', res.data.tokenType);
                    localStorage.setItem('clients', JSON.stringify(res.data.clients));

                    this.initClients();

                    return new GeneralResponse('', false, false, null);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }


    /**
     * get consumed internet for client/connection
     */
    getClientConsumes(): Observable<{} | GeneralResponse> {
        const url = `${this._api}client/me/${this.currentClient.id}/consumes/${this.currentConection.id}`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {
                    if (!res.data.error) {
                        return new GeneralResponse('', false, false, ConsumedInternetModel.createOne(res.data, new ConsumedInternetModel()));
                    } else {
                        return new GeneralResponse('Se produjo un error en el servidor', true, false, null);
                    }
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    // get client info from server
    getCurrentClientData(): Observable<{} | GeneralResponse> {
        const url = `${this._api}client/me/${this.currentClient.id}/show`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {
                    if (!res.data.error) {
                        const client = ClientModel.createOne(res.data, new ClientModel());
                        this.currentClient.paymentLast = client.paymentLast;
                        this.currentClient.paymentTotal = client.paymentTotal;
                        this.currentClient.paymentMin = client.paymentMin;
                        this.currentClient.accountBalance = client.accountBalance;
                        this.updateStorage();
                        return new GeneralResponse('', false, false, client);
                    } else {
                        return new GeneralResponse('Se produjo un error, intente mas tarde!', true, false, null);
                    }
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );
    }

    /**
     * actualiza el current client en el storage
     */
    updateStorage(): void {
        const clients = JSON.parse(localStorage.getItem('clients'));
        const index = clients.indexOf(clients.find(x => x.id === this.currentClient.id));
        clients[index] = this.currentClient;
        localStorage.setItem('clients', JSON.stringify(clients));
    }



    async loadComponent(vcr: ViewContainerRef, isOTT: boolean) {
        const { PrincipalComponent } = await import('../../../app/main/virtual-office/panel/principal/principal.component');

        const { PrincipalOttComponent } = await import('../../main/virtual-office/panel/principal-ott/principal-ott.component');

        vcr.clear();
        let component: any = isOTT ? PrincipalOttComponent : PrincipalComponent;

        return vcr.createComponent(
            this.cfr.resolveComponentFactory(component)
        )
    }
}

