import { Component, OnInit } from '@angular/core';
import { SubsidiaryService } from './providers/data/subsidiary.service';
import { BaseComponent } from './core/components/base-component';
import { takeUntil, take } from 'rxjs/operators';
import { SubsidiaryModel } from './models/data/subsidiary.model';
import { PlansService } from './providers/products/plans.service';
import { Router } from '@angular/router';
import { BomsService } from './providers/products/boms.service';
import { DataService } from './providers/data/data.service';
import { LayoutService } from './providers/layout.service';
import { CartService } from './providers/cart/cart.service';
import { CartBomService } from './providers/cart/cart-bom.service';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { GraphsService } from './providers/graphs.service';
import { InitialBannerComponent } from './procedures/initial-banner/initial-banner.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {

    showFooter = true;
    constructor(private subsidiaryService: SubsidiaryService,
                private plansService: PlansService,
                private dataService: DataService,
                private router: Router,
                private bomsService: BomsService,
                private cartService: CartService,
                private cartBomService: CartBomService,
                private layoutService: LayoutService,
                private graphsService: GraphsService,
                private spinner: NgxSpinnerService) {
        super();

        this.showFooter = this.layoutService.showFooter;
        this.layoutService.changeShowFooter
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(
            (show: boolean) => {
              setTimeout(() => {
                this.showFooter = show;
               });
            }
          );
    }

    subsidiary: SubsidiaryModel;
    loading = false;

    ngOnInit(): void {
        super.ngOnInit();

        // traigo los datos comunes
        this.dataService.getData()
          .pipe(takeUntil(this.componentDestroyed))
          .subscribe(() => {});

        // seteo la sucursal como actual
        this.subsidiary = this.subsidiaryService.currentSubsidiary;
        this.refreshFork();

        // me subscribo al cambio de sucursal ya q se refresca todo al hacerlo
        this.subsidiaryService.changeSubsidiary
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (subsidiary: SubsidiaryModel) => {
                  if (this.subsidiary.id !== subsidiary.id) {
                    this.subsidiary = subsidiary;
                    this.restoreSite();
                    this.goToHome();
                  }
                },
                (err) => { console.log(err); },
                () => {  }
            );

        // me subscribo al cambio de corporativo ya q se refresca todo al hacerlo
        this.subsidiaryService.changeCorporative
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(
            (coorporative: number) => {
              this.restoreSite();
              this.goToHome();
            },
            (err) => { console.log(err); },
            () => { }
        );
    }

    restoreSite() {
      this.refreshFork();
      this.cleanCart();
    }

    goToHome() {
        this.router.navigate(['/']);
    }

    /** limpia el carro de productos y dde combo */
    cleanCart() {
      this.cartService.cleanCart();
      this.cartBomService.cleanBom();
    }


    refreshFork() {
      const subsidiariesObs = this.bomsService.getBoms(this.subsidiary.id, this.subsidiaryService.corporative);
      const plansObs = this.plansService.getPlans(this.subsidiary.id, this.subsidiaryService.corporative);
      const graphsObs = this.graphsService.getGraphs(this.subsidiary.id, this.subsidiaryService.corporative);

      this.spinner.show();
      forkJoin(
        subsidiariesObs, plansObs, graphsObs
      ).pipe(take(1))
      .subscribe(
        results => {
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  

}
