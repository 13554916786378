import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base-component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-direct-payment-error',
  templateUrl: './direct-payment-error.component.html',
  styleUrls: ['./direct-payment-error.component.scss']
})
export class DirectPaymentErrorComponent extends BaseComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute) {
    super();
  }

  error_message = '';

  ngOnInit() {
    super.ngOnInit();

    const error_code = this.route.snapshot.queryParamMap.get('conf');

    switch (error_code) {
      case '3':
        this.error_message = 'Excede el límite de la tarjeta';
        break;
      case '4':
        this.error_message = 'Tarjeta vencida';
        break;
      case '5':
        this.error_message = 'Código de seguridad inválido';
        break;
      case '6':
        this.error_message = 'Tarjeta inválida';
        break;
      case '7':
        this.error_message = 'Transacción rechazada por el procesador';
        break;
      case '8':
        this.error_message = 'Error en 3D Secure';
        break;
      case '9':
        this.error_message = 'Error detectado en el cliente';
        break;
      case '10':
        this.error_message = 'Error de procesamiento detectado en PSP';
        break;
      case '11':
        this.error_message = 'Error en el procesador';
        break;
      case '12':
        this.error_message = 'Error en el MOP';
        break;
      case '13':
        this.error_message = 'Error en la definición WSDL';
        break;
      case '14':
        this.error_message = 'Error ejecutando proceso Batch';
        break;
      case '15':
        this.error_message = 'Transacción Split reversada por API';
        break;
      case '16':
        this.error_message = 'Cupón de pago emitido – Pendiente de pago';
        break;
      case '17':
        this.error_message = 'Aprobada y Reversada';
        break;
      default:
        this.error_message = 'No se recibio un detalle del problema';
        break;

    }
  }
}
