import { ProductModel } from './product.model';


export class ProductFamilyModel {
    public id: number;
    public name: string;
    public tag: string;
    public description: string;
    public colorCssClass: string;


    public plans: ProductModel[];

    constructor(id: number, name: string, tag: string, description: string, colorCssClass: string) {
      this.id = id;
      this.name = name;
      this.tag = tag;
      this.description = description;
      this.colorCssClass = colorCssClass;
      this.plans = [];
    }

    getIcon(): string {
        switch (this.tag) {
            case 'internet':
                return 'wifi';
            case 'cable':
                return 'tv';
            case 'telefono':
                return 'phone';
            default:
                return 'devices_other';
        }
    }

    get isTelefono(): boolean {
      return this.tag === 'telefono';
    }

    get isCable(): boolean {
        return this.tag === 'cable';
    }

    get isInternet(): boolean {
        return this.tag === 'internet';
    }

    get isNow(): boolean{
        return this.tag === 'now';
    }
}
