import { Serializable } from '../../core/models/serializable.model';
import { ClientModel } from '../client/client.model';
import { ProcedureKindModel } from '../data/procedure-kind.model';
import { BomModel } from '../product/bom.model';
import { ProductModel } from '../product/product.model';
import { ProcedureDataModel } from './procedure-data.model';
import { DeviceModel } from '../product/device.model';
import { ConnectionModel } from '../client/connection.model';
import { ProcedureStateModel } from '../data/procedure-state.model';
import { SubsidiaryModel } from '../data/subsidiary.model';
import { ProcedureProductModel } from './procedure-product.model';
import {ImageModel} from '../image.model';

export class ProcedureModel extends Serializable {
    /* parametros q se usan para mostrar de server */
    public id: number;
    public code: string;
    public procedureState: ProcedureStateModel;
    public date: Date | string | any;
    public productsProcedure: ProcedureProductModel[];
    /* --------- */

    public subsidiary: SubsidiaryModel;
    public client: ClientModel | null;
    public connection: ConnectionModel | null;
    public procedureKind: ProcedureKindModel;
    public clientDetails: string;
    public contactPhone: string;
    public contactDate: string | null;
    public contactTime: string | null;
    public price: number;
    public bom: BomModel | null;
    public products: ProductModel[];
    /** se usa cuando el procedimiento va todo al mismo plan */
    public planId: number | null;
    public data: ProcedureDataModel[];
    public devices: DeviceModel[];
    public files: ImageModel[];

    initForNewProcedure() {
        this.client = null;
        this.bom = null;
        this.planId = null;
        this.products = [];
        this.data = [];
        this.devices = [];
        this.price = 0;
    }

    newModel(data: any): ProcedureModel {
        const dataParsed = new ProcedureModel(data);

        if (data.client) {
            dataParsed.client = ClientModel.createOne(data.client, new ClientModel());
        }

        if (data.connection) {
            dataParsed.connection = ConnectionModel.createOne(
                data.connection,
                new ConnectionModel()
            );
        }

        if (data.procedureKind) {
            dataParsed.procedureKind = ProcedureKindModel.createOne(
                data.procedureKind,
                new ProcedureKindModel()
            );
        }

        if (data.procedureState) {
            dataParsed.procedureState = ProcedureStateModel.createOne(
                data.procedureState,
                new ProcedureStateModel()
            );
        }

        if (data.products) {
            dataParsed.productsProcedure = ProcedureProductModel.createArray(
                data.products, new ProcedureProductModel()
            );
        }

        if (data.dataValues) {
            dataParsed.data = [];
            for (const d of data.dataValues) {
                dataParsed.data.push(new ProcedureDataModel(d.data, d.value));
            }
        }

        return dataParsed;
    }

    prepareForStore(): any {
        const response: any = {};

        response.subsidiary_id = this.subsidiary.id;

        response.procedure_kind_id = this.procedureKind.id;
        response.client_details = this.clientDetails;
        response.contact_phone = this.contactPhone;
        response.contact_date = null;
        response.contact_time = this.contactTime;
        response.price = this.price;

        if (this.client != null) {
            response.client_id = this.client.id;

            if (this.connection != null) {
                response.client_connection_id = this.connection.id;
            }
        }

        if (this.bom != null) {
            response.bom = {};
            response.bom.bom_id = this.bom.id;
            response.bom.bom_price = this.bom.price;
        }

        if (this.products.length > 0) {
            response.products = [];
            // agrego productos y opcionales al mismo nivel
            for (const p of this.products) {
                response.products.push(this.productForStore(p, this.planId));
                if (p.optionals != null) {
                    for (const o of p.optionals) {
                        response.products.push(this.productForStore(o, p.id));
                    }
                }
            }
        }

        if (this.devices.length > 0) {
            response.devices = [];
            // agrego los devices seleccionados
            for (const device of this.devices) {
                const d: any = {};
                d.product_id = device.product.id;
                d.device_type = device.type;
                d.number = device.number;
                response.devices.push(d);
            }
        }

        if (this.data.length > 0) {
            response.data = [];
            for (const d of this.data) {
                response.data.push(d);
            }
        }

        if (this.files != null && this.files.length > 0) {
            response.files = this.files;
        }

        return response;
    }

    /**
     * prepara el producto para enviar como parte del tramite
     * @param prod ProductModel
     */
    private productForStore(p: ProductModel, plan_id: number = null): any {
        const prod: any = {};
        prod.product_id = p.id;
        prod.product_price = p.price;
        if (plan_id != null) {
            prod.plan_id = plan_id;
        }
        return prod;
    }
}
