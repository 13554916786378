<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container pt-4 pb-2">
    <div class="row">
      <div class="col-12">
        <div class="titulo color-primary">Producto a adquirir</div>

        <div class="row align-self-center">
          <span class="col-sm-3 color-primary  font-weight-bold"
          >Producto:</span
          >
          <div class="col-sm-9 ">
            <span>{{ product.name }}</span>
          </div>
        </div>

        <div class="row align-self-center">
          <span class="col-sm-3 color-primary  font-weight-bold">Precio:</span>
          <div class="col-sm-9 ">
            <span>{{ product.price | currency: 'es-AR':'$' }}</span>
          </div>
        </div>
    
      </div>
    </div>


  </div>
</div>

<div class="modal-footer">

  <button
   
    href="javascript:void(0)"
    class="btn btn-block text-white bg-color-primary d-block"
    (click)="makeProcedure()"
  >
    CONFIRMAR COMPRA
  </button>

</div>

