import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PdfService} from '../../../shared/services/pdf.service';
import {ClientModel} from '../../../models/client/client.model';
import {ClientService} from '../../../providers/auth/client.service';
import {AccountService} from '../../../providers/auth/account.service';
import {GeneralResponse} from '../../../models/general-response.model';
import {CouponModel} from '../../../models/data/coupon.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {ModalHelperFunctions} from '../../../helpers/modal-functions';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-modal-emit-coupon',
    templateUrl: './modal-emit-coupon.component.html',
    styleUrls: ['./modal-emit-coupon.component.scss']
})
export class ModalEmitCouponComponent implements OnInit {

    title: string;
    selectedValue: string;
    coupon: CouponModel;
    currentClient: ClientModel;
    action: string;

    constructor(private bsModalRef: BsModalRef,
                private pdfService: PdfService,
                private clientsService: ClientService,
                private accountService: AccountService,
                private spinner: NgxSpinnerService,
                private modalService: BsModalService) {
    }

    ngOnInit() {
        this.currentClient = this.clientsService.currentClient;
    }

    showAmounts(): boolean {
        return (+this.currentClient.paymentLast !== 0 ||
            +this.currentClient.paymentTotal !== 0 ||
            +this.currentClient.paymentMin !== 0 );
    }

    onClose() {
        this.bsModalRef.hide();
    }
    onEmitCoupon() {
        this.spinner.show();
        this.accountService.getPaymentCouponData(this.currentClient.id)
            .subscribe(
                (resp: GeneralResponse) => {
                    this.coupon = resp.data;
                    this.coupon.montoPesos = (+this.selectedValue).toFixed(2);
                    this.coupon.monto = this.leftpad((this.coupon.montoPesos.toString().replace('.', '')), 6, '0' );
                    this.coupon.setBarCode();
                    this.pdfService.printPaymentCoupon(this.clientsService.currentClient, this.coupon);
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    this.bsModalRef.hide();
                    ModalHelperFunctions.showModalResult(
                        'Error al generar cupón de pago',
                        err.message,
                        err.error,
                        this.modalService
                    );
                }
            );
    }


    pad(input, length, padding) {
        let str = input + '';
        return (length <= str.length) ? str : this.pad(str + padding, length, padding);
    }

    leftpad(input, length, padding) {
        let str = input + '';
        return (length <= str.length) ? str : this.leftpad(padding + str, length, padding);
    }

    OnPayOnline(): void {
        // TODO: implementar pago
        this.onClose();
    }

    get hasPaymentLast(): boolean {
      return (this.currentClient.paymentLast !== 0);
    }

    get hasPaymentMin(): boolean {
      return (this.currentClient.paymentMin !== 0);
    }

    get hasPaymentTotal(): boolean {
      return (this.currentClient.paymentTotal !== 0);
    }

}
