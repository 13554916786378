import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { BomModel } from 'src/app/models/product/bom.model';
import { BaseComponent } from 'src/app/core/components/base-component';
import { BomsService } from 'src/app/providers/products/boms.service';
import { CartBomService } from 'src/app/providers/cart/cart-bom.service';
import { ProductModel } from 'src/app/models/product/product.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalCartConfirmComponent } from 'src/app/main/procedures/modal-cart-confirm/modal-cart-confirm.component';
import { take } from 'rxjs/operators';
import { GeneralResponse } from 'src/app/models/general-response.model';
import { environment } from 'src/environments/environment';
import { ModalHelperFunctions } from 'src/app/helpers/modal-functions';
import {PdfService} from '../../../../shared/services/pdf.service';
import {CartBomModel} from '../../../../models/cart/cart-bom.model';
import {SubsidiaryService} from '../../../../providers/data/subsidiary.service';

@Component({
  selector: 'app-bom-detail',
  templateUrl: './bom-detail.component.html',
  styleUrls: ['./bom-detail.component.scss']
})
export class BomDetailComponent extends BaseComponent implements OnInit {
  bsModalRef: BsModalRef;
  bsModalResultRef: BsModalRef;

  bom: BomModel;
  id: number;
  loading: boolean;
  cartBom: CartBomModel;
  movistarPlan = false;
  tvNowSelected = false;
  priceCombo = '';
  showBuySection = false;

  constructor(
    private _activeRoute: ActivatedRoute,
    private router: Router,
    private cartBomService: CartBomService,
    private bomService: BomsService,
    private modalService: BsModalService,
    private pdfService: PdfService,
    private subsidiaryService: SubsidiaryService
  ) {
    super();
    this.priceCombo = environment.texts.priceComboTeletrabajo;
  }

  ngOnInit() {
    this.loading = true;
    this.cartBom = this.cartBomService.cart;
    this._activeRoute.params.subscribe((params: Params) => {
      if (params['bomId']) {
        this.id = +params['bomId'];
        if (this.bomService.boms) {
          this.setBom(this.bomService.boms.find(prod => prod.id === this.id));
        }
        this.loading = false;
      } else {
        this.goBack();
      }
    });
    this.bomService.bomsChange.subscribe((boms: BomModel[]) => {
      this.setBom(boms.find(bom => bom.id === this.id));
    });
    if(this.router.url.includes('detalle-qr')){
      this.showBuySection = true;
    }
  }

  get bomProducts(): ProductModel[] {
    return this.cartBomService.cart.products;
  }

  get bomPrice(): number {
    return this.cartBomService.cart.price;
  }

  setBom(bom: BomModel) {
    this.bom = bom;
    this.cartBomService.setBom(this.bom);
  }

  goBack(): void {
    this.cartBomService.cleanBom();
    this.router.navigate(['carro-compras/promos']);
  }

  onEditProduct(product: ProductModel) {
    this.router.navigate(['..', this.id, product.id], {
      relativeTo: this._activeRoute
    });
  }

  onApplyBom() {
    const initialState = {
      title: 'Confirmar Solicitud de compra',
      type: 'bom',
      applyMovistar: this.movistarPlan,
      addTvNow: this.tvNowSelected
    };
    this.bsModalRef = this.modalService.show(ModalCartConfirmComponent, {
      ...environment.modalConfig,
      initialState
    });
    this.bsModalRef.setClass('modal-xl');

    // m subscribo a la respuesta del modal
    this.bsModalRef.content.onCloseModal
      .pipe(take(1))
      .subscribe((response: GeneralResponse) => {
        if (response) {
          const result = ModalHelperFunctions.showModalResult(
            response.error
              ? 'Solicitud de compra no generada'
              : 'Tu solicitud fue generada',
            response.message,
            response.error,
            this.modalService
          );
          // m subscribo a la respuesta del modal result
          result.content.onCloseModal.pipe(take(1)).subscribe(() => {
            if (!response.error) {
              this.cartBomService.cleanBom();
              this.bomService.cleanBestBom();
              this.goBack();
            }
          });
        }
      });
  }

  submitProcedure(response) {
    debugger;
    if (response) {
      const result = ModalHelperFunctions.showModalResult(
        response.error
          ? 'Solicitud de compra no generada'
          : 'Tu solicitud fue generada',
        response.message,
        response.error,
        this.modalService
      );
      // m subscribo a la respuesta del modal result
      result.content.onCloseModal.pipe(take(1)).subscribe(() => {
        if (!response.error) {
          this.cartBomService.cleanBom();
          this.bomService.cleanBestBom();
          this.goBack();
        }
      });
    }
  }

  print(): void {
    this.pdfService.printBomResume(this.cartBomService.cart, this.movistarPlan, this.tvNowSelected);
  }

  get showMovistar(): boolean {
    return this.subsidiaryService.currentSubsidiary.tag !== 'sl';
  }
}
