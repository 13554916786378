import { ProceduresModule } from './../procedures/procedures.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


// import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CartRoutingModule } from './cart-routing.module';
import { CartComponent } from './cart.component';
import { CollapseModule } from 'src/app/shared/directives/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectedProductsComponent } from './selected-products/selected-products.component';
import { SelectedProductDetailComponent } from './selected-products/selected-product-detail/selected-product-detail.component';
import { CartHomeComponent } from './cart/cart-home/cart-home.component';
import { FamilyButtonsComponent } from './cart/cart-home/family-buttons/family-buttons.component';
import { CartFamilyProductsComponent } from './cart/cart-family-products/cart-family-products.component';
import { CartComponent as CartCartComponent } from './cart/cart.component';
import { BomComponent } from './bom/bom.component';
import { BomHomeComponent } from './bom/bom-home/bom-home.component';
import { BomDetailComponent } from './bom/bom-detail/bom-detail.component';
import { CartPlanDetailsComponent } from './cart/cart-plan-details/cart-plan-details.component';
import { BomPlanDetailsComponent } from './bom/bom-plan-details/bom-plan-details.component';
import { BomHomeDetailsComponent } from './bom/bom-home/bom-home-details/bom-home-details.component';
import { ModalCartConfirmComponent } from '../procedures/modal-cart-confirm/modal-cart-confirm.component';
import { ModalProcedureResultComponent } from '../procedures/modal-procedure-result/modal-procedure-result.component';
import { SharedModule } from '../shared/shared.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    CartComponent,

    SelectedProductsComponent,
    SelectedProductDetailComponent,

    CartCartComponent,
    CartHomeComponent,
    FamilyButtonsComponent,
    CartFamilyProductsComponent,

    BomComponent,
    BomHomeComponent,
    BomDetailComponent,
    CartPlanDetailsComponent,
    BomPlanDetailsComponent,
    BomHomeDetailsComponent,


  ],
    imports: [
        CommonModule,

        CartRoutingModule,

        BrowserAnimationsModule,
        CollapseModule,

        SharedModule,
        FormsModule,
        ProceduresModule
        
    ],
  exports: [
    SelectedProductsComponent
  ],
  entryComponents: [
    ModalCartConfirmComponent,
    ModalProcedureResultComponent,
  ]
})
export class CartModule { }
