import { Component, OnInit, Input } from '@angular/core';
import { TermCondition } from 'src/app/models/product/term-condition.model';

@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

    @Input() terms: TermCondition[] = [];
    isCollapsed = true;

    constructor() { }

    ngOnInit() {
    }

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

}
