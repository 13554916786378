<app-modal-header [title]="title" (close)="onClose()"></app-modal-header>

<div class="procedure-modal oficina-virtual formulario font-size-1rem">
  <div class="container py-4 mb-5" name="form" [formGroup]="form">
    <div class="row">
      <div class="col-lg-12 margin-top-mobile-1">
        <div class="form-group row">
          <label  class="col-sm-3 col-form-label col-form-label-sm color-primary">Conexión
            <span class="required">*</span></label>
          <div class="col-sm-9" >
            <select  class="form-control form-control-sm"  formControlName="connection">
              <option *ngFor="let c of connections" [value]="c"> {{ c.code }} - Domicilio:
                {{ c.fullAddress }}</option>
            </select>
          </div>
        </div>

        <div class="color-primary">Datos del titular actual</div>
        <div class="mx-2 py-2">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Nombre</label>
            <div class="col-sm-9 align-self-center">
              <input
                      readonly
                      [(ngModel)]="currentOwner.firstName"
                      type="text"
                      class="form-control form-control-sm"
                      [ngModelOptions]="{standalone: true}"/>
            </div>

          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Apellido</label>
            <div class="col-sm-9 align-self-center">
              <input
                      readonly
                      [(ngModel)]="currentOwner.lastName"
                      type="text"
                      class="form-control form-control-sm"
                      [ngModelOptions]="{standalone: true}"/>
            </div>

          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >DNI</label>
            <div class="col-sm-9 align-self-center">
              <input
                      readonly
                      [(ngModel)]="currentOwner.document"
                      type="text"
                      class="form-control form-control-sm"
                      [ngModelOptions]="{standalone: true}"/>
            </div>
          </div>
        </div>

        <div class="color-primary pt-3">Datos del nuevo titular</div>
        <div class="mx-2 py-2">

          <!--apellido-->
          <div class="form-group row ">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Apellido
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                      type="text"
                      class="form-control form-control-sm "
                      formControlName="lastname"
                      [ngClass]="{ 'is-invalid': validInput('lastname') }"
                      placeholder="Apellido"
              />
            </div>
          </div>

          <!--nombre-->
          <div class="form-group row">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Nombre
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                      type="text"
                      class="form-control form-control-sm "
                      formControlName="name"
                      [ngClass]="{ 'is-invalid': validInput('name') }"
                      placeholder="Nombre"
              />
            </div>
          </div>

          <!--dni-->
          <div class="form-group row ">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >Documento
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                      type="number"
                      class="form-control form-control-sm "
                      formControlName="document"
                      [ngClass]="{ 'is-invalid': validInput('document') }"
                      placeholder="Documento"
              />
            </div>
          </div>

          <!--cuit cuil-->
          <div class="form-group row ">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary "
            >CUIT/CUIL
              <span class="required">*</span></label
            >
            <div class="col-sm-9 align-self-center">
              <input
                      type="number"
                      class="form-control form-control-sm "
                      formControlName="cuit"
                      [ngClass]="{ 'is-invalid': validInput('cuit') }"
                      placeholder="CUIT/CUIL"
              />
            </div>
          </div>

          <!--email-->
          <div class="form-group row">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Email <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                      type="email"
                      class="form-control form-control-sm"
                      placeholder="Email"
                      formControlName="email"
                      [ngClass]="{ 'is-invalid': validInput('email') }"
              />
            </div>
          </div>

          <!--telefono-->
          <div class="form-group row">
            <label
                    for="colFormLabelSm"
                    class="col-sm-3 col-form-label col-form-label-sm color-primary"
            >Teléfono <span class="required">*</span></label
            >
            <div class="col-sm-9">
              <input
                      type="tel"
                      class="form-control form-control-sm"
                      placeholder="(000) 000 0000"
                      mask="(000) 000 0000"
                      formControlName="phone"
                      [ngClass]="{ 'is-invalid': validInput('phone') }"
              />
            </div>
          </div>

        </div>

        <div class="form-group row">
          <label  class="col-sm-4 col-form-label col-form-label-sm color-primary align-self-center">Horario
            para Contactar</label>
          <div class="col-sm-3 align-self-center">
            <input type="tel" placeholder="hh:mm" class="form-control" mask="Hh:m0" formControlName="timeFrom" />
          </div>
          <div class="col-sm-1 align-self-center"></div>
          <div class="col-sm-4 input-in-mobile align-self-center">
            <input  type="tel" formControlName="timeUntil" placeholder="hh:mm" class="form-control"
                    mask="Hh:m0" />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label col-form-label-sm color-primary">Observaciones</label>
          <div class="col-sm-9">
            <textarea type="text" rows="4" class="form-control form-control-sm"
                      formControlName="comment"></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label col-form-label-sm color-primary">
            Foto DNI Titular  <span class="required">*</span></label>
          <div class="col-sm-8">
            <input type="file"
                   id="dni"
                   multiple="false"
                   (change)="uploadDNI($event)">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label col-form-label-sm color-primary">
            Foto nuevo titular <span class="required">*</span></label>
          <div class="col-sm-8">
            <input type="file"
                   id="titular"
                   multiple="false"
                   (change)="uploadPicture($event)">
          </div>

        </div>
      </div>
    </div>

    <app-modal-recaptcha
            (confirmCaptcha)="toggleCaptcha($event)"
    ></app-modal-recaptcha>
  </div>
</div>

<div class="modal-footer">

  <button
          [disabled]="!isValidForm"
          href="javascript:void(0)"
          class="btn btn-block text-white bg-color-primary d-block"
          (click)="onConfirm()"
  >
    ENVIAR
  </button>

</div>

