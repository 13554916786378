import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'periodFormat'
})
export class PeriodFormatPipe implements PipeTransform {
  /** recibe mes anio => mes 3 letras + anio en numeros devuelve mes entero + anio */
  transform(value: string, args?: any): string {
    const separate = value.split(' ');
    if (separate.length === 2) {
      return `${this.parseMonth(separate[0])} ${separate[1]}`;
    }
    return value;
  }

  private parseMonth(month: string): string {
    switch (month.toLocaleLowerCase()) {
      case 'ene':
        return 'Enero';
      case 'feb':
        return 'Febrero';
      case 'mar':
        return 'Marzo';
      case 'abr':
        return 'Abril';
      case 'may':
        return 'Mayo';
      case 'jun':
        return 'Junio';
      case 'jul':
        return 'Julio';
      case 'ago':
        return 'Agosto';
      case 'sep':
        return 'Septiembre';
      case 'oct':
        return 'Octubre';
      case 'nov':
        return 'Noviembre';
      case 'dic':
        return 'Diciembre';
      default:
        return month;
    }
  }
}
