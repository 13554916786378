export const environment = {
  production: true,
  // api : 'http://new.express.com.ar/express.api/public/v1/',
  api : 'https://appweb.express.com.ar/express.api/public/v1/',
  iconsUrl  : 'assets/img/plan_icons/',
  routes    : {
    login : ['/', 'cliente', 'ingreso'],
  },
  texts: {
    procedure_footer: 'Cualquier duda o consulta comunicate con Express.',
    priceComboTeletrabajo: '1500'
  },
  modalConfig : {
    keyboard: false,
    ignoreBackdropClick: true,
  },
  externalUris: {
    now: 'https://now.express.com.ar/',
    nowWeb: 'https://expressplay.com.ar/ar/',
    nowAndroid: 'https://play.google.com/store/apps/details?id=com.express.android.minerva.devices',
    nowIos: 'https://itunes.apple.com/ar/app/now-express-for-iphone/id1410189558?mt=8',
    coronavirusPdf: 'https://www.express.com.ar/NosQuedamosEnCasa.pdf',
    dnu: 'https://www.boletinoficial.gob.ar/detalleAviso/primera/227120/20200325',
    principalPanel: 'https://www.express.com.ar/oficina-virtual/panel/principal',
    promos: 'https://www.express.com.ar/carro-compras/promos/inicio',
    wsPayment: 'https://200.61.163.65:8457/WondersoftAutorizacionPagos.aspx'
  },
    recaptchaKey: '6LfLtqsUAAAAABVSIAlw64aa8wl2S9_Jy0CcmX0k',
    credit_cards_cbh: [
      {id: 1, name: 'Cabal'},
      {id: 2, name: 'Visa'},
      {id: 3, name: 'Mastercard'},
      {id: 6, name: 'Naranja'},
      {id: 10, name: 'Visa Debito'},
      {id: 12, name: 'Maestro'},
    ],
    credit_cards_sl: [
      {id: 1, name: 'Cabal'},
      {id: 2, name: 'Visa'},
      {id: 3, name: 'Mastercard'},
      {id: 6, name: 'Naranja'},
      {id: 10, name: 'Visa Debito'},
      {id: 12, name: 'Maestro'},
    ],
    credit_cards_san: [
      {id: 1, name: 'Cabal'},
      {id: 2, name: 'Visa'},
      {id: 3, name: 'Mastercard'},
      {id: 6, name: 'Naranja'},
      {id: 10, name: 'Visa Debito'},
      {id: 12, name: 'Maestro'},
    ],
    credit_cards_dtv: [
      {id: 1, name: 'Cabal'},
      {id: 2, name: 'Visa'},
      {id: 3, name: 'Mastercard'},
      {id: 6, name: 'Naranja'},
      {id: 10, name: 'Visa Debito'},
      {id: 12, name: 'Maestro'},
    ],
    basicoOttCode: 100
};
