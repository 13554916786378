import { Injectable } from '@angular/core';
import { CartModel } from 'src/app/models/cart/cart.model';
import { ProductModel } from 'src/app/models/product/product.model';
import { BomModel } from 'src/app/models/product/bom.model';
import { BomsService } from '../products/boms.service';
import { TermCondition } from 'src/app/models/product/term-condition.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private _cart: CartModel;

  constructor() {
    this.getCartFromStorage();
  }

  /**
   * Devuelve el carro de compras
   */
  get cart(): CartModel {
    return this._cart;
  }

  /**
   * Devuelve si el carro tiene productos
   */
  get hasProducts(): boolean {
    return (this._cart.products.length > 0);
  }

  get cartTermsAndConditions(): TermCondition[] | null {
    let terms: TermCondition[] = [];
    for (const plan of this.cart.products) {
      if (plan.planTermsAndConditions != null) {
        terms = [
          ...terms,
          ...plan.planTermsAndConditions
        ];
      }
    }
    return (terms.length > 0) ? terms : null;
  }

  /**
   * Agrega productos al carro, si ya tiene para la familia, lo pisa.
   * @param product ProductModel
   */
  addProduct(product: ProductModel) {
    // chekeo si ya existe un prod para la familia
    const index = this._cart.products.indexOf(this._cart.products.find(cp => cp.family.id === product.family.id));
    if (index !== -1) {
      this._cart.products.splice(index, 1);
    }
    this._cart.products.push(product);
    this.calculateTotal();
    this.setCartInStorage();
  }

  /**
   * Revisa si se selecciono producto para una familia
   * @param familyId Id de la familia
   */
  checkIfFamilyIsSelected(familyId: number): boolean {
    return (this._cart.products.findIndex(cp => cp.family.id === familyId) !== -1);
  }

  /**
   * Elimina productos del carro.
   * @param product ProductModel
   */
  quitProduct(product: ProductModel) {
    this._cart.products.splice(this._cart.products.indexOf(product), 1);
    this.calculateTotal();
    this.setCartInStorage();
  }

  /**
   * Busc un produto en el carrito y lo devuelve o null
   * @param productId id del producto a buscar
   */
  findProductInCart(productId: number): ProductModel | null {
    return this._cart.products.find(p => p.id === productId);
  }

  /**
   * devuelve el producto para la familia, si es que hay o null
   * @param familyId number
   */
  getProductByFamily(familyId): ProductModel {
    return this.cart.products.find(cp => cp.family.id === familyId);
  }

  /**
   * Limpia el carro borrando todo lo agregado
   */
  cleanCart() {
    this._cart = new CartModel();
    this.cleanCartInStorage();
  }

  /**
   * Recalcula el total del carro.
   */
  calculateTotal() {
    this.cart.price = 0;
    for (const product of this._cart.products) {
      this.cart.price += product.price;
      for (const optional of product.optionals) {
        this.cart.price += +optional.price;
      }
    }
  }

  /**
   * guarda el carrito en el storage
   */
  setCartInStorage() {
    localStorage.setItem('cart', JSON.stringify(this._cart));
    localStorage.setItem('cart-day', this.getPeriod());
  }

  /**
   * Limpia el carrito del storage
   */
  cleanCartInStorage() {
    localStorage.removeItem('cart');
    localStorage.removeItem('cart-day');
  }

  /**
   * Trae el carro actual del storage si existe y si esta en fecha, sino genera uno nuevo
   */
  getCartFromStorage() {
    if (localStorage.getItem('cart')) {
      // reviso si el dia q se guardo es el mismo q hoy
      if (localStorage.getItem('cart-day') === this.getPeriod()) {
        this._cart = CartModel.createFromString(JSON.parse(localStorage.getItem('cart')));
        this.calculateTotal();
      } else {
        this._cart = new CartModel();
      }
    } else {
      this._cart = new CartModel();
    }
  }

  getPeriod(): string {
    const d = new Date();
    return d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString();
  }
}
