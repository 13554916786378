import { Component, OnInit } from '@angular/core';
import {LayoutService} from '../../../providers/layout.service';

@Component({
  selector: 'app-finder',
  templateUrl: './finder.component.html',
  styleUrls: ['./finder.component.scss']
})
export class FinderComponent implements OnInit {

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
      this.layoutService.showFooter = true;
  }

}
