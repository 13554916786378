<li class="border-bottom-1">
    <div class="row">
        <div class="col-12">
          <div class="row no-gutters">
              <div class="col-auto">
                  <a *ngIf="!isBom" (click)="onQuitProduct()" class="lnk-quitar-producto cursor-pointer">Quitar</a>
                  <img src="{{product.iconUrl}}" class="img-fluid" alt="Plan TV Básico">
              </div>
              <div class="col">
                  <div class="card-block px-3 text-left cursor-pointer" (click)="onEditProduct()">
                      <div class="row no-gutters">
                        <div class="col">
                          <div class="nombre-plan">{{product.name}}</div>
                          <div class="descripcion-plan">{{product.description}}</div>
                          <!-- <div class="descripcion-plan"> + click para ver detalles</div> -->
                        </div>
                        <div class="col-auto">
                          <div class="precio py-2 color-primary float-right"
                            [ngClass]="{'precio-tachado': isBom}">
                            {{product.price | currency:'es-AR':'$'}}
                          </div>
                        </div>
                      </div>
                  </div>
                   <div class="card-block px-3 text-left">
                      <div class="row no-gutters adicional"
                        *ngFor="let optional of product.optionals">
                        <div class="col">
                          {{optional.name}}
                        </div>
                        <div class="col-auto">
                          <div class="precio color-primary float-right" [ngClass]="{'precio-tachado': underlineOptional(optional)}">{{optional.price | currency:'es-AR':'$'}}</div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</li>
