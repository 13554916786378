export class ProcedureDataModel {
  constructor(public data: string, public value: string) {}

  get dataParsed(): string {
    const dataHasParse = PROCEDURE_DATA_PARSER.find(p => p.data === this.data);
    if (dataHasParse) {
      return dataHasParse.value;
    } else {
      return this.data;
    }
  }
}

export const PROCEDURE_DATA_PARSER: ProcedureDataModel[] = [
  new ProcedureDataModel('nombre', 'Nombre'),
  new ProcedureDataModel('edad', 'Edad'),
  new ProcedureDataModel('email', 'E-mail'),
  new ProcedureDataModel('telefono', 'Teléfono'),
  new ProcedureDataModel('documento', 'Documento'),
  new ProcedureDataModel('calle', 'Calle'),
  new ProcedureDataModel('numero', 'Número'),
  new ProcedureDataModel('piso', 'Piso'),
  new ProcedureDataModel('depto', 'Depto'),
  new ProcedureDataModel('tarjeta-credito', 'Tarjeta Credito'),
  new ProcedureDataModel('password', 'Contraseña'),
  new ProcedureDataModel('canal', 'Canal'),
  new ProcedureDataModel('cbu', 'CBU'),
];


