<div *ngIf="procedure">
  <app-modal-header [title]="procedure.procedureKind.name" (close)="onClose()"></app-modal-header>

  <div class="procedure-modal oficina-virtual formulario font-size-1rem">
    <div class="container py-4 mb-5">
      <div class="row">
        <div class="col-12">
          <div class="titulo color-primary">Detalle del trámite</div>

          <div class="row align-self-center">
            <span class="col-sm-4 color-primary  font-weight-bold">Nº DE GESTIÓN:</span>
            <div class="col-sm-8 ">
              <span>{{ procedure.code }}</span>
            </div>
          </div>

          <div class="row align-self-center">
            <span class="col-sm-4 color-primary  font-weight-bold">FECHA:</span>
            <div class="col-sm-8 ">
              <span>{{ procedure.date }}</span>
            </div>
          </div>

          <div class="row align-self-center">
            <span class="col-sm-4 color-primary  font-weight-bold">ESTADO:</span>
            <div class="col-sm-8 ">
              <span class="estado text-uppercase" [ngClass]="{
                  'pendiente': procedure.procedureState.isColor('pendiente'),
                  'progreso': procedure.procedureState.isColor('progreso'),
                  'review': procedure.procedureState.isColor('review'),
                  'finalizado': procedure.procedureState.isColor('finalizado')}">
                {{procedure.procedureState.name}}
              </span>
            </div>
          </div>

          <div class="mt-2" *ngIf="isBuyProductProcedure">

            <div class="titulo color-primary">Producto Adquirido</div>
            <div *ngFor="let product of procedure.productsProcedure">
              <div class="row align-self-center">
                <span class="col-sm-3 color-primary  font-weight-bold">Producto:</span>
                <div class="col-sm-9 ">
                  <span>{{ product.product.name }}</span>
                </div>
              </div>

              <div class="row align-self-center" *ngIf="product.product.hasOptionals">
                <span class="col-sm-3 color-primary  font-weight-bold">Adicionales:</span>
                <div class="col-sm-9 ">
                  <span>{{ getOptionalsText(product.product) }}</span>
                </div>
              </div>

              <div class="row align-self-center">
                <span class="col-sm-3 color-primary  font-weight-bold">Precio:</span>
                <div class="col-sm-9 ">
                  <span>{{ product.price | currency: 'es-AR':'$' }}</span>
                </div>
              </div>

              <div class="row align-self-center" *ngIf="product.plan">
                <span class="col-sm-3 color-primary  font-weight-bold">Plan:</span>
                <div class="col-sm-9 ">
                  <span>{{ product.plan.name }}</span>
                </div>
              </div>

            </div>


          </div>

          <div class="mt-2" *ngIf="isDataProcedure">
            <div class="titulo color-primary">Datos Enviados</div>
            <div *ngFor="let data of procedure.data">
              <div class="row align-self-center">
                <span class="col-sm-3 color-primary  font-weight-bold">{{data.dataParsed}}:</span>
                <div class="col-sm-9 ">
                  <span>{{ data.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
