import {Serializable} from '../../core/models/serializable.model';
import {DataModel} from '../data/data.model';

export class DeviceModel extends Serializable {

  public id: number;
  public brand: string;
  public model: string;
  public order: number;
  public price: number;
  public image: string;
  public subsidiaries: DataModel[];

  newModel(data: any): DeviceModel {
    const dataParsed = new DeviceModel(data);
    if (data.subsidiaries && data.subsidiaries.length > 0) {
      dataParsed.subsidiaries = DataModel.createArray(data.subsidiaries, new DataModel());
    } else {
      dataParsed.subsidiaries = null;
    }
    return dataParsed;
  }
}
