import { Component, OnInit } from '@angular/core';
import {ProceduresBaseComponent} from '../procedures-base-component';
import {ProceduresService} from '../../../providers/procedures/procedures.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ClientService} from '../../../providers/auth/client.service';
import {ProcedureKind} from '../../../models/data/procedure-kind.enum';
import {DataService} from '../../../providers/data/data.service';
import {ProcedureModel} from '../../../models/procedure/procedure.model';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ConnectionModel} from '../../../models/client/connection.model';
import {ProcedureDataModel} from '../../../models/procedure/procedure-data.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataModel} from '../../../models/data/data.model';
import {HelperFunctions} from '../../../helpers/functions';
import {ClientModel} from '../../../models/client/client.model';

@Component({
    selector: 'app-modal-automatic-debit',
    templateUrl: './modal-automatic-debit.component.html',
    styleUrls: ['./modal-automatic-debit.component.scss']
})
export class ModalAutomaticDebitComponent extends ProceduresBaseComponent implements OnInit {


    form: FormGroup;
    connections: ConnectionModel[];
    procedureTypes = [
        {tag: ProcedureKind.AUTOMATIC_DEBIT_UP, text: 'Alta'},
        {tag: ProcedureKind.AUTOMATIC_DEBIT_UPDATE, text: 'Modificación'},
        {tag: ProcedureKind.AUTOMATIC_DEBIT_DOWN, text: 'Baja'},
    ];
    client: ClientModel;

    entities = [
        {name: 'Visa'},
        {name: 'Amex'},
        {name: 'Naranja'},
        {name: 'Mastercad'},
        {name: 'Cabal'},
        {name: 'Cbu/Cuenta Bancaria'},
    ];

    title: string;

    constructor(
        bsModalRef: BsModalRef,
        spinner: NgxSpinnerService,
        proceduresService: ProceduresService,
        private clientsService: ClientService,
        private dataService: DataService,
        private _formBuilder: FormBuilder
    ) {
        super(bsModalRef, spinner, proceduresService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initForm();
    }

    initForm() {
        this.connections = this.clientsService.currentClient.connections;
        this.client = this.clientsService.currentClient;
        this.form = this._formBuilder.group({
            selectedProcedure: ['', [Validators.required]],
            name: ['', [Validators.required]],
            docType: ['', [Validators.required]],
            docNumber: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(9)]],
            paymentMethod: ['', Validators.required],
            paymentNumber: ['', [Validators.required, Validators.minLength(8)]],
            comment: [''],
            entity: ['', Validators.required],
            connection: [this.clientsService.currentConection, [Validators.required]],
            timeFrom: ['0900', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            timeUntil: ['1800', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
        });
    }

    get isValidForm(): boolean {
        return this.form.valid && this.captchaValid;
    }

    get documents(): DataModel[] {
        return this.dataService.documents;
    }

    // /** checkea si la conf a cambiar es la elegida */
    // checkSettingToChange(setting: string): boolean {
    //     return setting === this.settingToChange;
    // }
    //
    // selectDevice($event, device: DeviceModel): void {
    //     if ($event.srcElement.checked) {
    //         this.devicesSelected.push(device);
    //     } else {
    //         this.devicesSelected.splice(this.devicesSelected.indexOf(device), 1);
    //     }
    // }


    validInput(input: string): boolean {
        const control = this.form.get(input);
        if (control.dirty) {
            return !this.form.get(input).valid;
        } else {
            return false;
        }
    }
    makeProcedure(): ProcedureModel {
        const procedure = new ProcedureModel();

        procedure.initForNewProcedure();
        procedure.client = this.clientsService.currentClient;
        procedure.connection = this.form.value.connection;
        procedure.subsidiary = this.clientsService.currentClient.subsidiary;
        procedure.contactPhone = this.form.value.phone;
        procedure.contactTime = HelperFunctions.formatContactTime(this.form.value.timeFrom, this.form.value.timeUntil);
        procedure.procedureKind = this.dataService.procedureKind.find(
            pk => pk.tag === this.form.value.selectedProcedure
        );
        procedure.clientDetails = this.form.value.comment;
        let pm = this.form.value.paymentMethod;
        if (pm.length === 6) {
            pm = pm.substring(0, 2) + '/' + pm.substring(2, 6)
        }
        procedure.data = [
            new ProcedureDataModel('nombre', this.form.value.name),
            new ProcedureDataModel('documento', this.form.value.docType + ' ' + this.form.value.docNumber),
            new ProcedureDataModel('entidad', this.form.value.entity),
            new ProcedureDataModel('medio-pago', pm + ' - ' + this.form.value.paymentNumber),
        ];

        return procedure;
    }
}
